import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { getWithdrawalAll, updateWithdrawalRequest } from '../../services/user';
import swal from 'sweetalert';
import {
  getRecommendedGasPrice,
  usdtWalletContract,
} from '../../utils/web3/init';
import { useLocation, useNavigate } from 'react-router-dom';

export const useGetAllWithdrawalRequest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [approving, setApproving] = useState(false);

  const _getWithdrawRequests = async () => {
    try {
      const query = new URLSearchParams(location.search);

      setLoading(true);
      const data = await getWithdrawalAll(query?.get('status') ?? 'pending');
      setData(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const approveRequest = async (address, amounts, ids) => {
    try {
      console.log('address, amounts, ids', address, amounts, ids);
      setApproving(true);
      const fastTxGasFeeForApproval = await getRecommendedGasPrice();
      let _account = await window.web3Instance.eth.getAccounts();

      if (!_account?.[0]) {
        if (window?.ethereum?.isMetaMask) {
          await window.ethereum.send('eth_requestAccounts');
        }
        _account = await window.web3Instance.eth.getAccounts();
      }

      const nonce = Math.random();
      const result = await window.web3Instance.eth.personal.sign(
        window.web3Instance.utils.fromUtf8(
          `Signing with one-time nonce for wallet: ${nonce}`
        ),
        _account[0],
        String(nonce)
      );
      usdtWalletContract.methods
        .withdrawMultiple(address, amounts, ids)
        .send({
          from: _account?.[0],
          gasPrice: fastTxGasFeeForApproval,
        })
        .on('transactionHash', async function (hash) {
          // update this buy pack to init
          swal(
            'Cheers!!',
            'Request has been approved and USDT will be transfer to account once confirmed.',
            'success'
          );
          await updateWithdrawalRequest({
            id: ids,
            status: 'init',
            nonce,
            signature: result,
          });
          setApproving(false);
          _getWithdrawRequests();
        })
        .on('receipt', function (receipt) {
          console.log({ receipt });
        })
        .on('error', function (error) {
          setApproving(false);
          console.log({ error });
        });
    } catch (error) {
      console.log(error);
      setApproving(false);
      _getWithdrawRequests();
    }
  };

  const checkAccess = async () => {
    let _account = await window.web3Instance.eth.getAccounts();

    if (!_account?.[0]) {
      if (window?.ethereum?.isMetaMask) {
        await window.ethereum.send('eth_requestAccounts');
      }
      _account = await window.web3Instance.eth.getAccounts();
    }

    if (
      _account &&
      _account?.[0]?.toLowerCase() ===
        '0x0c78a2D6348EB50Ae0d3f3fcBc7B992Ca1082d99'.toLowerCase()
    ) {
      _getWithdrawRequests();
    } else {
      swal('Error', 'You dont have access to this page', 'error').then(() => {
        navigate('/dash', { replace: true });
      });
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      checkAccess(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { data, loading, _getWithdrawRequests, approveRequest, approving };
};
