import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { updateBackdoorRequest } from '../../services/user';
import swal from 'sweetalert';

import { useNavigate } from 'react-router-dom';

export const useBackdoorEntryRequest = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const backdoorEntryData = async () => {
    try {
      if (!data?.accountAddress) {
        swal('Error', 'Please enter account address', 'error');
        return;
      }
      setLoading(true);
      let _account = await window.web3Instance.eth.getAccounts();

      if (!_account?.[0]) {
        if (window?.ethereum?.isMetaMask) {
          await window.ethereum.send('eth_requestAccounts');
        }
        _account = await window.web3Instance.eth.getAccounts();
      }

      const nonce = Math.random();
      const result = await window.web3Instance.eth.personal.sign(
        window.web3Instance.utils.fromUtf8(
          `Signing with one-time nonce for wallet: ${nonce}`
        ),
        _account[0],
        String(nonce)
      );
      await updateBackdoorRequest({
        email: data?.email,
        accountAddress: data?.accountAddress,
        usdtBalance: data?.usdtBalance,
        signature: result,
        nonce,
      });
      swal('Success', 'Details updated', 'success');
      setData({});
      setLoading(false);
    } catch (error) {
      swal('Error', 'Something went wrong!!!', 'error');
      console.log(error);
      setLoading(false);
    }
  };

  const checkAccess = async () => {
    let _account = await window.web3Instance.eth.getAccounts();

    if (!_account?.[0]) {
      if (window?.ethereum?.isMetaMask) {
        await window.ethereum.send('eth_requestAccounts');
      }
      _account = await window.web3Instance.eth.getAccounts();
    }

    if (
      _account &&
      _account?.[0]?.toLowerCase() ===
        '0x0c78a2D6348EB50Ae0d3f3fcBc7B992Ca1082d99'.toLowerCase()
    ) {
    } else {
      swal('Error', 'You dont have access to this page', 'error').then(() => {
        navigate('/dash', { replace: true });
      });
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      checkAccess(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { data, loading, backdoorEntryData, setData };
};
