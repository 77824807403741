import { useState } from 'react';
import { buyPack } from '../../services/packs';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

export const useBuyPack = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const buyPackCall = async (data) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);

      await buyPack(data);
      swal(
        'Success!',
        'You have successfully bought pack, NFTs will be available in account in sometime',
        'success'
      ).then(() => {
        navigate('/my-nfts');
      });
    } catch (error) {
      console.log({ error });
      swal('Error!', error?.message ?? error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return { loading, buyPackCall };
};
