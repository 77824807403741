export const APIConstants = {
  dev: {
    baseURL: 'http://localhost:3001/api/v1',
    domain: 'http://localhost:3000',
    gasStationAPI: 'https://gasstation.polygon.technology/v2',
  },
  stage: {
    baseURL: 'https://stage-apix.cubixpro.io/api/v1',
    domain: 'https://stage.cubixpro.io',
    gasStationAPI: 'https://gasstation.polygon.technology/v2',
  },
  prod: {
    baseURL: 'https://packsaleapi.cubixpro.io/api/v1',
    domain: 'https://cubixpro.io',
    gasStationAPI: 'https://gasstation.polygon.technology/v2',
  },
};
