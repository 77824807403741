import { useState } from 'react';
import { putAddSubscriptionSignUp } from '../../services/packs';

export const useAddSubscriptionSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const addSubscriptionSignUp = async () => {
    try {
      setLoading(true);
      await putAddSubscriptionSignUp({ email, subscriptionType: 'signUp' });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      setEmail('');
    }
  };

  return { loading, addSubscriptionSignUp, setEmail, email };
};
