import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ActivityFeed } from '../../components/dash/activity-feed';
import { NFTCardList } from '../../components/dash/nft-card/list';
import { ProfileCard } from '../../components/dash/profile/card';
import { StatsCard } from '../../components/dash/profile/stats-card';
import { TermsModal } from '../../components/dash/terms-modal';
import { Content } from '../../components/layout/content';
import { getConfig } from '../../config';
import { UserContext } from '../../context/UserContext';
import { bonusTypes } from '../../services/user';
import CopyToClipboard from 'react-copy-to-clipboard';

const StatsCardSection = ({ refProfitsStats }) => {
  const userContext = useContext(UserContext);

  const userLevel = userContext?.accountDetails?.profile;
  const stats = userContext?.stats;
  const allStats = stats?.allStats;

  console.log({ allStats });

  const sponsorBonus =
    allStats?.find((d) => d.bonusType === 'direct')?.amount ?? '0';

  const teamMatching =
    allStats?.find((d) => d.bonusType === 'team')?.amount ?? '0';

  const directMatching =
    allStats?.find((d) => d.bonusType === 'team_direct')?.amount ?? '0';

  const totalEarning = allStats?.reduce((acc, d) => {
    return acc + d?.amount;
  }, 0);

  console.log({ totalEarning });

  return (
    <section className="section-one">
      <div className="section-one-detail-top">
        <div className="section-one-detail-top-inner">
          <ProfileCard />
          <StatsCard
            title="Team A Reference"
            image={'/assets/images/indirect-icon.png'}
            withImage
            count={
              <>
                {getConfig('domain') +
                  `/register?code=${userContext?.accountDetails?.referralCode}&team=A`}
                <CopyToClipboard
                  text={
                    getConfig('domain') +
                    `/register?code=${userContext?.accountDetails?.referralCode}&team=A`
                  }
                  onCopy={() => alert('Copied!')}
                >
                  <a href="javascript:void(0)" className="wallet-icon-one">
                    <img
                      style={{ height: 24, marginLeft: 12 }}
                      src="/assets/images/wallet-icon-one.png"
                      alt="wallet-pic"
                    />
                  </a>
                </CopyToClipboard>
              </>
            }
            countStyle={{
              fontSize: 15,
              lineHeight: 2,
            }}
          />
          <StatsCard
            title="Team B Reference"
            image={'/assets/images/direct-icon.png'}
            withImage
            count={
              <>
                {getConfig('domain') +
                  `/register?code=${userContext?.accountDetails?.referralCode}&team=B`}
                <CopyToClipboard
                  text={
                    getConfig('domain') +
                    `/register?code=${userContext?.accountDetails?.referralCode}&team=B`
                  }
                  onCopy={() => alert('Copied!')}
                >
                  <a href="javascript:void(0)" className="wallet-icon-one">
                    <img
                      style={{ height: 24, marginLeft: 12 }}
                      src="/assets/images/wallet-icon-one.png"
                      alt="wallet-pic"
                    />
                  </a>
                </CopyToClipboard>
              </>
            }
            countStyle={{
              fontSize: 15,
              lineHeight: 2,
            }}
          />
        </div>
      </div>

      <div className="section-one-detail-top">
        <div className="section-one-detail-top-inner">
          <StatsCard
            title="Total Direct Partners"
            image={'/assets/images/indirect-icon.png'}
            withImage
            count={userContext?.accountDetails?.directPartner}
            rightTitle={'Last 24hr changes'}
            leftTitle={stats?.directPartner?.[0]?.amount ?? '0'}
          />
          <StatsCard
            className="green"
            title={'Total Earning'}
            count={`USDT ${Number(totalEarning || '0').toFixed(2) ?? '-'}`}
            leftTitle={`Withdrawable amount: ${Number(
              userContext?.accountDetails?.totalWithdrawableAmount ?? '0'
            ).toFixed(2)}`}
            rightTitle={``}
            color={refProfitsStats?.levelsColors?.[userLevel?._id - 1]}
          />

          <StatsCard
            title="Sponsor Bonus"
            image={'/assets/images/direct-icon.png'}
            withImage
            count={Number(sponsorBonus ?? '0').toFixed(2)}
            rightTitle={'Last 24hr changes'}
            link={`/bonus/${bonusTypes.direct}`}
            leftTitle={stats?.sponsorBonus?.[0]?.amount ?? '0'}
          />
          <StatsCard
            title="Team matching"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={Number(teamMatching ?? '0').toFixed(2)}
            link={`/bonus/${bonusTypes.team}`}
            leftTitle={stats?.teamBonus?.[0]?.amount ?? '0'}
            rightTitle={'Last 24hr changes'}
          />
        </div>
      </div>
      <div className="section-one-detail-top">
        <div className="section-one-detail-top-inner">
          <StatsCard
            title="Direct Matching"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={Number(directMatching ?? '0').toFixed(2)}
            link={`/bonus/${bonusTypes.teamDirect}`}
            leftTitle={stats?.teamMatchingBonus?.[0]?.amount ?? '0'}
            rightTitle={'Last 24hr changes'}
          />
          <StatsCard
            title="Rank Bonus"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            rankBonus
            count={userContext?.accountDetails?.rankBonus}
            link={`/bonus/${bonusTypes.rank}`}
          />
          <StatsCard
            title="Infinite Pool Bonus"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.infinityPoolBonus}
          />
          <StatsCard
            title="Total investment"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.individualIncome}
            leftTitle={stats?.directInvestment?.[0]?.amount ?? '0'}
            rightTitle={'Last 24hr changes'}
          />
        </div>
      </div>
      <div className="section-one-detail-top">
        <div className="section-one-detail-top-inner">
          <StatsCard
            title="Team A"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.teamACount}
            leftTitle={`${
              stats?.teamCount?.find(
                (_teamCount) => String(_teamCount.node) === '0'
              )?.amount ?? '0'
            } | ${
              stats?.teamInactiveCount?.find(
                (_teamCount) => String(_teamCount.node) === '0'
              )?.amount ?? '0'
            } (Inactive)`}
            rightTitle={'Last 24hr changes'}
          />
          <StatsCard
            title="Team B"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.teamBCount}
            leftTitle={`${
              stats?.teamCount?.find(
                (_teamCount) => String(_teamCount.node) === '1'
              )?.amount ?? '0'
            } | ${
              stats?.teamInactiveCount?.find(
                (_teamCount) => String(_teamCount.node) === '1'
              )?.amount ?? '0'
            } (Inactive)`}
            rightTitle={'Last 24hr changes'}
          />

          <StatsCard
            title="Team A Business"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.businessIncomeFirstTeam}
            leftTitle={
              stats?.teamBusiness?.find(
                (_teamBusiness) => String(_teamBusiness.node) === '0'
              )?.amount ?? '0'
            }
            rightTitle={'Last 24hr changes'}
          />
          <StatsCard
            title="Team B Business"
            image={'/assets/images/total-direct-partner-icon.png'}
            withImage
            count={userContext?.accountDetails?.businessIncomeSecondTeam}
            leftTitle={
              stats?.teamBusiness?.find(
                (_teamBusiness) => String(_teamBusiness.node) === '1'
              )?.amount ?? '0'
            }
            rightTitle={'Last 24hr changes'}
          />
        </div>
      </div>
    </section>
  );
};

const SecondSection = ({ refProfitsStats }) => {
  const { accountDetails } = useContext(UserContext);
  return (
    <div className="section-five-main">
      <div className="section-five-main-left">
        <ActivityFeed />
        {/* <div className="section-five-main-left-top">
          <div className="section-five-main-left-top-inner">
            <CardVariant2
              title={'Total Members'}
              count={refProfitsStats?.childPartners}
              delta={''}
            />
            <CardVariant2
              title={'Team profit'}
              count={`${parseFloat(refProfitsStats?.teamProfit).toFixed(
                2
              )} USDT`}
              delta={''}
            />
          </div>
        </div> */}
      </div>
      <div className="section-five-main-right my-direct">
        <div className="section-five-main-right-top">
          <h3>My Directs</h3>
        </div>
        <div className="section-five-main-right-bottom">
          <ul>
            {Array.isArray(accountDetails?.myReferrals)
              ? accountDetails?.myReferrals.map((user) => {
                  return <li>ID: {user?.referralCode}</li>;
                })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StatasticsWrapper = () => {
  return (
    <>
      <StatsCardSection />
      <NFTCardList />
      <section className="section-five" id="market">
        <div className="section-three-main-title platform-section">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Platform</h2>
            </div>
            <div className="section-three-main-title-inner-info ">
              <Link to="/"></Link>
            </div>
          </div>
        </div>
        <SecondSection />
      </section>
    </>
  );
};
export const DashView = () => {
  return (
    <Content>
      <TermsModal />
      <StatasticsWrapper />
      <section className="section-six">
        <div className="section-six-main">
          <div className="section-six-main-inner">
            <h2>Smart Contract</h2>
          </div>

          <div className="section-six-main-upload">
            <h3>
              <a
                target={'_blank'}
                href={`${getConfig(
                  'explorerLink'
                )}address/0x6da8a67989cbecbc971d574522081df25416b057`}
                className="wallet-icon-two"
              >
                0x6da8a67989cbecbc971d574522081df25416b057
              </a>
            </h3>
          </div>
        </div>
      </section>
    </Content>
  );
};
