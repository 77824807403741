import { AdminConfig } from '../../components/admin-config/list';
import { Content } from '../../components/layout/content';

export const AdminConfigView = () => {
  return (
    <Content>
      <AdminConfig />
    </Content>
  );
};
