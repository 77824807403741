import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getConfig } from '../../../config';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { formatDate } from '../../../utils/formator/formator';
import { useGetBoughtPacks } from '../../../hooks/packs/useGetBoughtPacks';

export const BoughtPackList = () => {
  const { packs, loading, approveBuyPack, approving } = useGetBoughtPacks();
  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Bought Packs</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div className="section-three-main-detail-table">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th>Date</th>
                <th>Transaction</th>
                <th>Account</th>
                <th>Pack</th>
                <th>Status</th>
                <th>NFTs</th>
                <th>Action</th>
              </tr>
              {packs?.data?.records?.length <= 0
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((d) => <tr></tr>)
                : null}
              {Array.isArray(packs?.data?.records) &&
                packs?.data?.records?.map((pack) => {
                  return (
                    <tr>
                      <td>{formatDate(pack?.createDateTime)}</td>
                      <td>
                        <div className="section-three-main-detail-table-box-main">
                          <span>{shortenAccountAddr(pack?.txHash)}</span>
                          <span>
                            <CopyToClipboard
                              text={pack?.txHash}
                              onCopy={() => alert('Copied!')}
                            >
                              <a href="javascript:void(0)">
                                <img
                                  src="/assets/images/link-grey-icon.png"
                                  alt="link-grey-icon"
                                />
                              </a>
                            </CopyToClipboard>
                          </span>
                          <span>
                            <a
                              href={`${getConfig('explorerLink')}tx/${
                                pack?.txHash
                              }`}
                              target={'_blank'}
                            >
                              <img
                                src="/assets/images/open-link-icon.png"
                                alt="open-link-icon"
                              />
                            </a>
                          </span>
                        </div>
                      </td>
                      <td>{pack?.user?.accountAddress}</td>
                      <td>
                        {pack?.pack?.name} ($
                        {pack?.packPrice} USDT)
                      </td>
                      <td>{pack?.status}</td>
                      <td>{pack?.pack?.totalNFTs || '0'} NFTs</td>
                      <td>
                        <button
                          className="btns"
                          onClick={() => {
                            if (
                              pack?.status === 'nft_distributed' ||
                              pack?.status === 'initiated'
                            ) {
                              return;
                            }
                            if (!approving) {
                              approveBuyPack(
                                pack?.pack?._id,
                                pack?.id,
                                pack?.user?.accountAddress
                              );
                            }
                          }}
                        >
                          {approving
                            ? 'Please wait'
                            : pack?.status === 'nft_distributed' ||
                              pack?.status === 'initiated'
                            ? pack?.status
                            : 'Approve Buy'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
