import React, { useEffect } from 'react';
import { MasterContext } from '../../../context/MasterContexts';
import { UserContext } from '../../../context/UserContext';
import { useGetAccountDetails } from '../../../hooks/account/useGetAccountDetails';
import { useGetMasters } from '../../../hooks/account/useGetMasters';
import { useMyAccount } from '../../../hooks/account/useMyAccount';
import { Footer } from '../footer';
import { Header } from '../header';
import { SideBar } from '../sidebar';

export const Content = ({ children }) => {
  const { wallet, fetchDetails } = useMyAccount();
  const { accountDetails, setAccountDetails, getAccountDetails, stats } =
    useGetAccountDetails(wallet);
  const { masters, setMasters, loading } = useGetMasters();

  useEffect(() => {
    fetchDetails();
  }, []);

  const masterValue = {
    masters,
    setMasters,
    loading,
  };

  const userValue = {
    accountDetails,
    setUser: setAccountDetails,
    getAccountDetails,
    loading,
    stats,
  };

  return (
    <MasterContext.Provider value={masterValue}>
      <UserContext.Provider value={userValue}>
        <div className="wrapper">
          <div className="hero-main-section cf">
            <div className="hero-main-section-inner cf">
              <SideBar />
              <div className="hero-main-section-right cf">
                <div className="midd-container">
                  <Header wallet={wallet} accountDetails={accountDetails} />
                  {children}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </UserContext.Provider>
    </MasterContext.Provider>
  );
};
