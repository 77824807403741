import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import {
  fetchAccountDetailsBasedOnAccountAddress,
  fetchTree,
} from '../../services/user';

export const useGetPartnersTree = () => {
  const userContext = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [partners, setPartners] = useState({});
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('myReferrals');
  const [loadingObj, setLoadingObj] = useState({});
  const [loadedTree, setLoadedTree] = useState({});
  const [currentDetails, setCurrentDetails] = useState(0);
  const [detailModal, setDetailsModal] = useState({});

  const _getPartnersTreeV2 = async (address, name) => {
    const data = await fetchTree(address, type, search);
    if (type !== 'myReferrals') {
      setPartners(data);
    }
    setLoadedTree({
      ...loadedTree,
      [name]: data,
    });

    setLoading(false);
    return data?.children ?? [];
  };

  const _getPartnersTree = async (address, init = false) => {
    try {
      if (!init) {
        setLoadingObj({
          [address]: true,
        });
      }
      const data = await fetchAccountDetailsBasedOnAccountAddress(
        address,
        type,
        init ? search : ''
      );
      if (init) {
        const d = formatUser(data);
        setPartners(d);
        const directData = {};
        d?.children?.forEach(
          (_dataToReturn) => (directData[_dataToReturn?.name] = null)
        );
        setLoadedTree(directData);
        setLoading(false);
        return;
      }
      const dataToReturn = data[type]?.map((myReferral) =>
        formatUser(myReferral)
      );
      if (!init) {
        setLoadingObj({
          [address]: false,
        });
      }
      return dataToReturn;
    } catch (error) {
      console.log({ error });
    }
  };

  const formatUser = (user) => {
    return {
      ...user,
      name: user?.referralCode,
      email: user?.email,
      children: user[type]?.map((myReferral) => formatUser(myReferral)) ?? [],
    };
  };

  const _openDetailsModal = async (referralCode) => {
    setCurrentDetails(referralCode);
    if (detailModal[referralCode]) {
      return detailModal[referralCode];
    }

    const data = await fetchAccountDetailsBasedOnAccountAddress(
      undefined,
      undefined,
      '',
      false,
      referralCode
    );

    setDetailsModal({
      ...detailModal,
      [referralCode]: data,
    });
  };

  useEffect(() => {
    setPartners({});
    if (userContext?.accountDetails?.accountAddress) {
      if (type === 'myReferrals') {
        _getPartnersTree(userContext?.accountDetails?.accountAddress, true);
      } else {
        _getPartnersTreeV2(userContext?.accountDetails?.accountAddress, true);
      }
    }
  }, [userContext?.accountDetails?.accountAddress, type, search]);

  return {
    partners,
    loading,
    accountDetails: userContext?.accountDetails,
    _getPartnersTree,
    loadingObj,
    type,
    setType,
    setSearch,
    search,
    searchInput,
    setSearchInput,
    loadedTree,
    _getPartnersTreeV2,
    _openDetailsModal,
    detailModal,
    setDetailsModal,
    currentDetails,
  };
};
