import { useEffect, useState } from 'react';
import { getPacks } from '../../services/packs';

const initState = {
  data: {
    records: [],
    total: 0,
  },
  error: null,
  loading: true,
};
export const useGetPacks = () => {
  const [packs, setPacks] = useState({ ...initState });
  const getData = async () => {
    setPacks({
      ...initState,
    });
    try {
      const packResponse = await getPacks();
      setPacks({
        ...packs,
        data: packResponse,
        loading: false,
      });
    } catch (error) {
      setPacks({
        ...initState,
        loading: false,
        error,
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return {
    packs,
    getData,
  };
};
