import React from 'react';
import { useGetMyActivities } from '../../../hooks/account/useGetMyActivities';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { ActivityFeedItem } from './item';
import moment from 'moment';
export const ActivityFeed = () => {
  const { activities, loading } = useGetMyActivities();
  return (
    <div
      className="section-five-main-left-bottom"
      style={{ height: 400, overflowY: 'auto' }}
    >
      <div className="activity-feed-section">
        <div className="activity-feed-top">
          <h3>Activity feed</h3>
        </div>
        <div className="activity-feed-bottom">
          {loading ? 'Loading...' : null}
          {activities?.records?.map((record) => {
            return (
              <ActivityFeedItem
                title={record?.description}
                id={shortenAccountAddr(record?.accountAddress)}
                time={moment
                  .duration(moment().diff(record?.createDateTime))
                  .humanize()}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
