import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getConfig } from '../../../config';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { formatDate } from '../../../utils/formator/formator';

export const PartnersList = ({
  partners,
  loading,
  setPage,
  page,
  view,
  setSearch,
  setSearchInput,
  searchInput,
}) => {
  const totalPages = partners?.total / 20;
  let pageIs = 0;
  const pageArray = [];
  while (totalPages > pageIs) {
    pageArray.push(pageIs);
    pageIs++;
  }

  return (
    <section className="section-partners">
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Partners</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div
            className="wallet-address-box"
            style={{ marginTop: 24, display: 'flex' }}
          >
            <div className="wallet-address-detail">
              <div className="wallet-address-box">
                <input
                  value={searchInput}
                  className="input-style"
                  placeholder="Search..."
                  onChange={(e) => setSearchInput(e.target.value)}
                  onSubmit={() => {
                    setSearch(searchInput);
                  }}
                />
              </div>
            </div>
            <div className="wallet-address-btn" style={{ margin: 10 }}>
              <button
                onClick={() => {
                  setSearch(searchInput);
                }}
              >
                Search
              </button>
            </div>
          </div>

          <div className="section-three-main-detail-table partners-list">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Address</th>
                <th>Email</th>
                <th>Individual Income</th>
                <th>Business Income</th>
                {view === 'team' ? (
                  <>
                    <th>Team A Business</th>
                    <th>Team B Business</th>
                  </>
                ) : null}
              </tr>
              <tbody>
                {Array.isArray(partners?.records) &&
                  partners?.records?.map((partner) => {
                    return (
                      <tr>
                        <td>
                          <img
                            src="/assets/images/table-partners.svg"
                            alt="table-icon"
                          />
                        </td>
                        <td>{formatDate(partner?.createDateTime)}</td>
                        <td>
                          <div className="section-three-main-detail-table-box-main">
                            <div
                              className="table-chip"
                              style={{
                                background: '#463991',
                                color: 'white',
                              }}
                            >
                              {shortenAccountAddr(partner?.accountAddress)}
                            </div>
                            <span>
                              <CopyToClipboard
                                text={partner?.accountAddress}
                                onCopy={() => alert('Copied!')}
                              >
                                <a href="javascript:void(0)">
                                  <img
                                    src="/assets/images/link-grey-icon.png"
                                    alt="link-grey-icon"
                                  />
                                </a>
                              </CopyToClipboard>
                            </span>
                            <span>
                              <a
                                href={`${getConfig('explorerLink')}address/${
                                  partner?.accountAddress
                                }`}
                                target={'_blank'}
                              >
                                <img
                                  src="/assets/images/open-link-icon.png"
                                  alt="open-link-icon"
                                />
                              </a>
                            </span>
                          </div>
                        </td>
                        <td>
                          {partner?.email} (
                          {partner?.node === 0 ? 'Team A' : 'Team B'})
                        </td>
                        <td>{partner?.individualIncome}</td>
                        <td>{partner?.businessIncome}</td>
                        {view === 'team' ? (
                          <>
                            <td>{partner?.businessIncomeFirstTeam}</td>
                            <td>{partner?.businessIncomeSecondTeam}</td>
                          </>
                        ) : null}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="pagination-div">
            <ul className="pagination">
              {page > 0 ? (
                <li
                  className="page-item"
                  onClick={() => {
                    if (page >= 0) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <a className="page-link" href="javascript:void(0)">
                    Previous
                  </a>
                </li>
              ) : null}
              {pageArray.map((p) => {
                return (
                  <li
                    className={`page-item ${p} ${
                      page === p ? 'active' : ''
                    } ${totalPages}`}
                    key={p}
                    onClick={() => setPage(p)}
                  >
                    <a className="page-link" href="javascript:void(0)">
                      {p + 1}
                    </a>
                  </li>
                );
              })}
              {page < pageArray.length - 1 ? (
                <li
                  className="page-item"
                  onClick={() => {
                    if (page <= pageArray.length) {
                      setPage(page + 1);
                    }
                  }}
                >
                  <a className="page-link" href="javascript:void(0)">
                    Next
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
