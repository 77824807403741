import * as Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { getConfig } from '../../config';
import {
  CONTRACT_ABI,
  CUBIX_ICO_CONTRACT_ABI,
  CUBIX_TOKEN_CONTRACT_ABI,
  NFT_CONTRACT_ABI,
  NFT_DISTRIBUTION_ABI,
  TOKEN_CONTRACT_ABI,
  USDC_TOKEN_CONTRACT_ABI,
  USDT_WALLET_ABI,
} from '../../config/constants/web3.constants';
import axios from 'axios';

window.web3Instance = new Web3(Web3.givenProvider);

export const connectTrustWallet = async () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: 'https://bsc-dataseed1.binance.org',
        },
        chainId: 56,
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  const provider = await web3Modal.connect();
  await web3Modal.toggleModal();

  window.web3Instance = new Web3(provider);
};

export const contract = new window.web3Instance.eth.Contract(
  CONTRACT_ABI,
  getConfig('contractAddress')
);

export const tokenContract = new window.web3Instance.eth.Contract(
  TOKEN_CONTRACT_ABI,
  getConfig('tokenContractAddress')
);

export const nftContract = new window.web3Instance.eth.Contract(
  NFT_CONTRACT_ABI,
  '0xAa5800aF7A65bA72980Ff69d1b16F1C00b2f895a'
);

export const secondTokenContract = new window.web3Instance.eth.Contract(
  TOKEN_CONTRACT_ABI,
  getConfig('secondTokenContractAddress')
);

export const getRecommendedGasPrice = async () => {
  const gasStation = await axios.get(
    `${getConfig('gasStationAPI')}`
  );
  const fastTxGasFee = gasStation?.data?.fast?.maxFee;
  return Web3.utils.toWei(String(Math.ceil(fastTxGasFee)), 'gwei');
};

export const cubixTokenContract = new window.web3Instance.eth.Contract(
  CUBIX_TOKEN_CONTRACT_ABI,
  getConfig('cubixTokenAddress')
);

export const usdcTokenContract = new window.web3Instance.eth.Contract(
  USDC_TOKEN_CONTRACT_ABI,
  getConfig('usdcTokenAddress')
);

export const iconContract = new window.web3Instance.eth.Contract(
  CUBIX_ICO_CONTRACT_ABI,
  getConfig('icoContractAddress')
);

export const nftDistributionContract = new window.web3Instance.eth.Contract(
  NFT_DISTRIBUTION_ABI,
  getConfig('nftDistributionContract')
);

export const usdtWalletContract = new window.web3Instance.eth.Contract(
  USDT_WALLET_ABI,
  getConfig('usdtWalletContract')
);
