import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const SetPasswordView = ({ data, setData, callSetPassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className="wrapper">
        <div className="hero-main-section cf">
          <div className="hero-main-section-inner-register cf">
            <div className="hero-main-section-detail cf">
              <div className="midd-container">
                <section className="welcome-back-section cf">
                  <div className="welcome-back-detail">
                    <div className="welcome-back-detail-inner">
                      <div className="welcome-back-detail-inner-left">
                        <div className="welcome-back-detail-inner-left-inner">
                          <div className="welcome-back-detail-inner-left-inner-top">
                            <div className="welcome-back-detail-inner-left-logo-box">
                              <Link to="/" className="welcome-back-logo">
                                <img
                                  src="/assets/images/Logo.svg"
                                  alt="logo"
                                  title="Cubix"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-left-inner-bottom">
                            <div className="welcome-back-detail-inner-left-inner-bottom-inner">
                              <div className="welcome-back-detail-inner-left-inner-bottom-detail">
                                <div className="welcome-back-detail-left-top-title">
                                  <h2>Set your password</h2>
                                </div>
                              </div>
                              <div className="wallet-address-section">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    callSetPassword();
                                  }}
                                >
                                  <div className="wallet-address-detail">
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.password}
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        className="input-style"
                                        placeholder="Enter your password"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.confirmPassword}
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        className="input-style"
                                        placeholder="Enter your confirm password"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            confirmPassword: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="wallet-address-box">
                                      <label>
                                        <input
                                          style={{ margin: 12 }}
                                          checked={showPassword}
                                          type="checkbox"
                                          onChange={(e) =>
                                            setShowPassword(!showPassword)
                                          }
                                        />
                                        Show Password
                                      </label>
                                    </div>

                                    <div className="wallet-address-btn">
                                      <button type="submit">
                                        Set Password
                                      </button>
                                    </div>

                                    <div
                                      className="wallet-address-btn"
                                      style={{ textAlign: 'center' }}
                                    >
                                      <Link
                                        to="/login"
                                        style={{ color: '#DBFF01' }}
                                      >
                                        Back to Login
                                      </Link>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="welcome-back-detail-inner-right">
                        <div className="welcome-back-detail-inner-right-inner">
                          <div className="welcome-back-detail-inner-right-top">
                            <div className="welcome-back-detail-inner-right-top-detail">
                              <h2>Welcome</h2>
                              <p>
                                Cubix, the <span>Blockchain</span>-based{' '}
                                <span>Fantasy Sports</span> game.
                                <span>Your</span> game, <span>play</span> it
                                your way.
                              </p>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-center">
                            <div className="welcome-back-detail-inner-right-center-detail">
                              <img
                                src="/assets/images/welcome-right-img.png"
                                alt="logo"
                                title="welcome-right-img"
                              />
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-bottom"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
