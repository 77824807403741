import { Content } from '../../components/layout/content';
import { WalletComponent } from '../../components/wallet/WalletComponent';

export const WalletPageView = () => {
  return (
    <Content>
      <WalletComponent />
    </Content>
  );
};
