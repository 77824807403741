import React from 'react';
import { useMyAccount } from '../../../hooks/account/useMyAccount';
import { RegisterView } from './view';

export const Login = () => {
  const { callLogin, loginData, setLoginData, loading } = useMyAccount(true);

  return (
    <RegisterView
      callLogin={callLogin}
      data={loginData}
      setData={setLoginData}
      loading={loading}
    />
  );
};
