import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import swal from 'sweetalert';
import Web3 from 'web3';
import { getConfig } from '../../../../config';
import { UserContext } from '../../../../context/UserContext';
import {
  contract,
  getRecommendedGasPrice,
  tokenContract,
} from '../../../../utils/web3/init';
import { useBuyPack } from '../../../../hooks/account/useBuyPack';

export const NFTCardItem = ({ _id, image, title, desc, price, isActive }) => {
  const numberId = _id;
  const [loading, setLoading] = useState(false);
  const { accountDetails } = useContext(UserContext);

  const [approved, setApproved] = useState(false);

  const { loading: buying, buyPackCall } = useBuyPack();

  // const [cubixTokenForOneUSDC, setCubixTokenForOneUSDC] = useState(0);

  useEffect(() => {
    // getCubixTokenForOneUSDC();
  }, []);

  // const getCubixTokenForOneUSDC = async () => {
  //   const response = await contract.methods.cubixTokenForOneUSDC().call();
  //   setCubixTokenForOneUSDC(parseFloat(response));
  // };

  const amount = 0;
  // const amount = Web3.utils.toWei(
  //   String(price * cubixTokenForOneUSDC),
  //   'ether'
  // );

  const initBuyPackWithoutMetamask = async (packId) => {
    await buyPackCall({
      packId: packId,
    });
  };

  const initBuyPack = async (isUSDT = false) => {
    try {
      setLoading(true);
      const fastTxGasFee = await getRecommendedGasPrice();

      let tokenContractToConsider = tokenContract;

      const allowance = await tokenContractToConsider.methods
        .allowance(accountDetails?.accountAddress, getConfig('contractAddress'))
        .call({
          gasPrice: fastTxGasFee,
        });

      if (
        parseInt(Web3.utils.fromWei(allowance, 'ether')) >=
        parseInt(Web3.utils.fromWei(amount, 'ether'))
      ) {
        setApproved(true);
        buyPack(false);
        return;
      }
      const fastTxGasFeeForApproval = await getRecommendedGasPrice();
      console.log({ fastTxGasFeeForApproval });
      tokenContractToConsider.methods
        .approve(getConfig('contractAddress'), amount)
        .send({
          from: accountDetails?.accountAddress,
          gasPrice: fastTxGasFeeForApproval,
        })
        .on('transactionHash', function (hash) {
          console.log({ hash });
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          if (confirmationNumber === 3) {
            setApproved(true);
            setLoading(false);
          }
        })
        .on('receipt', function (receipt) {
          console.log({ receipt });
        })
        .on('error', function (error) {
          console.log({ error });
          setLoading(false);
        });
    } catch (error) {
      console.log({ error });
      alert(error.message);
      setLoading(false);
      return;
    }
  };

  const buyPack = async (isUSDT = false) => {
    try {
      const fastTxGasFeeForBuy = await getRecommendedGasPrice();

      if (accountDetails.isActive) {
        contract.methods
          .buyPack(numberId, false)
          .send({
            from: accountDetails?.accountAddress,
            gasPrice: fastTxGasFeeForBuy,
          })
          .on('transactionHash', function (hash) {
            swal(
              'Information!',
              'Thanks for buying pack, once transaction successful on network you will have the corresponding NFTs in your wallet.',
              'success'
            );
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              swal(
                'Cheers!',
                'Pack buy successfully and NFTs of pack also transfer successfully.',
                'success'
              );
              setLoading(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          })
          .on('receipt', function (receipt) {
            console.log({ receipt });
          })
          .on('error', function (error) {
            console.log({ error });
            setLoading(false);
            setApproved(false);
            swal(
              'Error!',
              'Something went wrong, Please try again after sometime.',
              'error'
            );
          });

        return;
      }

      contract.methods
        .regUser(numberId, accountDetails?.parent?.accountAddress, false)
        .send({
          from: accountDetails?.accountAddress,
          gasPrice: fastTxGasFeeForBuy,
        })
        .on('transactionHash', function (hash) {
          console.log({ hash });
          swal(
            'Information!',
            'Thanks for buying pack, once transaction successful on network you will have the corresponding NFTs in your wallet.',
            'success'
          );
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          if (confirmationNumber === 3) {
            swal(
              'Cheers!',
              'Pack buy successfully and NFTs of pack also transfer successfully.',
              'success'
            );
            setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .on('receipt', function (receipt) {
          console.log({ receipt });
        })
        .on('error', function (error) {
          console.log({ error });
          setApproved(false);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  return (
    <div className="pack-box aos-init aos-animate">
      <div className="img-box">
        <img src={String(image).replace('http://', 'https://')} alt={title} />
      </div>
      <div className="text-box">
        <div className="innter-text">
          <div className="left-text">
            <p className="name">{title}</p>
          </div>
        </div>
        <div className="info-text">{desc}</div>
        {getConfig('dualMode') === true ? (
          <div className="pack-boxs-flex-main">
            <div className="pack-boxs-flex-main-inner">
              <div className="left-text">
                <p className="price">USDT {price}</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="pack-boxs-flex-main">
          <div className="pack-boxs-flex-main-inner">
            {getConfig('dualMode') !== true ? (
              <div className="left-text">
                <p className="price" style={{ paddingBottom: 4 }}>
                  USDT {price}{' '}
                </p>
                {/* <span style={{ fontSize: 12 }}>(30% off)</span> */}
              </div>
            ) : null}
            <a
              onClick={() => {
                if (isActive) {
                  initBuyPackWithoutMetamask(_id);
                }
              }}
              href="javascript:void(0)"
              title="BUY NOW"
              className={`${!isActive ? 'disabled ' : ''}btns`}
            >
              {loading || buying ? 'Please wait...' : 'BUY NOW'}
            </a>

            {/* Can ignore below snippets */}
            {getConfig('dualMode') === true ? (
              <a
                onClick={() => initBuyPack(false)}
                href="javascript:void(0)"
                title="BUY With WFH"
                className="btns"
              >
                {loading
                  ? 'Please wait...'
                  : approved ||
                    parseInt(
                      Web3.utils.fromWei(
                        accountDetails?.allowance || '',
                        'ether'
                      )
                    ) >= parseInt(Web3.utils.fromWei(amount, 'ether'))
                  ? 'BUY NOW'
                  : 'APPROVE WFH'}
              </a>
            ) : null}
            {/* Can ignore above snippets */}
          </div>
        </div>
      </div>
    </div>
  );
};
