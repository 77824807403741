import { APIConstants } from './constants/api.constant';
import { Web3Constants } from './constants/web3.constants';

export const envs = {
  mode: 'prod',
};

const socialLinks = {
  telegram: '',
  twitter: '',
  messenger: '',
};

export const configs = {
  dev: {
    ...Web3Constants.dev,
    ...APIConstants.dev,
    ...socialLinks,
  },
  prod: {
    ...Web3Constants.prod,
    ...APIConstants.prod,
    ...socialLinks,
  },
  stage: {
    ...Web3Constants.stage,
    ...APIConstants.stage,
    ...socialLinks,
  },
};

export const getConfig = (key) => {
  return configs[envs.mode]?.[key] ?? '';
};

export const isCubixToken = (data) => {
  return String(data).endsWith('000000000000000000')
}