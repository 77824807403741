import React from 'react';
import { useGetPacks } from '../../../../hooks/packs/useGetPacks';
import { NFTCardItem } from './item';

export const NFTCardList = () => {
  const { packs } = useGetPacks();
  return (
    <section className="section-three" id="fs">
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>NFT Packs</h2>
            </div>
            <div className="section-three-main-title-inner-info"></div>
          </div>
        </div>
        <section className="section-4" id="market">
          <div className="pack-boxs">
            {packs?.loading ? 'Loading...' : null}
            {packs?.data?.records?.map((pack) => {
              return <NFTCardItem {...pack} desc={pack?.desc} />;
            })}
          </div>
        </section>
      </div>
    </section>
  );
};
