import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {
  getDetailsBasedOnCode,
  loginAccountDetails,
  registerAccountDetails,
  setNotRegisteredEmail,
} from '../../services/user';

export const shortenAccountAddr = (address) => {
  return `${String(address).slice(0, 7)}...${String(address).slice(-4)}`;
};
export const useMyAccount = (move) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    referredBy: '',
    parent: '',
  });

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [emailData, setEmailData] = useState({
    email: '',
  });

  const navigateToDash = () => {
    navigate('/dash', {
      replace: true,
    });
  };

  const fetchDetails = () => {};

  const callLogin = async () => {
    if (!loginData?.email || !loginData?.password) {
      swal('Error!', 'Please enter valid details', 'error');
      return;
    }

    if (move) {
      try {
        setLoading(true);
        const response = await loginAccountDetails({
          ...loginData,
        });

        if (response) {
          sessionStorage.setItem('accountAddress', response?.accountAddress);
          sessionStorage.setItem('x-api-token', response?.accessToken);
          sessionStorage.setItem('user', JSON.stringify(response));
          navigateToDash();
        }
      } catch (error) {
        swal('Error!', error?.message ?? error, 'error');
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDetailsBasedOnCodeGiven = async () => {
    const query = new URLSearchParams(location.search);
    const team = query.get('team');
    const code = query.get('code');

    if (team) {
      const response = await getDetailsBasedOnCode(code);
      setData({
        ...data,
        referredBy: response?.accountAddress,
        team: `team${team}`,
        code,
      });
    }
  };

  const callRegister = async () => {
    if (!data?.referredBy || !data?.parent || !data?.email) {
      swal('Error!', 'Please enter valid details', 'error');
      return;
    }

    if (move) {
      try {
        setLoading(true);
        const response = await registerAccountDetails({
          ...data,
        });
        if (response) {
          swal(
            'Success!',
            'You have registered successfully, Please set your password.',
            'success'
          ).then(() => {
            navigate('/login', {
              replace: true,
            });
          });
        }
      } catch (error) {
        swal('Error!', error, 'error');
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      !sessionStorage.getItem('accountAddress') &&
      location.pathname !== '/register'
    ) {
      if (window.location.pathname === '/login') {
        sessionStorage.clear();
        return;
      }

      sessionStorage.clear();
      window.location.replace('/register');
    }
    getDetailsBasedOnCodeGiven();
  }, []);

  const callSetEmail = async () => {
    try {
      console.log('window.web3Instance', window.web3Instance);

      setLoading(true);
      if (window?.ethereum?.isMetaMask) {
        await window.ethereum.send('eth_requestAccounts');
      }
      const _account = await window.web3Instance.eth.getAccounts();

      if (!emailData?.email && !_account?.[0]) {
        swal(
          'Error!',
          'Please enter valid details or check if metamask is installed and connected',
          'error'
        );
      }

      if (_account[0]) {
        const nonce = Math.random();
        const result = await window.web3Instance.eth.personal.sign(
          window.web3Instance.utils.fromUtf8(
            `Signing with one-time nonce for wallet: ${nonce}`
          ),
          _account[0],
          String(nonce)
        );
        await setNotRegisteredEmail({
          email: emailData?.email,
          signature: result,
          nonce,
        });

        swal(
          'Success!',
          'You have set email successfully, Please set your password by clicking link sent to your email.',
          'success'
        ).then(() => {
          navigate('/login', {
            replace: true,
          });
        });
      }
    } catch (error) {
      console.log(error);
      swal('Error!', error?.message ?? error, 'error');
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    wallet: sessionStorage.getItem('accountAddress'),
    walletShort: shortenAccountAddr(sessionStorage.getItem('accountAddress')),
    loading,
    callRegister,
    data,
    setData,
    navigateToDash,
    fetchDetails,
    setLoginData,
    loginData,
    callLogin,
    callSetEmail,
    emailData,
    setEmailData,
  };
};
