export const withAuth = (Component) => {
  if (window.location.pathname.includes('forgot-password')) {
    return Component;
  }
  if (window.location.pathname.includes('set-password')) {
    return Component;
  }

  if (window.location.pathname.includes('login')) {
    return Component;
  }

  const isLoggedIn = sessionStorage.getItem('accountAddress');
  if (
    !isLoggedIn &&
    !['/', '/register', '/login'].includes(window.location.pathname)
  ) {
    window.location.replace('/register');
    return null;
  }
  return Component;
};
