import React from 'react';
import { ForgotPasswordView } from './view';
import { useResetPassword } from '../../../hooks/account/useResetPassword';

export const ForgotPassword = () => {
  const { loading, forgotPassword, setForgotPassword, callForgotPassword } =
    useResetPassword(true);

  return (
    <ForgotPasswordView
      loading={loading}
      callForgotPassword={callForgotPassword}
      data={forgotPassword}
      setData={setForgotPassword}
    />
  );
};
