import React from 'react';
import { useMyAccount } from '../../../hooks/account/useMyAccount';
import { RegisterView } from './view';

export const Register = () => {
  const {
    registration,
    error,
    loading,
    callRegister,
    data,
    setData,
    emailData,
    setEmailData,
    callSetEmail,
  } = useMyAccount(true);

  return (
    <RegisterView
      registration={registration}
      error={error}
      loading={loading}
      callRegister={callRegister}
      data={data}
      setData={setData}
      emailData={emailData}
      setEmailData={setEmailData}
      callSetEmail={callSetEmail}
    />
  );
};
