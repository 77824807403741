import React from 'react';
import { withAuth } from '../../hoc/auth/withAuth';
import { MyIncomePageView } from './view';
import { useParams } from 'react-router-dom';

const MyIncomePage = () => {
  const { bonusType } = useParams();
  return <MyIncomePageView bonusType={bonusType} />;
};

export default withAuth(MyIncomePage);
