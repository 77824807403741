import { Content } from '../../components/layout/content';
import { PackList } from '../../components/packs/list';

export const PacksView = () => {
  return (
    <Content>
      <PackList />
    </Content>
  );
};
