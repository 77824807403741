import { Content } from '../../components/layout/content';
import { MyIncomeList } from '../../components/my-income/list';

export const MyIncomePageView = ({ bonusType }) => {
  return (
    <Content>
      <MyIncomeList bonusType={bonusType} />
    </Content>
  );
};
