import { Content } from '../../components/layout/content';
import { BackdoorForm } from '../../components/withdrawals/list/backdoorForm';

export const BackdoorEntryView = () => {
  return (
    <Content>
      <BackdoorForm />
    </Content>
  );
};
