import React from 'react';
import { useGetPacks } from '../../hooks/packs/useGetPacks';

import HomePageView from './view';
const HomePage = () => {
  const { packs } = useGetPacks();

  return <HomePageView packs={packs} />;
};

export default HomePage;
