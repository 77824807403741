import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { forgotPasswordCall, setPassword } from '../../services/user';

export const useResetPassword = (move) => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [forgotPassword, setForgotPassword] = useState({
    email: '',
  });

  const navigateToLogin = () => {
    navigate('/login', {
      replace: true,
    });
  };

  const callSetPassword = async () => {
    if (!data?.password || !data?.confirmPassword) {
      swal('Error!', 'Please enter valid details', 'error');
      return;
    }
    if (data?.password !== data?.confirmPassword) {
      swal(
        'Error!',
        'Password and confirm password should match, Please check.',
        'error'
      );
      return;
    }

    if (move) {
      try {
        setLoading(true);
        await setPassword({
          ...data,
          token: params?.token,
        });
        swal(
          'Success',
          'Your password set successfully, can login into your account',
          'success'
        ).then(() => {
          navigateToLogin();
        });
      } catch (error) {
        swal('Error!', error, 'error');
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const callForgotPassword = async () => {
    if (!forgotPassword?.email) {
      swal('Error!', 'Please enter email', 'error');
      return;
    }

    try {
      setLoading(true);
      await forgotPasswordCall({
        ...forgotPassword,
      });
      swal(
        'Success',
        'Your reset password link has been sent successfully to registered email.',
        'success'
      ).then(() => {
        navigateToLogin();
      });
    } catch (error) {
      swal('Error!', error, 'error');
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    data,
    setData,
    callSetPassword,
    forgotPassword,
    setForgotPassword,
    callForgotPassword,
  };
};
