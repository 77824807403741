import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { fetchBoughtPacks } from '../../services/user';

export const useGetMyPacks = () => {
  const userContext = useContext(UserContext);
  const [packs, setPacks] = useState({});
  const [loading, setLoading] = useState(true);

  const getMyPacks = async (address) => {
    try {
      const data = await fetchBoughtPacks(address);
      setPacks(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      getMyPacks(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { packs, loading, getMyPacks };
};
