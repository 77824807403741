import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getConfig } from '../../../config';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { useGetMyPacks } from '../../../hooks/account/useGetMyPacks';
import { formatDate } from '../../../utils/formator/formator';

export const PackList = () => {
  const { packs, loading } = useGetMyPacks();
  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>My NFTs</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div className="section-three-main-detail-table">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Transaction</th>
                <th>Pack</th>
                <th>NFT(s)</th>
              </tr>
              {packs?.records?.length <= 0
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((d) => <tr></tr>)
                : null}
              {Array.isArray(packs?.records) &&
                packs?.records?.map((pack) => {
                  return (
                    <tr>
                      <td>
                        <img
                          src="/assets/images/table-nfts.svg"
                          alt="table-icon"
                        />
                      </td>
                      <td>{formatDate(pack?.createDateTime)}</td>
                      <td>
                        <div className="section-three-main-detail-table-box-main">
                          <div
                            className="table-chip"
                            style={{
                              background: '#51E16B',
                              color: 'blacks',
                            }}
                          >
                            {pack?.txHash
                              ? shortenAccountAddr(pack?.txHash)
                              : 'NA'}
                          </div>
                          <span>
                            <CopyToClipboard
                              text={pack?.txHash ?? 'NA'}
                              onCopy={() =>
                                alert(
                                  pack?.txHash
                                    ? 'Copied!'
                                    : 'NFT not distributed yet'
                                )
                              }
                            >
                              <a href="javascript:void(0)">
                                <img
                                  src="/assets/images/link-grey-icon.png"
                                  alt="link-grey-icon"
                                />
                              </a>
                            </CopyToClipboard>
                          </span>
                          <span>
                            <a
                              href={
                                pack?.txHash
                                  ? `${getConfig('explorerLink')}tx/${
                                      pack?.txHash
                                    }`
                                  : 'javascript:void(0)'
                              }
                              target={pack?.txHash ? '_blank' : ''}
                            >
                              <img
                                src="/assets/images/open-link-icon.png"
                                alt="open-link-icon"
                              />
                            </a>
                          </span>
                        </div>
                      </td>
                      <td>
                        {pack?.pack?.name} ($
                        {pack?.packPrice} USDT)
                      </td>
                      <td>{pack?.pack?.totalNFTs || '0'} NFT(s)</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
