import React from 'react';
import { Link } from 'react-router-dom';

export const StatsCard = ({
  title = '',
  image,
  count,
  withImage = false,
  leftTitle,
  rightTitle,
  color,
  className,
  link,
  countStyle,
}) => {
  if (!withImage) {
    return (
      <div
        className={'section-one-top-box ' + className}
        style={{ backgroundColor: color }}
      >
        <div className="earning-box-top">
          {link ? (
            <Link to={link}>
              <h4>{title}</h4>
            </Link>
          ) : (
            <h4>{title}</h4>
          )}
        </div>
        <div className="earning-box-center">
          <h2 style={countStyle}>{count}</h2>
        </div>
        <div className="earning-box-bottom">
          <div className="earning-box-bottom-inner">
            <div className="earning-box-bottom-inner-left">
              <p>{leftTitle}</p>
            </div>
            <div className="earning-box-bottom-inner-right">
              <p>{rightTitle}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'section-one-top-box ' + className}>
      <div className="section-one-center-box section-one-bg">
        <div className="section-one-center-box-top">
          {link ? (
            <Link to={link}>
              <p>{title}</p>
            </Link>
          ) : (
            <p>{title}</p>
          )}
        </div>
        <div className="section-one-center-box-middle">
          <h2>
            <span className="icon">
              <img src={image} alt="icon" />
            </span>
            <span className="text" style={countStyle}>
              {count}
            </span>
          </h2>
        </div>
        <div className="section-one-center-box-bottom">
          <div className="section-one-center-box-bottom-inner">
            <div className="section-one-center-box-bottom-inner-left">
              <span>{leftTitle}</span>
            </div>
            <div className="section-one-center-box-bottom-inner-right">
              <span>{rightTitle}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
