import React from 'react';
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getConfig } from '../../../config';

export const Header = ({ wallet, accountDetails }) => {
  const location = useLocation();
  return (
    <section className="hero-main-section-right-top">
      <div
        className="hero-main-section-right-top-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignTtems: 'center',
          flexWrap: 'wrap',
          paddingRight: '22px',
        }}
      >
        <div>
          <h2>
            Welcome to<span> CUBIX</span>
          </h2>
          <div className="hero-main-section-right-top-inner-content-main">
            <div className="hero-main-section-right-top-inner-content">
              <p>Wallet Address: {wallet}</p>
            </div>
            <div className="hero-main-section-right-top-inner-content-icon">
              <span>
                <CopyToClipboard text={wallet} onCopy={() => alert('Copied!')}>
                  <a href="javascript:void(0)" className="wallet-icon-one">
                    <img
                      src="/assets/images/wallet-icon-one.png"
                      alt="wallet-pic"
                    />
                  </a>
                </CopyToClipboard>
              </span>
              <span>
                <a
                  target={'_blank'}
                  href={`${getConfig('explorerLink')}address/${wallet}`}
                  className="wallet-icon-two"
                >
                  <img
                    src="/assets/images/wallet-icon-two.png"
                    alt="wallet-icon-2"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
        {['/dash'].includes(location.pathname) ? (
          <>
            <div className="hero-main-section-right-top-inner-content">
              <h2>Balance</h2>
              <h2>
                <span>
                  USDT {accountDetails?.walletProfile?.totalUSDTBalance}
                </span>
              </h2>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
};
