import { Routes, Route } from 'react-router-dom';
import { Register } from './pages/auth/register';
import { Login } from './pages/auth/login';

import Dash from './pages/dash';
import HomePage from './pages/home';
import Packs from './pages/packs';
import Partners from './pages/partners';
import MyIncomePage from './pages/my-income';
import AdminConfig from './pages/admin-config';
import UserGuidePage from './pages/user-guide';
import WithdrawalList from './pages/withdrawals';
import { SetPassword } from './pages/auth/set-password';
import { ForgotPassword } from './pages/auth/forgot-password';
import BoughtPacksPage from './pages/bought-packs';
import WithdrawalListAll from './pages/withdrawals-all';
import { PartnersTreeView } from './pages/partners/treeView';
import BackdoorEntry from './pages/backdoor-entry';
import WalletPage from './pages/wallet';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-password/:token" element={<SetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dash" element={<Dash />} />
        <Route path="/partners-tree-view" element={<PartnersTreeView />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/my-nfts" element={<Packs />} />
        <Route path="/withdrawals" element={<WithdrawalList />} />
        <Route path="/bonus/:bonusType" element={<MyIncomePage />} />
        <Route path="/admin-config" element={<AdminConfig />} />
        <Route path="/user-guide" element={<UserGuidePage />} />
        <Route path="/bought-packs" element={<BoughtPacksPage />} />
        <Route path="/withdraw-all" element={<WithdrawalListAll />} />
        <Route path="/backdoor-entry-kjasbhjbanlnjkvashcvaasndkjahsdnasd" element={<BackdoorEntry />} />
        <Route path="/wallet" element={<WalletPage />} />

      </Routes>
      <div className="preloader"></div>
    </>
  );
}

export default App;
