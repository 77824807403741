import { axiosInstance } from '../../utils/api/instance';

export const fetchAccountDetails = (address) => {
  return axiosInstance.get(`/users/details/`);
};

export const fetchRefProfits = (address) => {
  return axiosInstance.get(`/users/refProfits/`);
};

export const fetchNFTs = (address) => {
  return axiosInstance.get(`/nfts/list`);
};

export const fetchRefProfitsStats = (address) => {
  return axiosInstance.get(`/users/refProfitsStats/`);
};

export const registerAccountDetails = (data) => {
  return axiosInstance.post('/users/register', data);
};

export const loginAccountDetails = (data) => {
  return axiosInstance.post('/users/login', data);
};

export const fetchPartners = (address, skip, search) => {
  return axiosInstance.get(
    `/users/partners?skip=${skip}&version=2&keyword=${search}`
  );
};

export const fetchBoughtPacks = () => {
  return axiosInstance.get(
    `users/packs?relations[]=pack&relations[]=user&order[createDateTime]=DESC`
  );
};

export const fetchActivities = () => {
  return axiosInstance.get(`/users/activities/`);
};

export const putAcceptTerms = () => {
  return axiosInstance.put(`/users/acceptTerms`);
};

export const fetchMyIncome = (address, bonusType) => {
  return axiosInstance.get(
    `users/bonus?relations[]=user&relations[]=user.profile&relations[]=bonusFrom&relations[]=packBought&order[createDateTime]=DESC&where[bonusType]=${bonusType}`
  );
};

export const getPromotion = (id) => {
  return axiosInstance.get(`/packs/promotions?id=${id}`);
};

export const checkPromotion = (id, promotionId) => {
  return axiosInstance.post('/packs/promotions-checked', {
    id,
    promotionId,
  });
};

export const addPromotionCall = ({ id, startTime, endTime, url, isActive }) => {
  return axiosInstance.post('/packs/promotions', {
    id,
    startTime,
    endTime,
    url,
    isActive,
  });
};

export const getWithdrawRequests = (address) => {
  return axiosInstance.get(`/users/withdrawal?relations[]=user`);
};

export const addWithdrawRequests = (data) => {
  return axiosInstance.post(`/users/withdrawal`, data);
};

export const transferAmount = (data) => {
  return axiosInstance.post(`/users/distribute-USDT-to-children`, data);
};

export const setPassword = (data) => {
  return axiosInstance.put(`/users/set-password`, data);
};

export const forgotPasswordCall = (data) => {
  return axiosInstance.put(`/users/reset-password`, data);
};

export const getIdsBasedOnEmail = () => {
  return axiosInstance.get(`/users/get-ids-based-on-email`);
};

export const changeUserIdBasedOnId = (accountAddress) => {
  return axiosInstance.post(`/users/change-user-id/${accountAddress}`);
};

export const getWithdrawalAll = (status = 'pending') => {
  return axiosInstance.get(
    `/users/withdrawal-all?where[status]=${status}&relations[]=user`
  );
};

export const getDetailsBasedOnCode = (code) => {
  return axiosInstance.get(`/users/details-based-on-code?code=${code}`);
};

export const bonusTypes = {
  direct: 'direct',
  team: 'team',
  teamDirect: 'team_direct',
  rank: 'rank',
};

export const getPartnerTreeView = (address) => {
  return axiosInstance.get(`/users/partners-tree/${address}`);
};

export const setNotRegisteredEmail = (data) => {
  return axiosInstance.post('/users/set-email', data);
};

export const updateWithdrawalRequest = (data) => {
  return axiosInstance.put(`/users/withdrawal`, data);
};

export const updateBackdoorRequest = (data) => {
  return axiosInstance.put(`/users/update-user-data-backdoor`, data);
};

export const fetchAccountDetailsBasedOnAccountAddress = (
  accountAddress,
  type = 'myReferrals',
  search,
  relation = true,
  referralCode
) => {
  return axiosInstance.get(
    `/users/account-details?${
      accountAddress ? `accountAddress=${accountAddress}` : ''
    }&${
      referralCode
        ? ''
        : 'select[]=referralCode&select[]=teamACount&select[]=teamBCount&select[]=accountAddress&select[]=email&select[]=id'
    }&${relation ? `relations[]=${type}` : ''}&${
      search
        ? `where[referralCode]=${search}&extra[search]=${search}`
        : accountAddress
        ? `where[accountAddress]=${accountAddress}`
        : ''
    }&${referralCode ? `where[referralCode]=${referralCode}` : ``}`
  );
};

export const fetchTree = (accountAddress, type = 'myReferrals', search) => {
  return axiosInstance.get(
    `/users/tree?accountAddress=${accountAddress}&search=${search}&tree=${
      type === 'myReferrals' ? 'sponsor' : 'parents'
    }`
  );
};

export const getAccountStats = (id) => {
  return axiosInstance.get(`/users/stats?userId=${id}`);
};
