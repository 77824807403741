import React from 'react';
import { Content } from '../../components/layout/content';
import { withAuth } from '../../hoc/auth/withAuth';

const UserGuidePage = () => {
  return (
    <Content>
      <section className="section-main cf">
        <div
          className="referral-program-guidelines-section grey-bg mb-33 cf"
          style={{ marginTop: 32 }}
        >
          <div className="referral-program-guidelines-section-top mb-50">
            <h3 className="comman-title-back">Referral Program User Guide</h3>
          </div>
          <div className="referral-program-guidelines-section-center mb-50">
            <div className="referral-program-guidelines-section-center-image">
              <img
                src="/assets/images/bg-backend-referral.png"
                alt="bg-backend-referral"
              />
            </div>
          </div>
          <div className="referral-program-guidelines-section-bottom">
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon1.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Decentralisation:</h4>
                  <p>
                    The Cubix affiliate program is completely decentralized and
                    will operate via a smart contract on the polygon matic
                    network. A Cubix contract works on a P2P network.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon2.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Smart Contract ::</h4>
                  <p>
                    A smart contract is a tamper proof program which runs on a
                    blockchain network, the network here being Polygon. Smart
                    contracts cannot be meddled with once deployed which gives
                    security and safeguards the users.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon3.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Permanent Affiliate Program:</h4>
                  <p>
                    How often do you hear of a company changing its Referral
                    Program only receiving benefits as a result? DOWN WITH IT! A
                    Smart Contract cannot ever be changed.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon5.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Transparent:</h4>
                  <p>
                    When we see the pool, we cannot judge the depth same way
                    there are many hidden rules and criteria in different
                    referral programmes but Cubix has very simple and
                    transparent referral programme based on smart contract to
                    create more transparency.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon6.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>No legs to maintain:</h4>
                  <p>
                    Maintaining legs in an affiliate is a common practice but
                    with Cubix you don’t have to worry about your leg (
                    referral) contributing and you can earn nonetheless.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon7.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Permanent Level:</h4>
                  <p>
                    If you have archived any levels in referral programme, no
                    matter if you stop referring your friends still the level
                    you achieved will remain same and forever.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon8.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Zero Timeframe Targets:</h4>
                  <p>
                    There are no time based targets which reduces the pressure
                    and increases efficiency and the individual can work at his
                    or her speed.
                  </p>
                </div>
              </div>
            </div>
            <div className="referral-program-guidelines-content-box mb-15">
              <div className="referral-program-guidelines-content-box-inner">
                <div className="referral-program-guidelines-icon">
                  <img
                    src="/assets/images/referral-program-icon9.png"
                    alt="referral-program-icon"
                  />
                </div>
                <div className="referral-program-guidelines-content">
                  <h4>Fully Automatic Pay-outs:</h4>
                  <p>
                    The pay-outs are not manual and will be deployed via smart
                    contract which is fair and transparent with zero human
                    intervention in order to increase fairness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="registration-process-section grey-bg mb-33 cf">
          <div className="registration-process-section-top mb-69">
            <h3 className="comman-title-back">Registration Process</h3>
          </div>
          <div className="registration-process-section-bottom">
            <div className="registration-process-section-bottom-inner">
              <img
                src="/assets/images/registration-process-bg.png"
                alt="registration-process-bg"
              />
            </div>
          </div>
        </div>
        <div className="how-the-cubix-program-works-section grey-bg mb-33 cf">
          <div className="how-the-cubix-program-works-section-top mb-33">
            <h3 className="comman-title-back">
              How the CUBIX Affiliation Program Works.
            </h3>
          </div>
          <div className="how-the-cubix-program-works-section-content mb-50">
            <p>
              The CUBIX Affiliation program creates a residual income
              opportunity for all of the ecosystem users, CUBIX has built a
              decentralized affiliation program, using which the users can earn
              commission for every Direct and Team Referral that they
              accumulate.
            </p>
            <p>
              This is achieved by making a referral code mandatory for all new
              users who wish to log in , create an account , or buy a NFT pack
              from the CUBIX Platform.
            </p>
            <p>
              Using this process the CUBIX ecosystem converts all of its users
              into business partners, by syncing their success with the success
              of CUBIX.
            </p>
            <p>
              The process of referring a new user is extremely simple, you just
              have to ensure that your referral code is used at the time of
              account creation before they enter their dashboard to purchase any
              NFT pack.
            </p>
            <p>As soon as the user makes a purchase of an NFT pack,</p>
            <p>
              The amount is automatically split and sent partially to the CUBIX
              treasury and partially to the spline of the referral program,
              using a smart contract.
            </p>
            <p>
              The referrer can also check his entire earning through the CUBIX
              Dashboard that displays income data on Daily , Weekly and Monthly
              basis.
            </p>
            <p>
              The referral income generated on CUBIX/USDT can be classified into
              two group Direct and Team Referral income.
            </p>
            <h4>What is Direct income?</h4>
            <p>
              When a new user makes a purchase of Nft pack directly using your
              referral code, it is considered Direct income.
            </p>
            <h4>What is Team income?</h4>
            <p>
              When a user you invited to Cubix invites another new user to the
              ecosystem, you may claim part of the sales commission based upon
              the Level of your wallet address. This income is referred to as
              Team income.
            </p>
            <h4>What is a level?</h4>
            <p>
              Level is simply a grade or rank assigned to your wallet to
              calculate commission due to you by Cubix. The Level of your wallet
              depends upon the calculation of your direct and Team sales.
              Increase in Sales is directly proportional to rise in the level.
            </p>
            <h4>What sales targets have to be achieved to level up?</h4>
          </div>
          <div className="how-the-cubix-program-works-section-tabel mb-33">
            <div className="how-the-cubix-program-works-section-tabel-image target-in-dollar mb-33">
              <img src="/assets/images/table-image1.png" alt="table-image1" />
            </div>
            <div className="how-the-cubix-program-works-section-tabel-image direct-sell">
              <img src="/assets/images/table-image2.png" alt="table-image2" />
            </div>
          </div>
          <div className="how-the-cubix-program-works-section-content mb-33">
            <p>L=Level of indirect seller in your Team</p>
            <h4>How is the Team Income calculated?</h4>
            <p>
              System calculates Team sales income by simply evaluating the rank
              difference between your wallet and your referred users wallet. It
              is explained with the help of different scenarios below.
            </p>
          </div>
          <div className="how-the-cubix-program-works-section-scenario mb-33">
            <div className="how-the-cubix-program-works-section-scenario-content mb-33">
              <h3>Scenario one:</h3>
              <p>
                Referrer Wallet Level - 1<br />
                Referred User Wallet Level - 1
              </p>
              <p>
                In this case the Referrer will make 14% of the Direct Sale and
                0% of the Team Sale, as both the wallets are on the same level
              </p>
            </div>
            <div className="how-the-cubix-program-works-section-scenario-image">
              <img
                src="/assets/images/scenario-image1.png"
                alt="scenario-image1"
              />
            </div>
          </div>
          <div className="how-the-cubix-program-works-section-scenario">
            <div className="how-the-cubix-program-works-section-scenario-content mb-33">
              <h3>Scenario two:</h3>
              <p>
                Referrer Wallet Level - 5<br />
                Referred User Wallet Level - 1
              </p>
              <p>
                In this case the Referrer will make 24% of the Direct Sale and
                10% of the Team sale, as there is a difference between the
                commissions. (24%-14% = 10%)
              </p>
            </div>
            <div className="how-the-cubix-program-works-section-scenario-image">
              <img
                src="/assets/images/scenario-image2.png"
                alt="scenario-image1"
              />
            </div>
          </div>
        </div>
      </section>
    </Content>
  );
};

export default withAuth(UserGuidePage);
