import { useEffect, useState } from 'react';
import { getBoughtPacks, updatePack } from '../../services/packs';
import {
  getRecommendedGasPrice,
  nftDistributionContract,
} from '../../utils/web3/init';
import swal from 'sweetalert';

const initState = {
  data: {
    records: [],
    total: 0,
  },
  error: null,
  loading: true,
};
export const useGetBoughtPacks = () => {
  const [packs, setPacks] = useState({ ...initState });
  const [approving, setApproving] = useState(false);
  const getData = async () => {
    setPacks({
      ...initState,
    });
    try {
      const packResponse = await getBoughtPacks();
      setPacks({
        ...packs,
        data: packResponse,
        loading: false,
      });
    } catch (error) {
      setPacks({
        ...initState,
        loading: false,
        error,
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const approveBuyPack = async (packId, id, _address) => {
    console.log({ packId, id, _address });
    try {
      setApproving(true);
      const fastTxGasFeeForApproval = await getRecommendedGasPrice();
      let _account = await window.web3Instance.eth.getAccounts();

      if (!_account?.[0]) {
        if (window?.ethereum?.isMetaMask) {
          await window.ethereum.send('eth_requestAccounts');
        }
        _account = await window.web3Instance.eth.getAccounts();
      }

      nftDistributionContract.methods
        .buyPack(packId, id, true, _address)
        .send({
          from: _account?.[0],
          gasPrice: fastTxGasFeeForApproval,
        })
        .on('transactionHash', async function (hash) {
          // update this buy pack to init
          swal(
            'Cheers!!',
            'Pack has been approved and NFT will be distribute once confirmed.',
            'success'
          );
          setApproving(false);
          await updatePack({
            ids: [id],
          });
          getData();
        })
        .on('receipt', function (receipt) {
          console.log({ receipt });
        })
        .on('error', function (error) {
          setApproving(false);
          console.log({ error });
        });
    } catch (error) {
      console.log(error);
      setApproving(false);
      getData();
    }
  };

  return {
    packs,
    getData,
    approveBuyPack,
    approving,
  };
};
