import React from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useAddWithdrawalRequest } from '../../hooks/account/useAddWithdrawalRequest';
import swal from 'sweetalert';

export const RequestWithdrawal = ({
  amount,
  modalIsOpen,
  setModalIsOpen,
  setAmount,
  setToAccountAddress,
  toAccountAddress,
  _getWithdrawRequests,
}) => {
  const { loading, _addWithdrawRequests } = useAddWithdrawalRequest();

  return (
    <Modal
      ariaHideApp={false}
      className="react-modal"
      isOpen={modalIsOpen}
      style={{
        overlay: {
          zIndex: 1111111111111,
          backgroundColor: '#000000a6',
        },
        content: {
          position: 'absolute',
          border: '1px solid #383838',
          background: '#030303',
          overflow: 'auto',
          borderRadius: '20px',
          outline: 'none',
          marginRight: '-50%',
          maxHeight: '72vh',
          width: '500px',
          padding: '30px',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          overflowY: 'auto',
          boxShadow: '0 0 25px 2px #dbff0182',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          flexDirection: 'column',
        },
      }}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (amount > 0 && toAccountAddress) {
            if (!loading) {
              await _addWithdrawRequests({
                amount,
                toAddress: toAccountAddress,
              });
              _getWithdrawRequests();
              setModalIsOpen(false);
              setAmount(0);
              setToAccountAddress('');
            }
          } else {
            swal(
              'Error',
              'Please enter valid amount and account address',
              'error'
            );
          }
        }}
      >
        <button
          type="button"
          className="modal-close-btn"
          onClick={() => {
            setModalIsOpen(false);
            setAmount(0);
            setToAccountAddress('');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
          </svg>
        </button>
        <div className="">
          <div className="welcome-back-right-box mb-33">
            <h4 style={{ textAlign: 'center' }}>Add Withdraw Request</h4>
          </div>
          <div className="welcome-back-row">
            <div className="welcome-back-right-box mb-15">
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="text"
                className="input-style"
                placeholder="Enter amount"
                required
              />
            </div>
          </div>
          <div className="welcome-back-row">
            <div className="welcome-back-right-box mb-15">
              <input
                value={toAccountAddress}
                onChange={(e) => setToAccountAddress(e.target.value)}
                type="text"
                className="input-style"
                placeholder="Enter account address"
                required
              />
            </div>
          </div>
          <div className="welcome-back-btns" style={{ textAlign: 'center' }}>
            <button
              type="submit"
              className="btns"
              style={{ cursor: 'pointer' }}
            >
              {loading ? 'Please wait' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
