import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { fetchPartners } from '../../services/user';

export const useGetPartners = () => {
  const userContext = useContext(UserContext);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const getPartners = async (address, page, search) => {
    try {
      const data = await fetchPartners(address, page * 20, search);
      setPartners(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      getPartners(userContext?.accountDetails?.accountAddress, page, search);
    }
  }, [userContext?.accountDetails?.accountAddress, page, search]);

  return {
    partners,
    loading,
    getPartners,
    page,
    setPage,
    search,
    setSearch,
    searchInput,
    setSearchInput,
  };
};
