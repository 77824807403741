import { useState } from 'react';
import swal from 'sweetalert';
import { putUpdateProfile } from '../../services/packs';

export const useUpdateProfile = (address, getAccountDetails) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');

  const updateProfile = async (cb) => {
    try {
      if (!email) {
        swal('Error!', 'Please enter Email.', 'error');
        return;
      }
      if (!userName) {
        swal('Error!', 'Please enter Username.', 'error');
        return;
      }
      setLoading(true);
      await putUpdateProfile(address, {
        email,
        userName,
      });
      swal('Thank you!', 'Your profile has been updated.', 'success');
      getAccountDetails();
    } catch (error) {
      swal('Error!', error, 'error');
    } finally {
      setLoading(false);
      setEmail('');
      setUserName('');
      cb?.();
    }
  };

  return {
    loading,
    updateProfile,
    email,
    setEmail,
    userName,
    setUserName,
  };
};
