import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const RegisterView = ({ callLogin, data, setData, loading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="wrapper">
        <div className="hero-main-section cf">
          <div className="hero-main-section-inner-register cf">
            <div className="hero-main-section-detail cf">
              <div className="midd-container">
                <section className="welcome-back-section cf">
                  <div className="welcome-back-detail">
                    <div className="welcome-back-detail-inner">
                      <div className="welcome-back-detail-inner-left">
                        <div className="welcome-back-detail-inner-left-inner">
                          <div className="welcome-back-detail-inner-left-inner-top">
                            <div className="welcome-back-detail-inner-left-logo-box">
                              <Link to="/" className="welcome-back-logo">
                                <img
                                  src="/assets/images/Logo.svg"
                                  alt="logo"
                                  title="Cubix"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-left-inner-bottom">
                            <div className="welcome-back-detail-inner-left-inner-bottom-inner">
                              <div className="welcome-back-detail-inner-left-inner-bottom-detail">
                                <div className="welcome-back-detail-left-top-title">
                                  <h2>Welcome Back</h2>
                                  <p>Login into your account</p>
                                </div>
                              </div>

                              <div className="wallet-address-section">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    sessionStorage.clear();
                                    callLogin();
                                  }}
                                >
                                  <div className="wallet-address-detail">
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.email}
                                        type="text"
                                        className="input-style"
                                        placeholder="Enter your email address"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            email: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="wallet-address-detail">
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.password}
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        className="input-style"
                                        placeholder="Enter your password"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            password: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="wallet-address-box">
                                    <label>
                                      <input
                                        style={{ margin: 12 }}
                                        checked={showPassword}
                                        type="checkbox"
                                        onChange={(e) =>
                                          setShowPassword(!showPassword)
                                        }
                                      />
                                      Show Password
                                    </label>
                                  </div>
                                  <div className="wallet-address-btn">
                                    <button type="submit">
                                      {loading ? 'Please wait...' : 'Login Now'}
                                    </button>
                                  </div>
                                  <div
                                    className="wallet-address-btn"
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Link
                                      to="/forgot-password"
                                      style={{
                                        color: '#DBFF01',
                                        textDecoration: 'underline',
                                      }}
                                    >
                                      Forgot Password?
                                    </Link>
                                  </div>
                                  <div
                                    onClick={() => navigate('/register')}
                                    className="wallet-address-btn"
                                    style={{
                                      textAlign: 'center',
                                      border: '1px solid rgb(219, 255, 1)',
                                      borderRadius: '15px',
                                      padding: '18px',
                                      cursor: 'pointer',
                                      marginTop: 80,
                                    }}
                                    role="button"
                                  >
                                    <Link
                                      to="/register"
                                      style={{
                                        color: '#DBFF01',
                                      }}
                                    >
                                      Register
                                    </Link>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="welcome-back-detail-inner-right">
                        <div className="welcome-back-detail-inner-right-inner">
                          <div className="welcome-back-detail-inner-right-top">
                            <div className="welcome-back-detail-inner-right-top-detail">
                              <h2>Welcome</h2>
                              <p>
                                Cubix, the <span>Blockchain</span>-based{' '}
                                <span>Fantasy Sports</span> game.
                                <span>Your</span> game, <span>play</span> it
                                your way.
                              </p>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-center">
                            <div className="welcome-back-detail-inner-right-center-detail">
                              <img
                                src="/assets/images/welcome-right-img.png"
                                alt="logo"
                                title="welcome-right-img"
                              />
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-bottom"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
