import { useEffect, useState } from 'react';

export const useGetMasters = () => {
  const [masters, setMasters] = useState({});
  const [loading, setLoading] = useState(true);

  const getMaster = async () => {
    try {
      setMasters({
        ...masters,
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaster();
  }, []);

  return { masters, loading, setMasters };
};
