import React from 'react';
import { useBackdoorEntryRequest } from '../../../hooks/account/useBackdoorEntryRequest';

export const BackdoorForm = () => {
  const { data, loading, setData, backdoorEntryData } =
    useBackdoorEntryRequest();

  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Backdoor entry</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div style={{ padding: 20 }}>
            <div className="wallet-address-detail">
              <div className="wallet-address-box">
                <input
                  type="text"
                  className="input-style"
                  placeholder="account address"
                  onChange={(e) =>
                    setData({
                      ...data,
                      accountAddress: e.target.value,
                    })
                  }
                  value={data.accountAddress ?? ''}
                />
              </div>
              <div className="wallet-address-box">
                <input
                  type="email"
                  className="input-style"
                  placeholder="Email"
                  onChange={(e) =>
                    setData({
                      ...data,
                      email: e.target.value,
                    })
                  }
                  value={data.email ?? ''}
                />
              </div>
              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="USDT amount"
                  onChange={(e) =>
                    setData({
                      ...data,
                      usdtBalance: e.target.value,
                    })
                  }
                  value={data.usdtBalance ?? ''}
                />
              </div>
              <div className="wallet-address-btn">
                <button onClick={() => backdoorEntryData()}>
                  {loading ? 'Adding...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
