import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';

import { Content } from '../../components/layout/content';
import { useGetPartnersTree } from '../../hooks/account/useGetPartnersTree';
import Modal from 'react-modal/lib/components/Modal';

const textLayout = {
  vertical: {
    title: {
      textAnchor: 'start',
      x: 40,
    },
    attributes: {},
    attribute: {
      x: 40,
      dy: '1.2em',
    },
  },
  horizontal: {
    title: {
      textAnchor: 'start',
      y: 40,
    },
    attributes: {
      x: 0,
      y: 40,
    },
    attribute: {
      x: 0,
      dy: '1.2em',
    },
  },
};

const PureSvgNodeElement = ({
  nodeDatum,
  orientation,
  toggleNode,
  onNodeClick,
  addChildren,
  _getPartnersTreeV2,
  loadedTree,
  loadingObj,
  _openDetailsModal,
  _type,
}) => {
  return (
    <>
      <circle
        id={nodeDatum.name}
        r={20}
        onClick={async (e) => {
          if (_type === 'children') {
            _openDetailsModal(nodeDatum?.name);
            return;
          }
          if (loadedTree?.[nodeDatum?.name]) {
            _openDetailsModal(nodeDatum?.name);
            return;
          }
          if (nodeDatum?.__rd3t?.depth === 1) {
            const children = await _getPartnersTreeV2(
              nodeDatum?.accountAddress,
              nodeDatum?.name
            );

            toggleNode(e);
            onNodeClick(e);
            addChildren(children ?? []);
            return;
          }
          _openDetailsModal(nodeDatum?.name);
          if (nodeDatum?.referredById) {
            return;
          }
        }}
      ></circle>
      <g
        className={`rd3t-label id-${nodeDatum.name} account-address-${nodeDatum?.accountAddress}`}
      >
        <text className="rd3t-label__title" {...textLayout[orientation].title}>
          {nodeDatum.name}
        </text>
        {/* <text className="rd3t-label__attributes" x="40" y="20">
          <tspan key={'team'}>
            &#x2022;
            {String(nodeDatum?.referredById) === '0'
              ? ''
              : 'Sponsor: ' + nodeDatum?.referredById}
          </tspan>
        </text>
        <text className="rd3t-label__attributes" x="40" y="40">
          <tspan key={'team'}>
            &#x2022;{' '}
            {String(nodeDatum?.parentId) === '0'
              ? ''
              : 'Placement: ' + nodeDatum?.parentId}
          </tspan>
        </text> */}
        {/* <text className="rd3t-label__attributes" x="40" y="60">
          <tspan key={'team'}>Team A: {nodeDatum.teamACount}</tspan>
        </text>
        <text className="rd3t-label__attributes" x="40" y="80">
          <tspan key={'team'}>Team B: {nodeDatum.teamBCount}</tspan>
        </text> */}
      </g>
    </>
  );
};

const AppTreeView = () => {
  const {
    loading,
    partners,
    _getPartnersTree,
    _getPartnersTreeV2,
    loadingObj,
    type,
    setType,
    setSearch,
    searchInput,
    setSearchInput,
    search,
    loadedTree,
    _openDetailsModal,
    detailModal,
    setDetailsModal,
    currentDetails,
  } = useGetPartnersTree();

  const customNodeFnMapping = {
    svg: {
      description: 'Default - Pure SVG node & label (IE11 compatible)',
    },
  };

  const [translate, setTranslate] = useState({
    translateX: 700,
    translateY: 100,
  });

  const [dimensions, setDimensions] = useState();

  const state = {
    data: partners,
    orientation: 'vertical',
    dimensions: undefined,
    centeringTransitionDuration: 800,
    collapsible: true,
    shouldCollapseNeighborNodes: false,
    depthFactor: undefined,
    zoomable: true,
    draggable: true,
    zoom: 1,
    scaleExtent: { min: 0.1, max: 1 },
    separation: { siblings: 2, nonSiblings: 2 },
    nodeSize: { x: 200, y: 200 },
    enableLegacyTransitions: false,
    transitionDuration: 500,
    renderCustomNodeElement: customNodeFnMapping['svg'].fn,
    styles: {
      nodes: {
        node: {
          circle: {
            fill: '#52e2c5',
          },
          attributes: {
            stroke: '#000',
          },
        },
        leafNode: {
          circle: {
            fill: 'transparent',
          },
          attributes: {
            stroke: '#000',
          },
        },
      },
    },
  };

  const treeContainerRef = React.createRef();

  useEffect(() => {
    if (treeContainerRef.current) {
      const dimensions = treeContainerRef.current.getBoundingClientRect();
      setTranslate({
        translateX: dimensions.width / 2,
        translateY: dimensions.height / 4,
      });
    }
  }, [treeContainerRef.current]);

  const centerView = () => {
    if (treeContainerRef.current) {
      const { width, height } =
        treeContainerRef.current.getBoundingClientRect();
      setDimensions({
        width,
        height,
      });
    }
  };

  const detailsOfUser = detailModal?.[currentDetails];

  return (
    <div
      style={{
        minHeight: '80vh',
        borderRadius: 12,
        width: '100%',
        marginTop: 12,
      }}
    >
      <Modal
        ariaHideApp={false}
        className="react-modal"
        isOpen={detailsOfUser?.id}
        style={{
          overlay: {
            zIndex: 1111111111111,
            backgroundColor: '#000000a6',
          },
          content: {
            position: 'absolute',
            border: '1px solid #383838',
            background: '#030303',
            overflow: 'auto',
            borderRadius: '20px',
            outline: 'none',
            maxHeight: '80vh',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            overflowY: 'auto',
            boxShadow: '0 0 25px 2px #dbff0182',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            flexDirection: 'column',
          },
        }}
      >
        <button
          type="button"
          className="modal-close-btn"
          onClick={() => {
            setDetailsModal({});
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
          </svg>
        </button>
        <div className="">
          <div className="welcome-back-right-box mb-33">
            <h4 style={{ textAlign: 'center', marginBottom: 20 }}>
              Details of {detailsOfUser?.mainUser?.username}
            </h4>
            <p style={{ fontSize: 16 }}>Email: {detailsOfUser?.email}</p>
            <p style={{ fontSize: 16 }}>
              Team: {detailsOfUser?.node === 0 ? 'A' : 'B'}
            </p>
            <p style={{ fontSize: 16 }}>
              Account address: {detailsOfUser?.accountAddress}
            </p>
            <p style={{ fontSize: 16 }}>
              Total Business: {detailsOfUser?.businessIncome}
            </p>
            <p style={{ fontSize: 16 }}>
              My Investment: {detailsOfUser?.individualIncome}
            </p>
            {/* <p style={{ fontSize: 16 }}>Direct matching: </p>
            <p style={{ fontSize: 16 }}>Team matching: </p> */}
            <p style={{ fontSize: 16 }}>
              A Team Business: {detailsOfUser?.businessIncomeFirstTeam}
            </p>
            <p style={{ fontSize: 16 }}>
              B Team Business: {detailsOfUser?.businessIncomeSecondTeam}
            </p>
          </div>
        </div>
      </Modal>
      <div className="wallet-address-box" style={{ marginTop: 24 }}>
        <label style={{ marginRight: 24 }}>
          <input
            checked={type === 'myReferrals'}
            value="myReferrals"
            type="radio"
            onChange={() => {
              setType('myReferrals');
            }}
          />
          Sponsor Tree
        </label>
        <label>
          <input
            checked={type === 'children'}
            value="children"
            type="radio"
            onChange={() => {
              setType('children');
            }}
          />
          Placement Tree
        </label>
      </div>
      <div
        className="wallet-address-box"
        style={{ marginTop: 24, display: 'flex' }}
      >
        <div className="wallet-address-detail">
          <div className="wallet-address-box">
            <input
              value={searchInput}
              className="input-style"
              placeholder="Search with user id"
              onChange={(e) => setSearchInput(e.target.value)}
              onSubmit={() => {
                setSearch(searchInput);
              }}
            />
          </div>
        </div>
        <div className="wallet-address-btn" style={{ margin: 10 }}>
          <button
            onClick={() => {
              setSearch(searchInput);
            }}
          >
            Search
          </button>
        </div>
        {search ? (
          <div
            className="wallet-address-btn"
            style={{
              margin: 10,
            }}
          >
            <button
              style={{
                backgroundColor: 'transparent',
                transition: '0.5s',
                color: '#ffffff',
              }}
              onClick={() => {
                setSearchInput('');
                setSearch('');
              }}
            >
              Reset
            </button>
          </div>
        ) : null}
      </div>
      <p style={{ fontSize: 14 }}>
        <i>Click on ID circle to get details of user</i>
      </p>
      {loading ? (
        <p>Please wait...</p>
      ) : (
        <div className="tree-container" ref={treeContainerRef}>
          <Tree
            hasInteractiveNodes
            data={state.data}
            renderCustomNodeElement={(rd3tProps) => {
              return (
                <PureSvgNodeElement
                  nodeDatum={rd3tProps.nodeDatum}
                  toggleNode={rd3tProps.toggleNode}
                  orientation={state.orientation}
                  onNodeClick={rd3tProps.onNodeClick}
                  addChildren={rd3tProps.addChildren}
                  _getPartnersTree={_getPartnersTree}
                  _getPartnersTreeV2={_getPartnersTreeV2}
                  _openDetailsModal={_openDetailsModal}
                  loadedTree={loadedTree}
                  loadingObj={loadingObj}
                  _type={type}
                />
              );
            }}
            rootNodeClassName="demo-node"
            branchNodeClassName="demo-node"
            orientation={state.orientation}
            dimensions={dimensions}
            centeringTransitionDuration={state.centeringTransitionDuration}
            translate={{ x: translate.translateX, y: translate.translateY }}
            pathFunc={'step'}
            collapsible={state.collapsible}
            zoomable={state.zoomable}
            draggable={state.draggable}
            zoom={state.zoom}
            scaleExtent={state.scaleExtent}
            nodeSize={state.nodeSize}
            separation={state.separation}
            enableLegacyTransitions={state.enableLegacyTransitions}
            transitionDuration={state.transitionDuration}
            depthFactor={state.depthFactor}
            styles={state.styles}
            shouldCollapseNeighborNodes={state.shouldCollapseNeighborNodes}
            onNodeClick={() => {
              centerView();
            }}
          />
        </div>
      )}
      <style jsx global>
        {`
          .partner-tree-view circle {
            fill: #dfff01 !important;
          }
          .partner-tree-view path.link {
            stroke: #dfff01 !important;
          }
          .partner-tree-view text {
            fill: white !important;
          }

          .demo-container {
            position: fixed;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
          }

          .column-left {
            width: 30%;
            display: flex;
            flex-direction: column;
            background: #f7fafc;
            border-right: 1px solid #2d3748;
          }

          .controls-container {
            display: flex;
            flex-direction: column;
            flex-basis: 1;
            overflow-y: scroll;
          }

          .state-container {
            display: none;
            flex-direction: column;
            flex-basis: 30%;
            border-top: 1px solid black;
          }
          .state-container > h4 {
            color: black;
          }

          .state {
            width: 100%;
            height: 100%;
          }

          .column-right {
            height: 100%;
            width: 100%;
          }

          .tree-container {
            height: 100%;
            background: #ccf6c8;
            height: 100vh;
            width: '100%';
            margin-top: 24px;
          }

          .prop-container {
            padding: 2px 5px;
            margin: 15px 10px;
          }
          .prop {
            font-weight: bold;
            display: block;
          }

          .prop-large {
            font-size: 16px;
          }

          .sub-prop {
            padding: 5px;
          }

          .btn-controls {
            color: #f7fafc;
            background-color: #2d3748;
            transition: color 0.15s ease, border-color 0.15s ease;
          }

          .btn-controls:hover,
          .btn-controls:focus,
          .btn-controls:active {
            color: white;
            border-color: #f7fafc;
          }

          .tree-stats-container {
            text-align: center;
            padding: 0.5rem 2rem;
            border-bottom: 1px solid #2d3748;
            font-weight: bold;
          }

          /* Custom node classes */
          .demo-node > circle {
            fill: #2d3748;
            stroke: #2d3748;
          }

          /* Custom path classes */
          .my-path-class {
            stroke: royalblue;
            stroke-width: 10;
          }
        `}
      </style>
    </div>
  );
};

export const PartnersTreeView = () => {
  return (
    <Content>
      <AppTreeView />
    </Content>
  );
};
