import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const RegisterView = ({
  data,
  setData,
  callRegister,
  loading,
  emailData,
  setEmailData,
  callSetEmail,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrapper">
        <div className="hero-main-section cf">
          <div className="hero-main-section-inner-register cf">
            <div className="hero-main-section-detail cf">
              <div className="midd-container">
                <section className="welcome-back-section cf">
                  <div className="welcome-back-detail">
                    <div className="welcome-back-detail-inner">
                      <div className="welcome-back-detail-inner-left">
                        <div className="welcome-back-detail-inner-left-inner">
                          <div className="welcome-back-detail-inner-left-inner-top">
                            <div className="welcome-back-detail-inner-left-logo-box">
                              <Link to="/" className="welcome-back-logo">
                                <img
                                  src="/assets/images/Logo.svg"
                                  alt="logo"
                                  title="Cubix"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-left-inner-bottom">
                            <div className="welcome-back-detail-inner-left-inner-bottom-inner">
                              <div className="welcome-back-detail-inner-left-inner-bottom-detail">
                                <div className="welcome-back-detail-left-top-title">
                                  <h2>Automatic Registration</h2>
                                  <p>Check the ID of your invitee.</p>
                                </div>
                              </div>
                              <div className="wallet-address-section">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    sessionStorage.clear();
                                    callRegister();
                                  }}
                                >
                                  <div className="wallet-address-detail">
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.email}
                                        type="text"
                                        className="input-style"
                                        placeholder="Enter your email address"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            email: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="wallet-address-box">
                                      <input
                                        readOnly={data?.code}
                                        value={data?.referredBy}
                                        type="text"
                                        className="input-style"
                                        placeholder="Enter your referral wallet address"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            referredBy: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="wallet-address-box">
                                      <input
                                        value={data?.parent}
                                        type="text"
                                        className="input-style"
                                        placeholder="Enter your placement wallet address"
                                        onChange={(e) =>
                                          setData({
                                            ...data,
                                            parent: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div
                                      className="wallet-address-box"
                                      style={{ marginTop: 24 }}
                                    >
                                      <label style={{ marginRight: 24 }}>
                                        <input
                                          readOnly={data?.code}
                                          checked={data?.team === 'teamA'}
                                          value="teamA"
                                          type="radio"
                                          onChange={(e) => {
                                            if (data?.code) {
                                              return;
                                            }

                                            setData({
                                              ...data,
                                              team: e.target.value,
                                            });
                                          }}
                                        />
                                        Team A
                                      </label>
                                      <label>
                                        <input
                                          readOnly={data?.code}
                                          checked={data?.team === 'teamB'}
                                          value="teamB"
                                          type="radio"
                                          onChange={(e) => {
                                            if (data?.code) {
                                              return;
                                            }
                                            setData({
                                              ...data,
                                              team: e.target.value,
                                            });
                                          }}
                                        />
                                        Team B
                                      </label>
                                    </div>
                                    <div className="wallet-address-btn">
                                      <button type="submit">
                                        {loading
                                          ? 'Please wait...'
                                          : 'Register Now'}
                                      </button>
                                    </div>

                                    <div
                                      onClick={() => navigate('/login')}
                                      className="wallet-address-btn"
                                      style={{
                                        textAlign: 'center',
                                        border: '1px solid rgb(219, 255, 1)',
                                        borderRadius: '15px',
                                        padding: '18px',
                                        cursor: 'pointer',
                                        marginTop: 60,
                                      }}
                                      role="button"
                                    >
                                      <Link
                                        to="/login"
                                        style={{
                                          color: '#DBFF01',
                                        }}
                                      >
                                        Login
                                      </Link>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="welcome-back-detail-inner-right">
                        <div className="welcome-back-detail-inner-right-inner">
                          <div className="welcome-back-detail-inner-right-top">
                            <div className="welcome-back-detail-inner-right-top-detail">
                              <h2>Welcome</h2>
                              <p>
                                Cubix, the <span>Blockchain</span>-based{' '}
                                <span>Fantasy Sports</span> game.
                                <span>Your</span> game, <span>play</span> it
                                your way.
                              </p>
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-center">
                            <div className="welcome-back-detail-inner-right-center-detail">
                              <img
                                src="/assets/images/welcome-right-img.png"
                                alt="logo"
                                title="welcome-right-img"
                              />
                            </div>
                          </div>
                          <div className="welcome-back-detail-inner-right-bottom">
                            <div className="welcome-back-detail-inner-left-inner-bottom-detail">
                              <div className="welcome-back-detail-left-top-title">
                                <p>
                                  Please set your email here if not set already.
                                </p>
                              </div>
                            </div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                callSetEmail();
                              }}
                            >
                              <div className="wallet-address-detail">
                                <div className="wallet-address-box">
                                  <input
                                    value={emailData?.email}
                                    type="text"
                                    className="input-style"
                                    placeholder="Enter your email address"
                                    onChange={(e) =>
                                      setEmailData({
                                        ...emailData,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              <div className="wallet-address-btn">
                                <button type="submit">
                                  {loading ? 'Please wait...' : 'Set Email'}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
