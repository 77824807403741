import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { fetchMyIncome } from '../../services/user';

export const useGetMyIncome = (bonusType) => {
  const userContext = useContext(UserContext);
  const [income, setIncome] = useState({});
  const [loading, setLoading] = useState(true);

  const getMyIncome = async (address) => {
    try {
      const data = await fetchMyIncome(address, bonusType);
      setIncome(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      getMyIncome(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { income, loading, getMyIncome };
};
