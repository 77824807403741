import { Content } from '../../components/layout/content';
import { WithdrawalsListAll } from '../../components/withdrawals/list/all';

export const WithdrawalListView = () => {
  return (
    <Content>
      <WithdrawalsListAll />
    </Content>
  );
};
