import { useState } from 'react';
import { changeUserIdBasedOnId } from '../../services/user';

export const useChangeUserIdBasedOnId = () => {
  const [loading, setLoading] = useState(false);

  const changeAddress = async (accountAddress) => {
    try {
      setLoading(true);
      const data = await changeUserIdBasedOnId(accountAddress);
      sessionStorage.setItem('accountAddress', data?.accountAddress);
      sessionStorage.setItem('x-api-token', data?.accessToken);
      sessionStorage.setItem('user', JSON.stringify(data));
      window.location.replace('/dash');
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return { changeAddress, loading };
};
