import React from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useUpdateProfile } from '../../../../hooks/account/useUpdateProfile';

const EditProfileModal = ({
  modalIsOpen,
  setModalIsOpen,
  accountAddress,
  getAccountDetails,
  userName: existingUserName,
  email: existingEmail,
}) => {
  const { email, userName, setEmail, setUserName, loading, updateProfile } =
    useUpdateProfile(accountAddress, getAccountDetails);

  useEffect(() => {
    setEmail(existingEmail);
    setUserName(existingUserName);
  }, [existingUserName, existingEmail]);

  return (
    <Modal
      ariaHideApp={false}
      className="react-modal"
      isOpen={modalIsOpen}
      style={{
        overlay: {
          zIndex: 1111111111111,
          backgroundColor: '#000000a6',
        },
        content: {
          position: 'absolute',
          border: '1px solid #383838',
          background: '#030303',
          overflow: 'auto',
          borderRadius: '20px',
          outline: 'none',
          marginRight: '-50%',
          maxHeight: '72vh',
          minHeight: '80vh',
          width: '500px',
          padding: '30px',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          overflowY: 'auto',
          boxShadow: '0 0 25px 2px #dbff0182',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          flexDirection: 'column',
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateProfile(setModalIsOpen);
        }}
      >
        {existingEmail ? (
          <button
            type="button"
            className="modal-close-btn"
            onClick={() => setModalIsOpen(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </button>
        ) : null}
        <div className="">
          <div className="welcome-back-right-box mb-33">
            <h4 style={{ textAlign: 'center' }}>Profile Details</h4>
          </div>
          <div className="welcome-back-row">
            <div className="welcome-back-right-box mb-15">
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                className="input-style"
                placeholder="Enter Username"
                required
                readOnly={existingEmail && existingEmail !== ''}
              />
            </div>
            <div className="welcome-back-right-box mb-15">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="input-style"
                placeholder="Enter Email"
                required
                readOnly={existingEmail && existingEmail !== ''}
              />
            </div>
          </div>
          {!existingEmail ? (
            <div className="welcome-back-btns" style={{ textAlign: 'center' }}>
              <button
                type="submit"
                className="btns"
                style={{ cursor: 'pointer' }}
              >
                {loading ? 'Please wait...' : 'Update'}
              </button>
            </div>
          ) : null}
        </div>
      </form>
    </Modal>
  );
};

export default EditProfileModal;
