import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { fetchActivities } from '../../services/user';

export const useGetMyActivities = () => {
  const userContext = useContext(UserContext);
  const [activities, setActivities] = useState({});
  const [loading, setLoading] = useState(true);

  const getMyActivities = async (address) => {
    try {
      const data = await fetchActivities(address);
      setActivities(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      getMyActivities(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { activities, loading, getMyActivities };
};
