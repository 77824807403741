import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { getWithdrawRequests } from '../../services/user';

export const useGetMyWithdrawalRequest = (bonusType) => {
  const userContext = useContext(UserContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const _getWithdrawRequests = async (address) => {
    try {
      setLoading(true);
      const data = await getWithdrawRequests(address, bonusType);
      setData(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContext?.accountDetails?.accountAddress) {
      _getWithdrawRequests(userContext?.accountDetails?.accountAddress);
    }
  }, [userContext?.accountDetails?.accountAddress]);

  return { data, loading, _getWithdrawRequests };
};
