import React, { useContext, useState } from 'react';
// import ProgressBar from '@ramonak/react-progress-bar';

import { UserContext } from '../../../../context/UserContext';
import EditProfileModal from '../EditProfile/EditProfileModal';
import ChangeAccountIdModal from '../ChangeAccountIdModal/ChangeAccountIdModal';

export const ProfileCard = () => {
  const userContext = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForAccountIds, setModalIsOpenForAccountIds] =
    useState(false);

  // const userLevel = userContext?.accountDetails?.profile;
  // const nextLevel = userContext?.accountDetails?.nextProfile;

  // const totalBusiness = userContext?.accountDetails?.businessIncome;
  // const percentage = (totalBusiness * 100) / nextLevel?.minimumBusinessRequired;

  return (
    <div
      className="section-one-top-box"
      style={{ position: 'relative', minHeight: 170, minWidth: '49%' }}
    >
      <EditProfileModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        accountAddress={userContext?.accountDetails?.accountAddress}
        getAccountDetails={userContext?.getAccountDetails}
        userName={userContext?.accountDetails?.userName}
        email={userContext?.accountDetails?.email}
      />

      <ChangeAccountIdModal
        modalIsOpen={modalIsOpenForAccountIds}
        setModalIsOpen={setModalIsOpenForAccountIds}
        accountAddress={userContext?.accountDetails?.accountAddress}
      />

      <div className="section-one-left section-one-bg">
        <div className="section-one-left-top">
          <div className="section-one-left-top-detail">
            <div
              className="section-one-left-top-inner"
              style={{
                width: '100%',
              }}
            >
              <div className="section-one-left-top-inner-img">
                <img src="/assets/images/profile-img.png" alt="profile-pic" />
              </div>
              <div
                className="section-one-left-top-inner-id"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <h3>ID {userContext?.accountDetails?.referralCode}</h3>
                <div>
                  <span
                    className="edit-profile-btn-second"
                    onClick={() => setModalIsOpenForAccountIds(true)}
                  >
                    Swap ID
                  </span>
                  &nbsp; &nbsp;
                  <span
                    className="edit-profile-btn"
                    onClick={() => setModalIsOpen(true)}
                  >
                    View Profile
                  </span>
                </div>
              </div>
            </div>
            <div className="section-one-left-top-inner-icon">
              {/* <img
                src={`/assets/images/${userLevel?._id}-icon.png`}
                alt={`level-pic`}
              /> */}
            </div>
          </div>
        </div>
        <div className="section-one-left-middle">
          <div className="section-one-left-middle-top">
            <div className="section-one-left-middle-top-inner">
              {/* <div className="section-one-left-middle-top-level">
                Rank {userLevel?._id}
              </div>
              <div className="section-one-left-middle-top-target">
                Rank business {nextLevel?.minimumBusinessRequired}
              </div> */}
            </div>
          </div>
          <div className="section-one-left-middle-bottom">
            {/* <ProgressBar
              completed={`${percentage?.toFixed(2)}`}
              bgColor="rgb(81, 225, 107)"
            /> */}
          </div>
        </div>
        <div className="section-one-left-bottom">
          <div className="section-one-left-middle-top-inner">
            {/* <div className="section-one-left-middle-top-level">Next Rank</div>
            <div className="section-one-left-middle-top-target">
              {totalBusiness} / {nextLevel?.minimumBusinessRequired}
            </div> */}
          </div>
        </div>
      </div>
      <style jsx global>{`
        .edit-profile-btn {
          padding: 8px !important;
          background: #dfff01;
          color: black;
          border-radius: 15px;
          cursor: pointer;
        }

        .edit-profile-btn-second {
          padding: 8px !important;
          background: #dfff01;
          color: black;
          border-radius: 15px;
          cursor: pointer;
          margin-right: 12px;
        }
      `}</style>
    </div>
  );
};
