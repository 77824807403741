import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const SideBar = () => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className="hero-main-section-left cf">
      <header className="header">
        <div className="menu-box">
          <div className="menu-box-main">
            <div className="menu-box-main-top">
              <div className="menu-left">
                <Link to="/" target="" title="Cubix">
                  <img src="/assets/images/Logo.svg" alt="logo" />
                </Link>
              </div>
              <div className="menu-right">
                <nav>
                  <div className="enumenu_container">
                    <div
                      id="menu-icon"
                      class="menu-icon"
                      onClick={() => setOpen(!open)}
                    >
                      {' '}
                      <div className="menu-box">
                        <span></span>
                        <span></span>
                        <span></span>{' '}
                      </div>
                      Menu
                    </div>
                    <ul
                      className="enumenu_ul mob"
                      style={{ display: open || !isMobile ? 'block' : 'none' }}
                    >
                      <li
                        className={
                          window.location.pathname === '/dash' && 'active'
                        }
                      >
                        <Link
                          to="/dash"
                          title="Dashboard"
                          className="smoothScroll "
                        >
                          <span className="icon">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 8V0H8V8H0ZM0 18V10H8V18H0ZM10 8V0H18V8H10ZM10 18V10H18V18H10ZM2 6H6V2H2V6ZM12 6H16V2H12V6ZM12 16H16V12H12V16ZM2 16H6V12H2V16Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>Dashboard </span>
                        </Link>
                      </li>
                      {/* <li
                        className={
                          window.location.pathname === '/wallet' && 'active'
                        }
                      >
                        <Link
                          to="/wallet"
                          title="Packs"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="16"
                              viewBox="0 0 80 60"
                              fill="none"
                              version="1.1"
                              id="Capa_1"
                            >
                              <g>
                                <path d="M72.325,33.234v-2.947c0-5.389-3.698-9.919-8.686-11.217l-0.009-4.859c0-4.742-3.859-8.601-8.603-8.601h-0.455L14.31,18.1   c-0.917,0.053-1.787,0.265-2.604,0.584h-0.105C5.205,18.684,0,23.889,0,30.287v28.804c0,6.397,5.204,11.603,11.601,11.603h49.123   c6.396,0,11.601-5.205,11.601-11.603V55.26c2.323-0.899,3.979-3.151,3.979-5.789v-10.45C76.303,36.385,74.648,34.133,72.325,33.234   z M70.303,49.47c0,0.118-0.093,0.211-0.211,0.211H53.851c-0.118,0-0.21-0.093-0.21-0.211V39.021c0-0.115,0.094-0.209,0.21-0.209   h16.241c0.116,0,0.211,0.094,0.211,0.209V49.47z M55.398,11.637c1.261,0.18,2.232,1.266,2.232,2.579l0.008,4.469H32.679   L55.398,11.637z M60.724,64.693H11.602c-3.093,0-5.601-2.509-5.601-5.603V30.287c0-3.095,2.508-5.603,5.601-5.603h49.122   c3.094,0,5.601,2.508,5.601,5.603v2.525H53.851c-3.424,0-6.21,2.785-6.21,6.209V49.47c0,3.425,2.786,6.211,6.21,6.211h12.474v3.41   C66.325,62.184,63.818,64.693,60.724,64.693z" />
                              </g>
                            </svg>
                          </span>
                          <span>Wallet</span>
                        </Link>
                      </li> */}
                      <li
                        className={
                          window.location.pathname === '/my-nfts' && 'active'
                        }
                      >
                        <Link
                          to="/my-nfts"
                          title="Packs"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              width="22"
                              height="16"
                              viewBox="0 0 22 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V16H0ZM15.65 9.15C16.5 9.25 17.3 9.42067 18.05 9.662C18.8 9.904 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.254 21.525 11.662C21.8417 12.0707 22 12.5167 22 13V16H18V13C18 12.2667 17.796 11.5623 17.388 10.887C16.9793 10.2123 16.4 9.63333 15.65 9.15ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97933 13.3 7.938C13.0167 7.896 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3957 6.675 13.637 6.025C13.879 5.375 14 4.7 14 4C14 3.3 13.879 2.625 13.637 1.975C13.3957 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116666 13.0667 0.0623335 13.3 0.0370002C13.5333 0.0123335 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9543 12.85 13.863 12.7C13.771 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5623 10.775 11.337C9.85833 11.1123 8.93333 11 8 11C7.06667 11 6.14167 11.1123 5.225 11.337C4.30833 11.5623 3.4 11.9 2.5 12.35C2.35 12.4333 2.22933 12.55 2.138 12.7C2.046 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.021 5.804 9.413 5.412C9.80433 5.02067 10 4.55 10 4C10 3.45 9.80433 2.97933 9.413 2.588C9.021 2.196 8.55 2 8 2C7.45 2 6.97933 2.196 6.588 2.588C6.196 2.97933 6 3.45 6 4C6 4.55 6.196 5.02067 6.588 5.412C6.97933 5.804 7.45 6 8 6Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>My NFTs</span>
                        </Link>
                      </li>

                      <li
                        className={
                          window.location.pathname === '/partners' && 'active'
                        }
                      >
                        <Link
                          to="/partners"
                          title="Partners"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              width="22"
                              height="16"
                              viewBox="0 0 22 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V16H0ZM15.65 9.15C16.5 9.25 17.3 9.42067 18.05 9.662C18.8 9.904 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.254 21.525 11.662C21.8417 12.0707 22 12.5167 22 13V16H18V13C18 12.2667 17.796 11.5623 17.388 10.887C16.9793 10.2123 16.4 9.63333 15.65 9.15ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97933 13.3 7.938C13.0167 7.896 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3957 6.675 13.637 6.025C13.879 5.375 14 4.7 14 4C14 3.3 13.879 2.625 13.637 1.975C13.3957 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116666 13.0667 0.0623335 13.3 0.0370002C13.5333 0.0123335 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9543 12.85 13.863 12.7C13.771 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5623 10.775 11.337C9.85833 11.1123 8.93333 11 8 11C7.06667 11 6.14167 11.1123 5.225 11.337C4.30833 11.5623 3.4 11.9 2.5 12.35C2.35 12.4333 2.22933 12.55 2.138 12.7C2.046 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.021 5.804 9.413 5.412C9.80433 5.02067 10 4.55 10 4C10 3.45 9.80433 2.97933 9.413 2.588C9.021 2.196 8.55 2 8 2C7.45 2 6.97933 2.196 6.588 2.588C6.196 2.97933 6 3.45 6 4C6 4.55 6.196 5.02067 6.588 5.412C6.97933 5.804 7.45 6 8 6Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>Partners</span>
                        </Link>
                      </li>
                      <li
                        className={
                          window.location.pathname === '/partners-tree-view' &&
                          'active'
                        }
                      >
                        <Link
                          to="/partners-tree-view"
                          title="Partners"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              width="22"
                              height="16"
                              viewBox="0 0 22 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V16H0ZM15.65 9.15C16.5 9.25 17.3 9.42067 18.05 9.662C18.8 9.904 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.254 21.525 11.662C21.8417 12.0707 22 12.5167 22 13V16H18V13C18 12.2667 17.796 11.5623 17.388 10.887C16.9793 10.2123 16.4 9.63333 15.65 9.15ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97933 13.3 7.938C13.0167 7.896 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3957 6.675 13.637 6.025C13.879 5.375 14 4.7 14 4C14 3.3 13.879 2.625 13.637 1.975C13.3957 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116666 13.0667 0.0623335 13.3 0.0370002C13.5333 0.0123335 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9543 12.85 13.863 12.7C13.771 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5623 10.775 11.337C9.85833 11.1123 8.93333 11 8 11C7.06667 11 6.14167 11.1123 5.225 11.337C4.30833 11.5623 3.4 11.9 2.5 12.35C2.35 12.4333 2.22933 12.55 2.138 12.7C2.046 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.021 5.804 9.413 5.412C9.80433 5.02067 10 4.55 10 4C10 3.45 9.80433 2.97933 9.413 2.588C9.021 2.196 8.55 2 8 2C7.45 2 6.97933 2.196 6.588 2.588C6.196 2.97933 6 3.45 6 4C6 4.55 6.196 5.02067 6.588 5.412C6.97933 5.804 7.45 6 8 6Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>Tree view</span>
                        </Link>
                      </li>

                      <li
                        className={
                          window.location.pathname === '/withdrawals' &&
                          'active'
                        }
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <Link
                          to="/withdrawals"
                          title="Information"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H13L18 5V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V6H12V2H2V16ZM4 14H14V12H4V14ZM4 6H9V4H4V6ZM4 10H14V8H4V10Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>Withdrawal Requests</span>
                        </Link>
                      </li>

                      <li
                        className={
                          window.location.pathname === '/withdrawals' &&
                          'active'
                        }
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        {/* <Link
                          to="/bought-packs"
                          title="Bought packs"
                          className="smoothScroll"
                        >
                          <span className="icon">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H13L18 5V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V6H12V2H2V16ZM4 14H14V12H4V14ZM4 6H9V4H4V6ZM4 10H14V8H4V10Z"
                                fill="#1C1B1F"
                              />
                            </svg>
                          </span>
                          <span>Bought packs</span>
                        </Link> */}
                      </li>

                      <li className="mobile-menu-bottom">
                        <div className="menu-box-main-bottom">
                          <div className="menu-box-main-bottom-top">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                            <a
                              // eslint-disable-next-line no-script-url
                              href="javascript:void(0)"
                              onClick={() => {
                                sessionStorage.clear();
                                window.location.replace('/register');
                              }}
                              title="logout"
                              className="logout"
                            >
                              Logout
                            </a>
                          </div>
                          <div className="menu-box-main-bottom-bottom">
                            <div className="menu-box-main-bottom-bottom-text">
                              <p>
                                Join the official
                                <br /> <span>CUBIX</span> community channel
                              </p>
                            </div>
                            <div className="menu-box-main-bottom-bottom-btn">
                              <ul>
                                <li>
                                  <Link
                                    to="#"
                                    title="refferal-programme"
                                    className="menu-btn"
                                  >
                                    Gaming Community
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title="refferal-programme"
                                    className="menu-btn"
                                  >
                                    Refferal Programme
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="menu-box-main-bottom">
              <div className="menu-box-main-bottom-top">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  // eslint-disable-next-line no-script-url
                  className="logout"
                  href="javascript:void(0)"
                  onClick={() => {
                    sessionStorage.clear();
                    window.location.replace('/register');
                  }}
                >
                  Logout
                </a>
              </div>
              <div className="menu-box-main-bottom-bottom">
                <div className="menu-box-main-bottom-bottom-text">
                  <p>
                    Join the official
                    <br /> <span>CUBIX</span> community channel
                  </p>
                </div>
                <div className="menu-box-main-bottom-bottom-btn">
                  <ul>
                    <li>
                      <Link
                        to="#"
                        title="refferal-programme"
                        className="menu-btn"
                      >
                        Gaming Community
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        title="refferal-programme"
                        className="menu-btn"
                      >
                        Referral Programme
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
