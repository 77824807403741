import { axiosInstance } from '../../utils/api/instance';

export const getPacks = () => {
  return axiosInstance.get('/masters/packs');
};

export const putAddSubscriptionSignUp = (data) => {
  return axiosInstance.put(`users/subscribe`, data);
};

export const postAddPrivateSell = (data) => {
  return axiosInstance.post(`users/privateSell`, data);
};

export const putUpdateProfile = (address, data) => {
  return axiosInstance.put(`users/details/${address}`, data);
};

export const getBoughtPacks = () => {
  return axiosInstance.get(
    '/users/bought-pack?relations[]=user&relations[]=pack'
  );
};

export const buyPack = (data) => {
  return axiosInstance.post('/users/buy-pack', data);
};

export const updatePack = (data) => {
  return axiosInstance.post('/users/change-pack-status', data);
};
