import React from 'react';
import { Link } from 'react-router-dom';
export const Footer = () => {
  return (
    <footer>
      <section className="footer-main">
        <div className="full-container">
          <div className="footer-main-detail">
            <div className="footer-main-detail-inner">
              <div className="footer-main-detail-inner-left">
                <div className="footer-main-logo">
                  <Link to="/" title="Cubix-footer">
                    <img src="/assets/images/Logo.svg" alt="footer-logo" />
                  </Link>
                </div>
                <div className="footer-main-copy">
                  <p>
                    Copyright © 2022{' '}
                    <Link to="/" title="Cunix Pro">
                      CUBIX Pro
                    </Link>{' '}
                    | Disclaimer{' '}
                  </p>
                </div>
              </div>
              <div className="footer-main-detail-inner-right">
                <ul>
                  <li>
                    <Link to="/" title="fb-icon">
                      <img
                        src="/assets/images/fb-footer-icon.png"
                        alt="fb-footer-icon"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="telegram-icon">
                      <img
                        src="/assets/images/telegram-icon-footer.png"
                        alt="telegram-icon-footer"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="twetter-icon">
                      <img
                        src="/assets/images/twetter-icon-footer.png"
                        alt="twetter-icon-footer"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="linkin-icon">
                      <img
                        src="/assets/images/linkin-icon-footer.png"
                        alt="linkin-icon-footer"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
