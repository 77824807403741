import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getConfig } from '../../../config';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { formatDate } from '../../../utils/formator/formator';
import { useGetAllWithdrawalRequest } from '../../../hooks/account/useGetAllWithdrawalRequest';

export const WithdrawalsListAll = () => {
  const { data, loading, approveRequest, approving } =
    useGetAllWithdrawalRequest();

  console.log('data ', data);
  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>All Withdrawal Requests</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div className="section-three-main-detail-table">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th></th>
                <th>Date</th>
                <th>User</th>
                <th>Withdrawal Account</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Transaction Hash</th>
                <th>Action</th>
              </tr>
              {data?.records?.length <= 0
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((d) => <tr></tr>)
                : null}
              {Array.isArray(data?.records) &&
                data?.records?.map((_data) => {
                  return (
                    <tr>
                      <td>
                        <img
                          src="/assets/images/table-request.svg"
                          alt="table-icon"
                        />
                      </td>
                      <td>{formatDate(_data?.createDateTime)}</td>
                      <td>
                        {_data?.user?.email} ({_data?.user?.referralCode})
                      </td>
                      <td>{_data?.toAddress}</td>
                      <td>{_data?.amount}</td>
                      <td>{_data?.status}</td>
                      <td>
                        {_data?.txHash ? (
                          <div className="section-three-main-detail-table-box-main">
                            <div
                              className="table-chip"
                              style={{ background: '#004F51', color: 'white' }}
                            >
                              <span>{shortenAccountAddr(_data?.txHash)}</span>
                            </div>
                            <span>
                              <CopyToClipboard
                                text={_data?.txHash}
                                onCopy={() => alert('Copied!')}
                              >
                                <a href="javascript:void(0)">
                                  <img
                                    src="/assets/images/link-grey-icon.png"
                                    alt="link-grey-icon"
                                  />
                                </a>
                              </CopyToClipboard>
                            </span>
                            <span>
                              <a
                                href={`${getConfig('explorerLink')}tx/${
                                  _data?.txHash
                                }`}
                                target={'_blank'}
                              >
                                <img
                                  src="/assets/images/open-link-icon.png"
                                  alt="open-link-icon"
                                />
                              </a>
                            </span>
                          </div>
                        ) : (
                          'NA'
                        )}
                      </td>
                      <td>
                        {_data.status === 'pending' ? (
                          <button
                            className="btns"
                            style={{ marginRight: 12 }}
                            onClick={() => {
                              approveRequest(
                                [_data?.toAddress],
                                [
                                  window.web3Instance.utils.toWei(
                                    String(_data?.amount),
                                    'mwei'
                                  ),
                                ],
                                [_data?.id]
                              );
                            }}
                          >
                            {approving ? 'Please wait...' : 'Approve'}
                          </button>
                        ) : (
                          '-'
                        )}
                        {/* <button className="btns">Reject</button> */}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
