import { Content } from '../../components/layout/content';
import { AffiliateLink } from '../../components/partners/affiliate-link';
import { PartnersList } from '../../components/partners/list';
import {
  NewPartners,
  NewPartnersStats,
} from '../../components/partners/new-partners';
import { TotalPartners } from '../../components/partners/total-partners';
import { useGetRefStats } from '../../hooks/account/useGetRefStats';
import { useGetPartners } from '../../hooks/account/useGetPartners';
import { useLocation } from 'react-router-dom';

export const PartnerStats = ({ partners, loading }) => {
  const { refProfitsStats } = useGetRefStats();
  return (
    <>
      <section className="section-one">
        <div className="section-one-detail">
          <div className="section-one-detail-inner">
            <TotalPartners
              count={refProfitsStats?.childPartners}
              partners={partners}
              loading={loading}
            />
            <AffiliateLink />
          </div>
        </div>
      </section>
      <section class="map-chart-full-section">
        <NewPartners monthWisePartners={refProfitsStats?.monthWisePartners} />
        <div class="map-chart-full-bottom">
          <div class="map-chart-full-bottom-inner">
            {/* <NewPartnersStats
              img={'/assets/images/link-click.png'}
              title={'Link Clicks'}
              count={refProfitsStats?.linkClicks ?? 0}
              delta={`+${refProfitsStats?.linkClicks ?? 0}`}
            /> */}
            <NewPartnersStats
              img={'/assets/images/total-direct-partner-icon.png'}
              title={'Total Direct Partners'}
              count={refProfitsStats?.directPartners}
              delta={refProfitsStats?.directPartner24Delta}
            />
            <NewPartnersStats
              img={'/assets/images/team-icon.png'}
              title={'Team'}
              count={refProfitsStats?.childPartners}
              delta={`+${refProfitsStats?.teamPartner24Delta}`}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const PatnersWrapper = () => {
  const {
    partners,
    loading,
    setPage,
    page,
    setSearch,
    setSearchInput,
    searchInput,
  } = useGetPartners();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const view = query.get('view') || '';
  return (
    <>
      {/* <PartnerStats partners={partners} loading={loading} /> */}
      <PartnersList
        partners={partners}
        loading={loading}
        setPage={setPage}
        page={page}
        view={view}
        setSearch={setSearch}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
      />
    </>
  );
};
export const PartnersView = () => {
  return (
    <Content>
      <PatnersWrapper />
    </Content>
  );
};
