import { useState } from 'react';
import { Content } from '../../components/layout/content';
import { WithdrawalsList } from '../../components/withdrawals/list';
import { useGetMyWithdrawalRequest } from '../../hooks/account/useGetMyWithdrawalRequest';
import { RequestWithdrawal } from '../../components/withdrawals/request-withdrawal';

const WithdrawalListViewWrapper = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [toAccountAddress, setToAccountAddress] = useState('');

  const { data, loading, _getWithdrawRequests } = useGetMyWithdrawalRequest();

  return (
    <>
      <div className="pack-boxs-flex-main">
        <div className="pack-boxs-flex-main-inner claim-income">
          <div className="left-text"></div>
          <a
            onClick={() => {
              setModalIsOpen(true);
            }}
            href="javascript:void(0)"
            title="Request Withdrawal"
            className="btns"
          >
            Request Withdrawal
          </a>
        </div>
      </div>

      <RequestWithdrawal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        amount={amount}
        setAmount={setAmount}
        setToAccountAddress={setToAccountAddress}
        toAccountAddress={toAccountAddress}
        _getWithdrawRequests={_getWithdrawRequests}
      />

      <WithdrawalsList data={data} loading={loading} />
    </>
  );
};
export const WithdrawalListView = () => {
  return (
    <Content>
      <WithdrawalListViewWrapper />
    </Content>
  );
};
