import { Content } from '../../components/layout/content';
import { BoughtPackList } from '../../components/packs/bought-packs';

export const BoughtPacksPageView = () => {
  return (
    <Content>
      <BoughtPackList />
    </Content>
  );
};
