import React from 'react';
import { useState } from 'react';
import { RequestWithdrawal } from '../withdrawals/request-withdrawal';
import { TransferAmountModal } from '../withdrawals/transfer-amount';
import { useGetAccountDetails } from '../../hooks/account/useGetAccountDetails';
import { useMyAccount } from '../../hooks/account/useMyAccount';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export const WalletComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [toAccountAddress, setToAccountAddress] = useState('');

  const [transferAmountModalIsOpen, setTransferAmountModalIsOpen] =
    useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [transferAmountToAccountAddress, setTransferAmountToAccountAddress] =
    useState('');

  const { wallet } = useMyAccount();

  const { accountDetails } = useGetAccountDetails(wallet);

  const navigate = useNavigate();

  return (
    <>
      <section className="hero-main-section-right-top">
        <div
          className="hero-main-section-right-top-inner"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '60px',
          }}
        >
          <div
            className="hero-main-section-right-top-inner-content"
            style={{ textAlign: 'center' }}
          >
            <h2>Current Balance</h2>
            <h2>
              <span>
                USDT {accountDetails?.walletProfile?.totalUSDTBalance}
              </span>
            </h2>
          </div>
        </div>
      </section>

      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <div className="pack-boxs-flex-main m-15">
          <div className="pack-boxs-flex-main-inner claim-income">
            <div className="left-text"></div>
            <a
              onClick={() => {
                swal(
                  'Deposit Details',
                  `Please deposit USDT in this account address ${accountDetails?.accountAddress}`,
                  'info'
                );
              }}
              href="javascript:void(0)"
              title="Request Withdrawal"
              className="btns"
            >
              Deposit
            </a>
          </div>
        </div>

        <div className="pack-boxs-flex-main m-15">
          <div className="pack-boxs-flex-main-inner claim-income">
            <div className="left-text"></div>
            <a
              onClick={() => {
                if (accountDetails?.walletProfile?.totalUSDTBalance > 0) {
                  setModalIsOpen(true);
                } else {
                  swal(
                    'Error',
                    'You dont have enough amount to withdraw',
                    'error'
                  );
                }
              }}
              href="javascript:void(0)"
              title="Request Withdrawal"
              className="btns"
            >
              Withdraw
            </a>
          </div>
        </div>

        <div className="pack-boxs-flex-main m-15">
          <div className="pack-boxs-flex-main-inner claim-income">
            <div className="left-text"></div>
            <a
              onClick={() => {
                if (accountDetails?.walletProfile?.totalUSDTBalance > 0) {
                  setTransferAmountModalIsOpen(true);
                } else {
                  swal(
                    'Error',
                    'You dont have enough amount to transfer',
                    'error'
                  );
                }
              }}
              href="javascript:void(0)"
              title="Request Withdrawal"
              className="btns"
            >
              Transfer
            </a>
          </div>
        </div>

        <RequestWithdrawal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          amount={amount}
          setAmount={setAmount}
          setToAccountAddress={setToAccountAddress}
          toAccountAddress={toAccountAddress}
          _getWithdrawRequests={() => navigate('/withdrawals')}
        />

        <TransferAmountModal
          modalIsOpen={transferAmountModalIsOpen}
          setModalIsOpen={setTransferAmountModalIsOpen}
          amount={transferAmount}
          setAmount={setTransferAmount}
          setToAccountAddress={setTransferAmountToAccountAddress}
          toAccountAddress={transferAmountToAccountAddress}
          _getWithdrawRequests={() => null}
        />
      </div>
    </>
  );
};
