import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getConfig } from '../../../config';
import { shortenAccountAddr } from '../../../hooks/account/useMyAccount';
import { formatDate } from '../../../utils/formator/formator';

const colors = {
  success: '#51E16B',
  reject: '#F21B1B',
  pending: '#F2B61B',
};
export const WithdrawalsList = ({ data, loading }) => {
  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Withdrawal Requests</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div className="section-three-main-detail-table">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Account</th>
                <th>Withdrawal Address</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Transaction Hash</th>
              </tr>
              {data?.records?.length <= 0
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((d) => <tr></tr>)
                : null}
              {Array.isArray(data?.records) &&
                data?.records?.map((_data) => {
                  return (
                    <tr>
                      <td>
                        <img
                          src="/assets/images/table-request.svg"
                          alt="table-icon"
                        />
                      </td>
                      <td>{formatDate(_data?.createDateTime)}</td>
                      <td>
                        {_data?.user?.email} (
                        {_data?.user?.node === 0 ? 'Team A' : 'Team B'})
                      </td>

                      <td>
                        <div className="section-three-main-detail-table-box-main">
                          <div
                            className="table-chip"
                            style={{ background: '#004F51', color: 'white' }}
                          >
                            {shortenAccountAddr(_data?.toAddress)}
                          </div>
                          <span>
                            <CopyToClipboard
                              text={_data?.toAddress}
                              onCopy={() => alert('Copied!')}
                            >
                              <a href="javascript:void(0)">
                                <img
                                  src="/assets/images/link-grey-icon.png"
                                  alt="link-grey-icon"
                                />
                              </a>
                            </CopyToClipboard>
                          </span>
                          <span>
                            <a
                              href={`${getConfig('explorerLink')}address/${
                                _data?.toAddress
                              }`}
                              target={'_blank'}
                            >
                              <img
                                src="/assets/images/open-link-icon.png"
                                alt="open-link-icon"
                              />
                            </a>
                          </span>
                        </div>
                      </td>
                      <td>{_data?.amount} USDT</td>
                      <td
                        style={{
                          color: colors?.[_data?.status],
                          textTransform: 'uppercase',
                        }}
                      >
                        {_data?.status}
                      </td>
                      <td>
                        {_data?.txHash ? (
                          <div className="section-three-main-detail-table-box-main">
                            <div
                              className="table-chip"
                              style={{ background: '#004F51', color: 'white' }}
                            >
                              <span>{shortenAccountAddr(_data?.txHash)}</span>
                            </div>
                            <span>
                              <CopyToClipboard
                                text={_data?.txHash}
                                onCopy={() => alert('Copied!')}
                              >
                                <a href="javascript:void(0)">
                                  <img
                                    src="/assets/images/link-grey-icon.png"
                                    alt="link-grey-icon"
                                  />
                                </a>
                              </CopyToClipboard>
                            </span>
                            <span>
                              <a
                                href={`${getConfig('explorerLink')}tx/${
                                  _data?.txHash
                                }`}
                                target={'_blank'}
                              >
                                <img
                                  src="/assets/images/open-link-icon.png"
                                  alt="open-link-icon"
                                />
                              </a>
                            </span>
                          </div>
                        ) : (
                          'NA'
                        )}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
