import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
  scales: {
    yAxis: {
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export const NewPartners = ({ monthWisePartners }) => {
  const labels = Object.keys(monthWisePartners ?? {}).map((m) => m);
  const data = {
    labels,
    datasets: [
      {
        label: 'New Partners',
        data: Object.values(monthWisePartners ?? {}).map((value) => value),
        backgroundColor: '#DBFF01',
      },
    ],
  };
  return (
    <div className="section-one-detail-box new-partner">
      <div className="new-partner-detail-box">
        <div className="new-partner-detail-box-top">
          <h3>New Partners</h3>
        </div>
        <div className="new-partner-detail-box-center">
          <div className="new-partner-detail-box-center-image">
            <Bar options={options} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const NewPartnersStats = ({ title, count, delta, img }) => {
  return (
    <div className="map-chart-full-bottom-box">
      <div className="map-chart-full-bottom-box-top">
        <div className="section-one-center-box section-one-bg">
          <div className="section-one-center-box-top">
            <p>{title}</p>
          </div>
          <div className="section-one-center-box-middle">
            <div className="section-one-center-box-middle-inner">
              <div className="section-one-center-box-middle-inner-left">
                <h2>
                  <span className="icon">
                    <img src={img} alt="link-click" />
                  </span>
                  <span className="text">{count}</span>
                </h2>
              </div>
              <div className="section-one-center-box-middle-inner-right">
                <img src="/assets/images/wave-icon.png" alt="wave-icon" />
              </div>
            </div>
          </div>
          <div className="section-one-center-box-bottom">
            <div className="section-one-center-box-bottom-inner">
              <div className="section-one-center-box-bottom-inner-left">
                <span>{delta}</span>
              </div>
              <div className="section-one-center-box-bottom-inner-right">
                <span>Last 24 hr change</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
