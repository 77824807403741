import { useEffect, useState } from 'react';
import { fetchAccountDetails, getAccountStats } from '../../services/user';
import swal from 'sweetalert';

export const useGetAccountDetails = (address) => {
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});

  const getAccountDetails = async () => {
    try {
      const details = await fetchAccountDetails(address);
      if (details?.isActive) {
        setAccountDetails(details);

        doGetAccountStats(details?.id);
      } else {
        swal(
          'Error',
          'Account is not yet active, Please check your mail and activate your account. Thanks',
          'error'
        ).then(() => {
          window.location.replace('/login');
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const doGetAccountStats = async (userId) => {
    const response = await getAccountStats(userId);
    setStats(response);
  };

  useEffect(() => {
    if (address) {
      getAccountDetails();
    }
  }, [address]);

  return {
    accountDetails,
    setAccountDetails,
    loading,
    getAccountDetails,
    stats,
  };
};
