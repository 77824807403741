import React from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useGetUserIdsBasedOnEmail } from '../../../../hooks/account/useGetUserIdsBasedOnEmail';
import { useChangeUserIdBasedOnId } from '../../../../hooks/account/useChangeUserIdBasedOnId';

const ChangeAccountIdModal = ({
  modalIsOpen,
  setModalIsOpen,
  accountAddress,
}) => {
  const { loading, data } = useGetUserIdsBasedOnEmail();
  const { loading: setting, changeAddress } = useChangeUserIdBasedOnId();

  return (
    <Modal
      ariaHideApp={false}
      className="react-modal"
      isOpen={modalIsOpen}
      style={{
        overlay: {
          zIndex: 1111111111111,
          backgroundColor: '#000000a6',
        },
        content: {
          border: '1px solid rgb(56, 56, 56)',
          background: 'rgb(3, 3, 3)',
          borderRadius: '20px',
          outline: 'none',
          boxShadow: 'rgba(219, 255, 1, 0.51) 0px 0px 25px 2px',
          overflowY: 'auto',
          height: '80vh',
          margin: 'auto',
          width: '800px',
          padding: '30px',
          marginTop: '50px',
          position: 'relative',
        },
      }}
    >
      <button
        type="button"
        className="modal-close-btn"
        onClick={() => setModalIsOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <div className="">
        <div className="welcome-back-right-box mb-33">
          <h4 style={{ textAlign: 'center' }}>Change account address</h4>
        </div>
      </div>
      {loading || setting ? 'Please wait...' : null}
      {data?.map((d) => {
        return (
          <div
            className="account-item"
            onClick={() => {
              if (setting) {
                return;
              }
              changeAddress(d?.accountAddress);
            }}
          >
            <span>
              {d?.accountAddress} - ({d?.referralCode})
            </span>
            {String(accountAddress).toLowerCase() ===
            d?.accountAddress?.toLowerCase() ? (
              <span className="account-item-selected">Selected</span>
            ) : null}
          </div>
        );
      })}
      <style jsx global>
        {`
          .account-item {
            padding: 18px;
            font-size: 20px;
            cursor: pointer;
            border-bottom: 1px solid;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow: hidden;
          }
          .account-item span {
            word-break: break-all;
          }
          .account-item-selected {
            padding: 8px !important;
            background: #dfff01;
            color: black;
            border-radius: 15px;
            cursor: pointer;
            font-size: 12px;
            float: right;
            margin-top: 8px !important;
          }
        `}
      </style>
    </Modal>
  );
};

export default ChangeAccountIdModal;
