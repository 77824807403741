import React, { useContext, useState } from 'react';
import { getConfig } from '../../../config';

import { UserContext } from '../../../context/UserContext';
import {
  contract,
  nftContract,
  getRecommendedGasPrice,
} from '../../../utils/web3/init';
import swal from 'sweetalert';

export const AdminConfig = () => {
  const { accountDetails } = useContext(UserContext);

  const [isLoading, setLoading] = useState(false);

  const [id, setid] = useState(0);
  const [price, setprice] = useState(0);
  const [nfts, setnfts] = useState(0);
  const [sportId, setsportId] = useState(0);
  const [buyLimit, setbuyLimit] = useState(0);
  const [secondTokenAddress, setSecondTokenAddress] = useState('');

  const setPackContract = async () => {
    const re = await nftContract.methods.packContract().call();
    alert(re);
  };
  const addSecondContractAddress = async () => {
    if (secondTokenAddress === '' || !secondTokenAddress) {
      return;
    }
    setLoading(true);
    const fastTxGasFeeForBuy = await getRecommendedGasPrice();

    contract.methods
      .setCubixTokenContractAddress(secondTokenAddress)
      .send({
        from: accountDetails?.accountAddress,
        gasPrice: fastTxGasFeeForBuy,
      })
      .on('transactionHash', function (hash) {})
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          swal('Cheers!', 'Second contract address set.', 'success');
          setLoading(false);
        }
      })
      .on('receipt', function (receipt) {
        console.log({ receipt });
      })
      .on('error', function (error) {
        console.log({ error });
        setLoading(false);
        swal(
          'Error!',
          'Something went wrong, Please try again after sometime.',
          'error'
        );
      });
  };

  const addNewPack = async () => {
    if (id <= 0 || price <= 0 || nfts <= 0 || sportId <= 0 || buyLimit <= 0) {
      return;
    }
    setLoading(true);

    const fastTxGasFeeForBuy = await getRecommendedGasPrice();

    contract.methods
      .addNewPack(id, price, nfts, sportId, buyLimit)
      .send({
        from: accountDetails?.accountAddress,
        gasPrice: fastTxGasFeeForBuy,
      })
      .on('transactionHash', function (hash) {})
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          swal('Cheers!', 'Pack added in contract.', 'success');
          setLoading(false);
        }
      })
      .on('receipt', function (receipt) {
        console.log({ receipt });
      })
      .on('error', function (error) {
        console.log({ error });
        setLoading(false);
        swal(
          'Error!',
          'Something went wrong, Please try again after sometime.',
          'error'
        );
      });
  };

  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Add New Pack</h2>
            </div>
          </div>
        </div>
        {String(accountDetails?.accountAddress).toLowerCase() ===
        getConfig('owner') ? (
          <div style={{ padding: 20 }}>
            <div className="wallet-address-detail">
              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="Pack Id"
                  onChange={(e) => setid(e.target.value)}
                />
              </div>
              <br />
              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="Price"
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
              <br />

              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="Sport Id"
                  onChange={(e) => setsportId(e.target.value)}
                />
              </div>
              <br />

              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="Total NFTs"
                  onChange={(e) => setnfts(e.target.value)}
                />
              </div>
              <br />

              <div className="wallet-address-box">
                <input
                  type="number"
                  className="input-style"
                  placeholder="Buy limit"
                  onChange={(e) => setbuyLimit(e.target.value)}
                />
              </div>
              <div className="wallet-address-btn">
                <button onClick={() => addNewPack()}>
                  {isLoading ? 'Adding...' : 'Add New Pack'}
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>Set second token address</h2>
            </div>
          </div>
        </div>
        {String(accountDetails?.accountAddress).toLowerCase() ===
        getConfig('owner') ? (
          <div style={{ padding: 20 }}>
            <div className="wallet-address-detail">
              <div className="wallet-address-box">
                <input
                  type="text"
                  className="input-style"
                  placeholder="Second token address"
                  onChange={(e) => setSecondTokenAddress(e.target.value)}
                />
              </div>
              <br />
              <div className="wallet-address-btn">
                <button onClick={() => addSecondContractAddress()}>
                  {isLoading ? 'Saving...' : 'Set Second contract address'}
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {String(accountDetails?.accountAddress).toLowerCase() ===
        getConfig('owner') ? (
          <div className="wallet-address-btn">
            <button onClick={() => setPackContract()}>Set Pack contract</button>
          </div>
        ) : null}
      </div>
    </section>
  );
};
