import React from 'react';
import { SetPasswordView } from './view';
import { useResetPassword } from '../../../hooks/account/useResetPassword';

export const SetPassword = () => {
  const { loading, data, setData, callSetPassword } = useResetPassword(true);

  return (
    <SetPasswordView
      loading={loading}
      callSetPassword={callSetPassword}
      data={data}
      setData={setData}
    />
  );
};
