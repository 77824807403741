import axios from 'axios';
import { getConfig } from '../../config';

export const axiosInstance = axios.create({
  baseURL: getConfig('baseURL'),
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('x-api-token')}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response?.data?.data;
  },
  (err) => {
    console.log({ err });
    if (
      err.response.status === 401 &&
      window.location.pathname !== '/register'
    ) {
      if (window.location.pathname === '/login') {
        window.sessionStorage.clear();
      } else {
        window.sessionStorage.clear();
        window.location.replace('/register');
      }
    }
    return Promise.reject(
      err?.response?.data?.message ??
        'Something went wrong!, Please try again after sometime'
    );
  }
);
axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${sessionStorage.getItem('x-api-token')}`,
  };
  return config;
});
