import { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { addWithdrawRequests, transferAmount } from '../../services/user';
import swal from 'sweetalert';

export const useAddWithdrawalRequest = () => {
  const { accountDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const _addWithdrawRequests = async (data) => {
    try {
      setLoading(true);
      const reqPayload = {
        ...data,
        accountAddress: accountDetails?.accountAddress,
        toAddress: data?.toAddress,
      };

      await addWithdrawRequests(reqPayload);
      swal(
        'Success',
        'Your request has been submitted for withdrawal, Please wait for approval, you will receive token shortly in your account',
        'success'
      );
    } catch (error) {
      swal('Error', error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const _transferAmount = async (data) => {
    try {
      setLoading(true);
      const reqPayload = {
        ...data,
        toAccountAddress: data?.toAddress,
      };

      await transferAmount(reqPayload);
      swal(
        'Success',
        `Your USDT amount ${data?.amount} has been transferred to ${data?.toAddress} successfully.`,
        'success'
      );
    } catch (error) {
      swal('Error', error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return { loading, _addWithdrawRequests, _transferAmount };
};
