import React from 'react';
// import { Link } from 'react-router-dom';

export const ActivityFeedItem = ({ title, id, time }) => {
  return (
    <div className="activity-feed-bottom-box">
      <div className="activity-feed-bottom-box-inner">
        <div className="activity-feed-bottom-box-inner-left">
          <div className="ctivity-feed-bottom-box-inner-left-inner">
            <div className="activity-feed-bottom-box-inner-left-text">
              {title}
            </div>
            {/* <div className="activity-feed-bottom-box-inner-left-id">
              <Link to="#">{id}</Link>
            </div> */}
          </div>
        </div>
        <div className="activity-feed-bottom-box-inner-right">
          <h3>{time}</h3>
        </div>
      </div>
    </div>
  );
};
