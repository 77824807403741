import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { UserContext } from '../../../context/UserContext';
import { putAcceptTerms } from '../../../services/user';

export const TermsModal = () => {
  const userDetails = useContext(UserContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log({ userDetails });
  useEffect(() => {
    if (userDetails?.accountDetails?.acceptedTerms === false) {
      setIsOpen(true);
    }
  }, [userDetails?.accountDetails?.accountAddress]);
  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto',
          height: '80vh',
          padding: 0,
          borderRadius: 20,
        },
      }}
    >
      <div class="disclaimer-popup-section" id="disclaimer-popup">
        <div class="main">
          <div class="disclaimer-popup-detail">
            <div class="disclaimer-popup-title">
              <h2>Disclaimer</h2>
            </div>
            <div class="disclaimer-popup-content">
              <p>
                You acknowledge and agree that the said NFTs (Non – Fungible
                Tokens) use cases are eligible/applicable only for the in-game
                process of the Cubix platform.
              </p>
              <p>
                You acknowledge and agree that the CUBIX does not provide any
                gaurantees against the sale of the NFTS used in the Fantasy
                Games.
              </p>
              <p>
                You acknowledge and agree that the holding of the Cubix Tokens
                is subject to market risk. Inherently in risk of the potential
                for Tokens/nfts, and/or Wallets, to be lost, stolen, or hacked.
              </p>
              <p>
                You expressly understand and agree that your access to and use
                of the services provided by the Cubix platform are at your sole
                risk, and that the services are provided "as is" and "as
                available" without warranties of any kind, whether express or
                implied.
              </p>
              <p>
                You agree that it is lawful and legal in the region and
                territory you are from to buy/own crypto currency tokens / Nfts.
              </p>
              <p>
                If you choose to invest in the Cubix tokens or Nfts you do so on
                your own initiative and are solely responsible for compliance
                with local laws in the territory of access.
              </p>
              <p>
                As an investor/player/ platform user you agree to indemnify and
                hold Cubix and anyone who provides services for Cubix to be
                harmless against any and all liability, loss, damages, costs,
                and expenses which you may incur while investing in token and
                Nft sales facilitated by Cubix.
              </p>
              <p>
                Please ensure that your own computer security is comprehensive
                and up to date. We accept no responsibility for losses caused by
                viruses, malware or other malicious or damaging software.
              </p>
              <p>
                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY: THIS
                PLATFORM IS PROVIDED BY CUBIX ON AN “AS IS” AND “AS AVAILABLE”
                BASIS. CUBIX MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS PLATFORM OR THE
                INFORMATION, CONTENT, PRE-SALES, OR NFTS AND TOKENS SOLD ON THIS
                PLATFORM. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS
                AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE
                LAW, CUBIX DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO NFT AND TOKEN SALES. CUBIX WILL
                NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE
                OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO DIRECT,
                INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
              </p>
              <p>
                Buying and trading cryptocurrencies should be considered a
                high-risk activity. CUBIX strongly encourages you to consult
                with a registered investment adviser or qualified investment
                professional and to do your own due diligence before making any
                investment decisions. CUBIX is not accountable, directly or
                indirectly, for any damage or loss incurred, alleged or
                otherwise, in connection to the use or reliance of any content
                you read or Investment you make through the Sales of tokens or
                Nfts we provide access to.
              </p>
            </div>
            <div class="disclaimer-popup-btn">
              {/* <div class="disclaimer-popup-btn-check-box">
              <label class="container">
                I agree and accept
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </div> */}
              <div class="disclaimer-popup-btn-accept">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  // eslint-disable-next-line no-script-url
                  href="javascript:void(0)"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await putAcceptTerms(
                        userDetails?.accountDetails?.accountAddress
                      );
                      setIsOpen(false);
                    } catch (error) {
                      setLoading(false);
                    }
                  }}
                  class="btns"
                >
                  {loading ? 'Please wait...' : 'I agree and accept'}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
