import React from 'react';
import { formatDate } from '../../../utils/formator/formator';
import { useGetMyIncome } from '../../../hooks/account/useGetMyIncome';

const bonusTypesLabel = {
  team: 'My Team Matching Bonus',
  team_direct: 'My Direct Matching Bonus',
  direct: 'My Sponsor Bonus',
};

export const MyIncomeList = ({ bonusType }) => {
  const { income, loading } = useGetMyIncome(bonusType);
  return (
    <section className="section-partners" style={{ minHeight: '100vh' }}>
      <div className="section-three-main">
        <div className="section-three-main-title">
          <div className="section-three-main-title-inner">
            <div className="section-three-main-title-inner-left">
              <h2>My {bonusTypesLabel?.bonusType?.replace('_', ' ')} Bonus</h2>
            </div>
          </div>
        </div>
        <div className="section-three-main-detail">
          <div className="section-three-main-detail-table">
            {loading && <p>Loading...</p>}
            <table>
              <tr>
                <th></th>
                <th>Date</th>
                <th>ID</th>
                <th>Bonus Type</th>
                <th>Level</th>
                <th>Percentage</th>
                <th>Bonus</th>
              </tr>
              {income?.records?.length <= 0
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((d) => <tr></tr>)
                : null}
              {Array.isArray(income?.records) &&
                income?.records?.map((record) => {
                  return (
                    <tr>
                      <td>
                        <img
                          src="/assets/images/table-bonus.svg"
                          alt="table-icon"
                        />
                      </td>
                      <td>{formatDate(record?.createDateTime)}</td>
                      <td>ID: {record?.bonusFrom?.referralCode}</td>
                      <td style={{ textTransform: 'uppercase' }}>
                        {String(record?.bonusType).replace('_', ' ')}
                      </td>
                      <td>{record?.userLevel}</td>
                      <td>{record?.percentage}%</td>
                      <td>{record?.amount} USDT</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
