import React, { useEffect, useState } from 'react';
// import './css/style.css';
// import './css/responsive.css';
import { Link } from 'react-router-dom';

import { useAddSubscriptionSignUp } from '../../hooks/account/useAddSubscriptionSignUp';
// import PrivateSellModal from './privateSellModal/privateSellModal';

const HomePageView = ({ packs }) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  // const [modalIsOpen, setModalIsOpen] = useState(false);

  const { email, setEmail, addSubscriptionSignUp, loading } =
    useAddSubscriptionSignUp();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="wrapper">
      {/* <PrivateSellModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      /> */}
      <div className="tele">
        <a href="https://t.me/cubixpro" title="telegram" target="_blank">
          <img src="/assets/images/telegram.svg" alt="telegram" />
        </a>
      </div>
      <header className="header">
        <div className="main">
          <div className="menu-box">
            <div className="menu-left">
              <a href="#" target="_blank" title="Cubix">
                <img src="/assets/images/Logo.svg" alt="logo" />
              </a>
            </div>
            <div className="menu-right">
              <nav>
                <div className="enumenu_container">
                  <div
                    id="menu-icon"
                    className={`menu-icon ${open ? ' active' : ''}`}
                    onClick={() => setOpen(!open)}
                  >
                    {' '}
                    <div className="menu-box">
                      <span></span>
                      <span></span>
                      <span></span>{' '}
                    </div>
                    Menu
                  </div>
                  <ul
                    className="enumenu_ul mob"
                    style={{ display: open || !isMobile ? 'block' : 'none' }}
                  >
                    <li className="">
                      <a href="#home" title="Home" className="smoothScroll">
                        Home{' '}
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="#fs"
                        title="Fantasy Sports"
                        className="smoothScroll"
                      >
                        Fantasy Sports
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="#market"
                        title="Marketplace"
                        className="smoothScroll"
                      >
                        Marketplace
                      </a>
                    </li>
                    <li className="">
                      <a href="#token" title="Token" className="smoothScroll">
                        Token
                      </a>
                    </li>
                    <li className="">
                      <a href="#road" title="Roadmap" className="smoothScroll">
                        Roadmap
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="#con"
                        title="Contact Us"
                        className="smoothScroll"
                      >
                        Contact Us
                      </a>
                    </li>

                    <li className="">
                      <Link
                        to="/login"
                        title="Connect Wallet"
                        className="smoothScroll connect-wallet-btn"
                      >
                        Login
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="/register"
                        title="Connect Wallet"
                        className="smoothScroll connect-wallet-btn"
                      >
                        Register
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="midd-container">
        <section className="section-1" id="#home">
          <div className="main">
            <section className="marketplace-div">
              <div className="one">
                <div className="image-div">
                  <img
                    src="/assets/images/marketplace-div-img-1.svg"
                    alt="marketplace-div-img-1"
                  />
                  <img
                    src="/assets/images/marketplace-div-img-2.svg"
                    alt="marketplace-div-img-2"
                  />
                </div>
                <div className="marketplace-div-text">
                  <h2>CUBIX World is live now!!</h2>
                  <p>
                    Explore Marketplace and upcoming Games in the Cubix World
                  </p>
                </div>
              </div>
              <div className="two">
                <a
                  className="marketplace-div-link"
                  href="https://cubixpro.world/"
                  target="_blank"
                >
                  Visit Here
                </a>
              </div>
            </section>
            <div className="inner-box">
              <div
                className="left-text"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="800"
              >
                <h1>
                  Cubix, the <span>Blockchain</span>-based
                  <br /> <span>Fantasy Sports</span> Platform.
                  <br />
                  <span> Your</span> game, <span>play</span> it your way.
                </h1>
                <p>
                  Cubix is an NFT fantasy sports Platform, and is the first to
                  offer 2 different playing modes. Play to earn and cater to
                  your style of play, whether it’s the standard for 1:1 matches,
                  or you want to engage in a larger tournament style gameplay.
                </p>
                {/* <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    marginTop: 16,
                  }}
                >
                  <img
                    role={'button'}
                    style={{ height: 100, cursor: 'pointer' }}
                    onClick={() => setModalIsOpen(true)}
                    src="/assets/images/Button animation.gif"
                    alt="buy now"
                  />
                </div> */}
              </div>
              <div
                className="rigt-img"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
              >
                <img src="/assets/images/hero.png" alt="heroimage" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-2">
          <div className="main">
            <div className="inner-box">
              <h3>
                Who’s in your <span>Dream Team</span>?
              </h3>
              <div className="nft-boxes">
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                >
                  <div className="img-box">
                    <img src="/assets/images/image-1.png" alt="NFT" />
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="100"
                >
                  <div className="img-box">
                    <img src="/assets/images/image-2.png" alt="NFT" />
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="200"
                >
                  <div className="img-box">
                    <img src="/assets/images/image-3.png" alt="NFT" />
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="300"
                >
                  <div className="img-box">
                    <img src="/assets/images/image-4.png" alt="NFT" />
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="400"
                >
                  <div className="img-box">
                    <img src="/assets/images/image-5.png" alt="NFT" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-9">
          <div className="main">
            <div className="inner-box">
              <h3>
                Play your <span>Favorite</span> Sports
              </h3>
              <div className="nft-boxes">
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon1.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>Cricket</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="100"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon2.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>Soccer</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="200"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon3.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>Baseball</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="300"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon4.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>Basketball</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="400"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon5.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>American Football</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
                <div
                  className="nft-box"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-delay="400"
                >
                  <div className="img-box">
                    <img src="/assets/images/play-icon6.png" alt="play-icon" />
                  </div>
                  <div className="play-your-title">
                    <h3>Hockey</h3>
                  </div>
                  <div className="play-your-btn">
                    <a href="#" className="upcoming-btn">
                      UPCOMING
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-3" id="fs">
          <div className="main">
            <div
              className="section-text"
              data-aos="fade-down"
              data-aos-easing="linear"
            >
              <h2 className="title">
                Start <span>playing</span> and <span>earning</span> in minutes.
              </h2>
              <p>
                Collect your favorite players, form your team, and see how they
                stack up against their real-life counterparts.
              </p>
            </div>
            <div className="card-box">
              <ul>
                <li data-aos="fade-up" data-aos-easing="linear">
                  <img src="/assets/images/card-1.png" alt="card" />
                  <p>Pick Your Favourite Sport</p>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-delay="100"
                >
                  <img src="/assets/images/card-2.png" alt="card" />
                  <p>Purchase NFT</p>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-delay="200"
                >
                  <img src="/assets/images/card-3.png" alt="card" />
                  <p>Make Your Team</p>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-delay="300"
                >
                  <img src="/assets/images/card-4.png" alt="card" />
                  <p>Play To Win</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="section-4" id="market">
          <div className="main">
            <div
              className="section-text"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <h2 className="title">
                Here’s what you get inside
                <br />
                your <span>NFT</span> packs:
              </h2>
              <p>
                Each NFT pack comes stacked with different cards. Most of them
                will be common player cards, but on occasion, you’ll get lucky
                and score a few rare, legendary, and unique NFT cards along the
                way.
              </p>
            </div>
            <div className="pack-boxs">
              {packs?.loading ? 'Loading...' : null}
              {packs?.data?.records?.map((pack) => {
                const defaultDesc = 'Chance to score any 1 of the tier card';
                const obj = {};
                pack?.cardDistribution
                  ?.filter((_cardDistribution) => {
                    return _cardDistribution?.totalCards > 0;
                  })
                  .map((_cardDistribution) => {
                    obj[_cardDistribution?.cardType?.title] =
                      _cardDistribution?.totalCards;
                    return `${_cardDistribution?.totalCards} ${_cardDistribution?.cardType?.title}`;
                  })
                  .join(' | ');

                return (
                  <div
                    className="pack-box"
                    data-aos="fade-down"
                    data-aos-duration="800"
                  >
                    <div className="img-box">
                      <img src={pack?.image} alt={pack?.title} />
                    </div>
                    <div className="text-box">
                      <div className="innter-text">
                        <div className="left-text">
                          <p className="name">{pack?.title}</p>
                          {/* <p className="light">Liverpool</p> */}
                        </div>
                        <div className="right-text">
                          <p className="price">USDT ${pack?.price}</p>
                        </div>
                      </div>
                      <div className="info-text">
                        {obj?.Legendary
                          ? `${obj?.Legendary} Legendary | ${obj?.['Super Rare']} Super Rare | ${obj?.['Rare']} Rare | ${obj?.['Common']} Common`
                          : defaultDesc}
                      </div>
                      <Link
                        to={pack?.isActive ? '/register' : '/'}
                        title="BUY NOW"
                        className={`${!pack?.isActive ? 'disabled' : ''} btns`}
                      >
                        BUY NOW
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="section-10" id="nft-roadmap">
          <div className="main">
            <div className="nft-roadmap-section-detail">
              <div className="nft-roadmap-section-top">
                <h3>
                  <span>NFT</span> Roadmap
                </h3>
              </div>
              <div className="nft-roadmap-section-bottom">
                <div className="nft-roadmap-section-bottom-inner">
                  <div className="nft-roadmap-box">
                    <div className="nft-roadmap-box-image">
                      <img
                        src="/assets/images/nft-road-img1.png"
                        alt="nft-road-img"
                      />
                    </div>
                    <div className="nft-roadmap-box-content">
                      <h4>June 2022</h4>
                      <p> Cricket</p>
                      <p>NFT sale starts</p>
                    </div>
                  </div>
                  <div className="nft-roadmap-box">
                    <div className="nft-roadmap-box-image">
                      <img
                        src="/assets/images/nft-road-img2.png"
                        alt="nft-road-img"
                      />
                    </div>
                    <div className="nft-roadmap-box-content">
                      <h4>August 2022</h4>
                      <p> Football</p>
                      <p>NFT sale starts</p>
                    </div>
                  </div>
                  <div className="nft-roadmap-box">
                    <div className="nft-roadmap-box-image">
                      <img
                        src="/assets/images/nft-road-img3.png"
                        alt="nft-road-img"
                      />
                    </div>
                    <div className="nft-roadmap-box-content">
                      <h4>2023</h4>
                      <p>Basketball</p>
                      <p>NFT sale starts</p>
                    </div>
                  </div>
                  <div className="nft-roadmap-box">
                    <div className="nft-roadmap-box-image">
                      <img
                        src="/assets/images/nft-road-img4.png"
                        alt="nft-road-img4"
                      />
                    </div>
                    <div className="nft-roadmap-box-content">
                      <h4>2023</h4>
                      <p>American Football</p>
                      <p>NFT sale starts</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-11" id="affiliation-programme">
          <div className="main">
            <div className="affiliation-programme-detail">
              <div className="affiliation-programme-top">
                <h3>
                  <span>Affiliation </span>Programme
                </h3>
              </div>
              <div className="affiliation-programme-bottom">
                <div className="affiliation-programme-bottom-inner">
                  <div className="affiliation-programme-left affiliation-programme-box">
                    <img
                      src="/assets/images/affiliation-programme.png"
                      alt="affiliation-programme"
                    />
                  </div>
                  <div className="affiliation-programme-right affiliation-programme-box">
                    <div className="affiliation-programme-right-content">
                      <p>
                        Cubix Affiliation Programme is very unique and easy to
                        adopt by anyone, no matter how big or small community
                        you have.
                      </p>
                      <p>
                        Anyone can join form anywhere, and can earn referral
                        earning by just sharing your referral ID with your
                        friends, family and community. Please make sure your
                        Referral ID is active before you share with anyone, and
                        to activate your referral ID, purchase minimum 30$ pack.
                      </p>
                      <p>
                        Please note that Cubix Affilation program is pure
                        affiliation program, it’s not Pyramid scheme or MLM
                        plans. Cublix is selling NFT packs for games which we
                        are developing and same time we are giving opportunity
                        to our community to earn referral income.
                      </p>
                      <p>To know more, please join our telegram channel.</p>
                    </div>
                    <div className="affiliation-programme-right-btn">
                      <Link to="/register" className="btns" title="JOIN NOW">
                        JOIN NOW
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-5">
          <div className="main">
            <h2 className="title">
              Get in early for a chance to secure
              <br />
              <span>Up to 100 free CUBIX tokens</span>
            </h2>
            <div
              className="input-box"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <form
                method="post"
                id="ajaxForm"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="email"
                  className="input-style"
                  id="emails"
                  placeholder="Enter your email address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <button id="submitbtn" onClick={() => addSubscriptionSignUp()}>
                  {loading ? 'Saving...' : 'SIGN UP NOW'}
                </button>
              </form>
              <div className="sucess" id="successMessage1">
                <p>
                  Thank you for reaching out! We’ll get back to you shortly.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-6" id="token">
          <div className="main">
            <div
              className="section-text"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <h2 className="title">
                Why play for <span>CUBIX</span> tokens?
              </h2>
              <p>
                The CUBIX token is the engine that drives the CUBIX ecosystem.
                When you win your matches, you’re rewarded with CUBIX tokens.
                But it’s more than just a token for buying and selling your
                player NFTs.
              </p>
              <p>
                As a utility token, CUBIX provides many other income
                opportunities, such as staking, yield farming via a liquidity
                pool, and more. In other words, when you’re not winning by
                playing actively, you can still continue to earn. (Talk about a
                game changer.)
              </p>
            </div>
            <div className="chart-box">
              <img
                src="/assets/images/crypto.svg"
                alt="Token chain"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
              />
              <img
                src="/assets/images/chart.svg"
                alt="chart"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
              />
            </div>
          </div>
        </section>
        <section className="section-7" id="road">
          <div className="main">
            <div
              className="section-text"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <h2 className="title">
                Alpha <span>Roadmap</span>
              </h2>
              <p>
                Tour milestones, past and present. We’ll be releasing our full
                roadmap near the end of 2022.
              </p>
            </div>
            <div className="roadmap-box desk">
              <div className="roadmap-box-inner">
                <div className="roadmap-box-main">
                  <div className="roadmap-box-main-image">
                    <img
                      src="/assets/images/alpha-box-image.png"
                      alt="alpha-box-1"
                    />
                  </div>
                  <div
                    className="roadmap-box-main-content"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                  >
                    <h3>
                      <span>Q1</span> 2022
                      <span className="roadmap-box-right">
                        {' '}
                        <img
                          src="/assets/images/roadmap-right-icon.png"
                          alt="roadmap-right-icon"
                        />
                      </span>
                    </h3>
                    <ul>
                      <li> White paper and website development</li>
                      <li>Seed round </li>
                      <li>Exhibit at CED 2022 Dubai</li>
                    </ul>
                  </div>
                </div>
                <div className="roadmap-box-main">
                  <div className="roadmap-box-main-image">
                    <img
                      src="/assets/images/alpha-box-image.png"
                      alt="alpha-box-2"
                    />
                  </div>
                  <div
                    className="roadmap-box-main-content"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-delay="100"
                  >
                    <h3>
                      <span>Q2</span> 2022
                      <span className="roadmap-box-right">
                        {' '}
                        <img
                          src="/assets/images/roadmap-right-icon.png"
                          alt="roadmap-right-icon"
                        />
                      </span>
                    </h3>
                    <ul>
                      <li>Private round</li>
                      <li>Strategic partnerships</li>
                    </ul>
                  </div>
                </div>
                <div className="roadmap-box-main">
                  <div className="roadmap-box-main-image">
                    <img
                      src="/assets/images/alpha-box-image.png"
                      alt="alpha-box-3"
                    />
                  </div>
                  <div
                    className="roadmap-box-main-content"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <h3>
                      <span>Q3</span> 2022
                    </h3>
                    <ul>
                      <li>Token generation event</li>
                      <li>Dex listing </li>
                      <li>Launch of classic fantasy game</li>
                      <li>NFT pack sale</li>
                      <li>NFT exchange </li>
                      <li>Beta testing for NFT based fantasy game </li>
                    </ul>
                  </div>
                </div>
                <div className="roadmap-box-main">
                  <div className="roadmap-box-main-image">
                    <img
                      src="/assets/images/alpha-box-image.png"
                      alt="alpha-box-4"
                    />
                  </div>
                  <div
                    className="roadmap-box-main-content"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-delay="300"
                  >
                    <h3>
                      <span> Q4</span> 2022
                    </h3>
                    <ul>
                      <li>NFT game launch during the FIFA world cup 2022</li>
                      <li>Fan club</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-whitepaper" id="section-whitepaper">
          <p>
            To learn more about the <span className="primary">CUBIX</span>{' '}
            ecosystem, please read our{' '}
            <span className="primary">whitepaper</span>.
          </p>
          <div>
            <a
              href="https://cubix.gitbook.io/cubix-white-paper/"
              className="private-sale-btn"
              target={'_blank'}
            >
              Read Now
            </a>
          </div>
        </section>
        <section className="section-12" id="awarded">
          <div className="main">
            <div className="awarded-section-detail">
              <div className="awarded-section-detail-inner">
                <div
                  className="awarded-image"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="800"
                >
                  <img src="/assets/images/awared-img.png" alt="awared-img" />
                </div>
                <div className="awarded-content">
                  <div
                    className="awarded-content-title"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="800"
                  >
                    <h3>
                      <span>Awarded</span> as{' '}
                      <span>Best NFT Gaming Platform </span>
                      at Crypto Expo Dubai 2022.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-8" id="con">
          <div className="main">
            <div
              className="section-text"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <h2 className="title">
                Want to stay <span>up to date</span>?
              </h2>
              <h4>
                Sign up for our emails below, and you stand to receive Up to 100
                free CUBIX tokens!
              </h4>
            </div>
            <div
              className="input-box"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <form
                method="post"
                id="ajaxForm2"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="email"
                  className="input-style"
                  id="mail"
                  placeholder="Enter your email address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <button id="btn2" onClick={() => addSubscriptionSignUp()}>
                  {loading ? 'Saving...' : 'SIGN UP NOW'}
                </button>
              </form>
              <div className="sucess" id="successMessage2">
                <p>
                  Thank you for reaching out! We’ll get back to you shortly.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className="main">
          <div className="footer-inner">
            <div className="left-side">
              <a href="#" target="_blank" title="Cubix">
                <img src="/assets/images/footer.svg" alt="logo" />
              </a>
              <p className="cpy">Copyright © 2022 CUBIX Pro</p>
            </div>
            <div className="right-side">
              <div className="links">
                <ul>
                  {/* <li>
                    <a href="#" title="About Us">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Contact Us">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Privacy Policy">
                      Privacy Policy
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/Cubix-105219865366718"
                      title="Facebook"
                      target="_blank"
                    >
                      <img src="/assets/images/facebook.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/cubix_world"
                      title="twitter"
                      target="_blank"
                    >
                      <img src="/assets/images/twitter.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/cubixpro"
                      title="linkedin"
                      target="_blank"
                    >
                      <img src="/assets/images/linkedin.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/cubixpro"
                      title="telegram"
                      target="_blank"
                    >
                      <img src="/assets/images/telegram.svg" alt="telegram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mob">
            <p className="cpy">Copyright © 2022 CUBIX Pro</p>
          </div>
        </div>
      </footer>
      <style jsx>
        {`
          /*Rest style*/
          @font-face {
            font-family: 'Aeonik';
            src: url('../fonts/Aeonik-Light.eot');
            src: url('../fonts/Aeonik-Light.eot?#iefix')
                format('embedded-opentype'),
              url('../fonts/Aeonik-Light.woff2') format('woff2'),
              url('../fonts/Aeonik-Light.woff') format('woff'),
              url('../fonts/Aeonik-Light.ttf') format('truetype');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Aeonik';
            src: url('../fonts/Aeonik-Regular.eot');
            src: url('../fonts/Aeonik-Regular.eot?#iefix')
                format('embedded-opentype'),
              url('../fonts/Aeonik-Regular.woff2') format('woff2'),
              url('../fonts/Aeonik-Regular.woff') format('woff'),
              url('../fonts/Aeonik-Regular.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Aeonik';
            src: url('../fonts/Aeonik-Bold.eot');
            src: url('../fonts/Aeonik-Bold.eot?#iefix')
                format('embedded-opentype'),
              url('../fonts/Aeonik-Bold.woff2') format('woff2'),
              url('../fonts/Aeonik-Bold.woff') format('woff'),
              url('../fonts/Aeonik-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'Termina';
            src: url('../fonts/Termina-Bold.eot');
            src: url('../fonts/Termina-Bold.eot?#iefix')
                format('embedded-opentype'),
              url('../fonts/Termina-Bold.woff2') format('woff2'),
              url('../fonts/Termina-Bold.woff') format('woff'),
              url('../fonts/Termina-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Termina';
            src: url('../fonts/Termina-Black.eot');
            src: url('../fonts/Termina-Black.eot?#iefix')
                format('embedded-opentype'),
              url('../fonts/Termina-Black.woff2') format('woff2'),
              url('../fonts/Termina-Black.woff') format('woff'),
              url('../fonts/Termina-Black.ttf') format('truetype');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
          }

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            list-style: none;
          }
          * {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
          html,
          body.em,
          .em span,
          .em applet,
          .em object,
          .em iframe,
          .em h1,
          .em h2,
          .em h3,
          .em h4,
          .em h5,
          .em h6,
          .em p,
          .em blockquote,
          .em pre,
          .em a,
          .em abbr,
          .em acronym,
          .em address,
          .em big,
          .em cite,
          .em code,
          .em del,
          .em dfn,
          .em em,
          .em font,
          .em img,
          .em ins,
          .em kbd,
          .em q,
          .em s,
          .em samp,
          .em small,
          .em strike,
          .em strong,
          .em sub,
          .em sup,
          .em tt,
          .em var,
          .em dl,
          .em dt,
          .em dd,
          .em ol,
          .em ul,
          .em li,
          .em fieldset,
          .em form,
          .em label,
          .em legend,
          .em table,
          .em caption,
          .em tbody,
          .em tfoot,
          .em thead,
          .em tr,
          .em th,
          .em td,
          .em select,
          .em input,
          .em option {
            margin: 0;
            padding: 0;
            border: 0;
            outline: 0;
          }
          html {
            -webkit-text-size-adjust: none;
          }
          .em nav ul,
          .em nav ol {
            list-style: none;
            list-style-image: none;
          }
          .em button,
          .em input,
          .em select,
          .em textarea {
            font-size: 100%;
            margin: 0;
            vertical-align: baseline;
            box-shadow: none !important;
          }
          .em input[type='button'],
          .em input[type='submit'] {
            -webkit-appearance: none !important;
          }
          .em :focus {
            outline: 0;
          }
          .em ol,
          .em ul {
            list-style: none;
          }
          .em a {
            text-decoration: none;
          }
          .em img {
            border: 0 none;
            max-width: 100%;
            vertical-align: top;
          }
          .em a {
            outline: none;
            cursor: pointer;
          }
          .em a:hover {
            text-decoration: none;
          }
          .em a:focus {
            text-decoration: none !important;
            outline: none !important;
          }
          .em .clear {
            clear: both;
          }
          .em .preloader {
            display: none;
          }

          /*Placeholder opacity cross browsers*/
          .em input::-webkit-input-placeholder {
            color: #fff;
            opacity: 1;
          } /* WebKit browsers */
          .em input:-moz-placeholder {
            color: #fff;
            opacity: 1;
          } /* Mozilla Firefox 4 to 18 */
          .em input::-moz-placeholder {
            color: #fff;
            opacity: 1;
          } /* Mozilla Firefox 19+ */
          .em input:-ms-input-placeholder {
            color: #fff;
            opacity: 1;
          } /* Internet Explorer 10+ */
          .em textarea::-webkit-input-placeholder {
            color: #fff;
            opacity: 1;
          } /* WebKit browsers */
          .em textarea:-moz-placeholder {
            color: #fff;
            opacity: 1;
          } /* Mozilla Firefox 4 to 18 */
          .em textarea::-moz-placeholder {
            color: #fff;
            opacity: 1;
          } /* Mozilla Firefox 19+ */
          .em textarea:-ms-input-placeholder {
            color: #fff;
            opacity: 1;
          } /* Internet Explorer 10+ */
          .em button::-moz-focus-inner,
          input[type='submit']::-moz-focus-inner {
            border: 0;
          }

          /* Table of Content
         ==================================================
         #Page Structure
         #Home Page Style */

          /* #Page Structure
        ================================================== */
          body.em {
            margin: 0;
            padding: 0;
            font-size: 16px;
            color: #fff;
            font-family: 'Aeonik', sans-serif;
            background-color: #000;
          }
          .em .wrapper {
            width: 100%;
            opacity: 1;
            transition: all ease-in-out 0.5s;
            -webkit-transition: all ease-in-out 0.5s;
            -moz-transition: all ease-in-out 0.5s;
          }
          .em .main {
            max-width: 1730px;
            margin: 0 auto;
          }
          .em a {
            transition: 0.5s;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -o-transition: 0.5s;
          }
          .em h1 {
            font-size: 64px;
            line-height: 72px;
            font-weight: 700;
            padding-bottom: 52px;
          }
          h1 span {
            color: #dbff01;
          }
          .em h2.title {
            font-size: 48px;
            line-height: 72px;
            font-weight: 700;
            padding-bottom: 22px;
          }
          .em h2.title span {
            color: #dbff01;
          }
          .em h4 {
            font-size: 36px;
            line-height: 45px;
            font-weight: 700;
          }
          .em h4 span {
            color: #dbff01;
          }
          .em .sucess {
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            color: #dbff01;
            display: none;
            padding-top: 10px;
          }
          .em p {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 20px;
          }
          .em p:last-child {
            padding-bottom: 0px;
          }
          .em ul.bullets {
            margin-left: 10px;
            margin-bottom: 10px;
          }
          .em ul.bullets li {
            color: #505050;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
            padding-left: 13px;
          }
          .em .common-btn {
            display: inline-block;
          }

          .em .btns {
            background-color: #dbff01;
            border-radius: 100px;
            color: #000;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.03em;
            padding: 20px 40px;
            display: inline-block;
            border: 1px solid #dbff01;
          }
          .em .btns:hover {
            background-color: #000;
            color: #dbff01;
          }
          .connect-wallet-btn {
            padding: 17px 32px 17px 64px;
            position: relative;
            background-color: #dbff01;
            color: #000;
          }
          /* Home Page Style
         ==================================================
         
        /***** header styles goes here *****/
          .header {
            float: left;
            width: 100%;
            background-color: #030303;
            padding: 55px 0;
          }
          .header .menu-box {
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
          }
          .menu-box .menu-left {
            width: 20%;
          }
          .menu-box .menu-left img {
            width: 100%;
            max-width: 318px;
          }
          .menu-box .menu-right {
            width: 65%;
          }
          .menu-box .menu-right nav {
            float: right;
          }

          .menu-icon {
            display: none;
            cursor: pointer;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            line-height: 33px;
            font-size: 22px;
            padding: 10px 0;
            text-indent: -9999px;
          }
          .menu-icon .menu-box {
            width: 38px;
            height: 26px;
            margin-right: 20px;
            padding: 0px 5px;
            position: absolute;
            right: 0;
          }
          .menu-icon .menu-box span {
            width: 100%;
            height: 4px;
            margin-bottom: 6px;
            background: #fff;
            display: block;
            border-radius: 2px;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
          }
          .menu-icon .menu-box span:nth-child(2) {
            background: #fff;
          }
          .menu-icon.active span:nth-child(1) {
            transform: rotate(45deg) translateY(8px) translateX(8px);
            -webkit-transform: rotate(45deg) translateY(8px) translateX(8px);
          }
          .menu-icon.active span:nth-child(2) {
            opacity: 0;
          }
          .menu-icon.active span:nth-child(3) {
            transform: rotate(-45deg) translateY(-7px) translateX(6px);
            -webkit-transform: rotate(-45deg) translateY(-7px) translateX(6px);
          }
          .enumenu_ul .caret {
            color: #fff;
            margin-left: 7px;
          }
          .enumenu_ul {
            padding: 0px;
            list-style: none;
            float: left;
          }
          .enumenu_ul > li {
            display: inline-block;
            vertical-align: middle;
            position: relative;
          }
          .enumenu_ul > li.linkedin,
          .enumenu_ul > li.twitter {
            display: none;
          }
          .enumenu_ul li a {
            font-size: 20px;
            font-weight: 400;
            text-transform: unset;
            color: #fff;
            position: relative;
            border-bottom: 0px solid #fff;
            padding-bottom: 0px;
          }
          .enumenu_ul li a.menubelow::after {
            position: absolute;
            right: -16px;
            top: 5px;
            background-color: #6859a3;
            height: 10px;
            width: 10px;
            content: '';
            border-radius: 50%;
            display: none;
          }
          .enumenu_ul li {
            padding: 0 22px;
          }
          .enumenu_ul li:last-child {
            padding-right: 0px;
          }
          .enumenu_ul > li > a:hover,
          .enumenu_ul > li.active > a {
            color: #dbff01;
          }
          .enumenu_ul > li.current_page_item a {
            border-color: #6859a3;
          }
          .enumenu_ul > li > a.menubelow:hover::after,
          .enumenu_ul > li.active > a.menubelow::after {
            background-color: #11283d;
          }
          .enumenu_ul > li ul {
            display: none;
          }
          .enumenu_ul ul li {
            width: 100%;
            margin: 0;
            list-style: none;
            position: relative;
          }
          .enumenu_ul.desk ul {
            top: 100%;
            z-index: 999;
            list-style: none;
            left: -20px;
            background: transparent;
            position: absolute;
            min-width: 175px;
            right: 0;
            padding-top: 36px;
          }
          .enumenu_ul.desk li:nth-child(2) ul {
            min-width: 240px;
          }
          /*Sub menu styles*/
          .menu.submenu-ul-level1 li a {
            background: rgba(31, 26, 107, 0.8);
            color: #fff;
            padding-left: 90px;
          }
          .enumenu_ul.desk li:hover > ul {
            display: block;
            opacity: 1;
          }
          .enumenu_ul.desk .sb-menu li {
            padding: 25px 15px 10px;
            background-color: #dde3ed;
            text-align: left;
            border-bottom: 0px solid #0000001a;
          }
          .enumenu_ul.desk .sb-menu li:last-child {
            border-bottom: none;
          }
          .enumenu_ul.desk .sb-menu li:hover {
            background-color: #dde3ed;
            color: #fff;
          }
          .enumenu_ul .sb-menu li:last-child {
            padding: 25px 15px 35px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          .enumenu_ul.desk .sb-menu .sb-menu {
            left: 100%;
            top: 0;
          }
          .enumenu_ul.desk ul li a {
            display: inline-block;
            color: #11283d;
            font-size: 18px;
            font-weight: 400;
            text-align: left;
            border-bottom: 0;
            padding-bottom: 0;
          }
          .enumenu_ul.desk ul li:hover > a {
            background: transparent;
            color: #11283d;
            font-weight: bold;
          }
          .enumenu_ul.desk ul li.current-menu-item > a {
            font-weight: bold;
          }

          .enumenu_ul .arrow {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 30px;
            height: 100%;
            cursor: pointer;
            z-index: 999;
            background: url('/assets/images/i1513916064566_arrow.png') no-repeat;
            background-size: 17px;
            background-position: center center;
          }
          .enumenu_ul li.mbtn {
            cursor: pointer;
            border: 0px solid #2771b8;
            font-size: 14px;
            line-height: 26px;
            color: #6859a3;
            background: #dde3ed;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            border: 0;
            border-radius: 30px;
            padding: 11px 39px;
            display: inline-block;
            -webkit-transition: all ease-in 0.3s;
            -moz-transition: all ease-in 0.3s;
            transition: all ease-in 0.3s;
            text-transform: uppercase;
          }
          .enumenu_ul li.mbtn a {
            border: none;
            color: #6859a3;
            padding-bottom: 0;
          }
          .enumenu_ul li.mbtn:hover,
          .enumenu_ul li a.mbtn:focus {
            background: #6859a3;
            color: #fff;
          }
          .enumenu_ul li.mbtn:hover a {
            color: #fff;
          }

          .enumenu_ul li a.connect-wallet-btn {
            color: #000000;
            padding: 16px 20px;
            border-radius: 24px;
            position: relative;
          }
          // .enumenu_ul li a.connect-wallet-btn::before {
          //   content: '';
          //   position: absolute;
          //   top: 18px;
          //   left: 24px;
          //   width: 24px;
          //   height: 24px;
          //   background-image: url(/assets/images/connect-wallet-icon.png);
          //   background-repeat: no-repeat;
          //   background-size: contain;
          // }
          .play-your-btn a {
            display: inline-block;
          }
          .upcoming-btn {
            background: #005047;
            border-radius: 15px;
            padding: 8px 16px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            align-items: center;
            text-align: center;
            color: #68f9e5;
            display: inline-block;
            transition: 0.5s;
            border: 1px solid #005047 !important;
          }
          .upcoming-btn:hover {
            background: transparent;
            /* border: 1px solid #005047; */
            transition: 0.5s;
          }
          /***** midd container styles goes here *****/

          .section-1 {
            float: left;
            width: 100%;
            /* background-image: url('/assets/images/banner.png'); */
            background-position: center center;
            background-size: cover;
            padding: 50px 0;
          }
          .section-1 .inner-box {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .section-1 .inner-box .left-text {
            width: 55%;
            padding-top: 119px;
          }
          .section-1 .inner-box .rigt-img {
            width: 43%;
          }
          .section-1 .inner-box .rigt-img img {
            width: 100%;
            max-width: 688px;
          }
          .section-2 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
          }
          .section-2 .inner-box h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-2 .inner-box h3 span {
            color: #dbff01;
          }
          .nft-boxes {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin: 35px 0 0 0;
          }
          .nft-boxes .nft-box {
            /* width: 9%; */
            /* max-width: 210px; */
            width: 17.2%;
            background: #323232;
            backdrop-filter: blur(10px);
            border-radius: 15px;
            padding: 15px;
            position: relative;
          }
          /* .nft-boxes .nft-box::after{
            position: absolute;
            right: 7px;
            top: 7px;
            height: 20px;
            width: 20px;
            background-image: url('/assets/images/small.png');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            content: "";
        } */
          .nft-boxes .nft-box .img-box {
            width: 100%;
            float: left;
            padding-bottom: 5px;
          }
          .nft-boxes .nft-box .img-box img {
            width: 100%;
            /* max-width: 120px; */
            max-width: 280px;
          }
          .nft-boxes .nft-box .bottom-text {
            width: 100%;
            float: left;
            text-align: left;
            padding-top: 10px;
          }
          .nft-boxes .nft-box .bottom-text p.name {
            font-family: 'Termina', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            padding-bottom: 5px;
          }
          .nft-boxes .nft-box .bottom-text p {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 0;
          }
          .nft-boxes .nft-box .bottom-text .proce-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-top: 15px;
          }
          .nft-boxes .nft-box .bottom-text .proce-box .left-side {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
          }
          .nft-boxes .nft-box .bottom-text .proce-box .left-side img {
            width: 100%;
            max-width: 20px;
          }
          .nft-boxes .nft-box .bottom-text .proce-box .left-side .price {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #dbff01;
            padding-left: 10px;
          }
          .nft-boxes .nft-box .bottom-text .proce-box .btns {
            font-size: 12px;
            line-height: 18px;
            padding: 4px 8px;
          }
          .section-3 {
            float: left;
            width: 100%;
            padding: 100px 0;
            text-align: center;
          }
          .section-3 .section-text {
            display: inline-block;
            width: 100%;
            max-width: 830px;
            margin: 0 auto;
            text-align: center;
          }
          .section-3 .card-box {
            display: flex;
            width: 100%;
            padding-top: 80px;
          }
          .section-3 .card-box ul {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .section-3 .card-box ul li {
            width: 24%;
            padding-top: 20px;
            position: relative;
          }
          .section-3 .card-box ul li:after {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 67px;
            width: 67px;
            background-image: url('/assets/images/poly.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            content: '1';
            font-weight: 700;
            font-size: 32px;
            line-height: 58px;

            align-items: center;
            color: #000;
            text-align: center;
          }
          .section-3 .card-box ul li:nth-child(2):after {
            content: '2';
          }
          .section-3 .card-box ul li:nth-child(3):after {
            content: '3';
          }
          .section-3 .card-box ul li:nth-child(4):after {
            content: '4';
          }
          .section-3 .card-box ul li p {
            padding-top: 28px;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
          }
          .section-4 {
            float: left;
            width: 100%;
            padding: 50px 0;
            text-align: center;
          }
          .section-4 .section-text {
            display: inline-block;
            width: 100%;
            max-width: 1029px;
            margin: 0 auto;
            text-align: center;
          }
          .section-4 .pack-boxs {
            padding-top: 134px;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .section-4 .pack-boxs .pack-box {
            /* width: 49%; */
            width: 32.6%;
            background: #323232;
            border: 1px solid #000000;
            border-radius: 37px;
            padding: 24px 25px;
            margin-bottom: 20px;
          }
          .section-4 .pack-boxs .pack-box img {
            width: 100%;
          }
          .section-4 .pack-boxs .pack-box .text-box {
            padding-top: 50px;
          }
          .section-4 .pack-boxs .pack-box .text-box .innter-text {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            text-align: left;
          }
          .section-4 .pack-boxs .pack-box .text-box .innter-text p.name {
            font-weight: 700;
            font-size: 24px;
            font-family: 'Termina', sans-serif;
            line-height: 32px;
            color: #dbff01;
          }
          .section-4 .pack-boxs .pack-box .text-box .innter-text p {
            font-weight: 300;
            font-size: 24px;
            line-height: 32px;
            padding-bottom: 0;
            color: #c4c4c4;
          }
          .section-4 .pack-boxs .pack-box .text-box .innter-text p.price {
            font-weight: 700;
            font-size: 36px;
            line-height: 80px;
          }
          .section-4 .pack-boxs .pack-box .text-box .info-text {
            text-align: left;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            padding-top: 6px;
            /* padding-bottom: 40px; */
          }
          .section-5 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 40px 0;
          }
          .section-5 .input-box {
            padding-top: 70px;
          }
          .em .input-box {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            max-width: 844px;
            margin: 0 auto;
          }
          .em .input-box form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
          }
          .em .input-style {
            width: 66%;

            background: #000;
            height: 62px;
            color: #ffffff;
            font-size: 16px;
            line-height: 19px;

            padding: 19px 47px;
            position: relative;
            border: 1px solid #dbff01;
            border-radius: 67px;
            box-shadow: none;
          }

          .em .input-box button {
            background-color: #dbff01;
            border-radius: 100px;
            color: #000;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.03em;
            padding: 20px 40px;
            border: none;
            cursor: pointer;
            display: inline-block;
            border: 1px solid #dbff01;
            transition: 0.5s;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -o-transition: 0.5s;
          }
          .em .input-box button:hover {
            background-color: #000;
            color: #dbff01;
          }
          .section-6 {
            float: left;
            width: 100%;
            padding: 40px 0;
            text-align: center;
          }
          .section-6 .section-text {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
          }
          .section-6 .chart-box {
            padding-top: 100px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1410px;
            margin: 0 auto;
          }
          .section-7 {
            float: left;
            width: 100%;
            padding: 120px 0 80px;
            text-align: center;
          }
          .section-7 .roadmap-box {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding-top: 100px;
          }
          .section-7 .roadmap-box.mob {
            display: none;
          }

          .section-7 .roadmap-box img {
            width: 100%;
            max-width: 233px;
          }
          .section-8 {
            float: left;
            width: 100%;
            padding: 100px 0;
            text-align: center;
          }
          .section-8 .input-box {
            padding-top: 100px;
          }
          .section-9 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
            margin: 52px 0 0 0;
          }
          .section-9 .inner-box h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-9 .inner-box h3 span {
            color: #dbff01;
          }
          .section-9 .nft-boxes .nft-box {
            width: 15%;
            padding: 50px 15px;
          }
          .section-9 .nft-boxes .nft-box .img-box img {
            max-width: 50px;
          }
          .section-9 .nft-boxes .play-your-title h3 {
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            color: #c4c4c4;
          }
          .play-your-btn a {
            display: inline-block;
            padding: 8px 16px;
          }
          .info-price-text-btn {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin: 30px 0 0 0;
          }
          .em .info-price-text-btn .btns {
            padding: 8px 25px;
          }
          .section-10 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
            margin: 0px 0 0 0;
          }
          .section-10 .nft-roadmap-section-top h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-10 .nft-roadmap-section-top h3 span {
            color: #dbff01;
          }
          .nft-roadmap-section-bottom-inner {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          }
          .nft-roadmap-box {
            background: #323232;
            backdrop-filter: blur(10px);
            border-radius: 15px;
            padding: 45px 15px;
            position: relative;
            width: 19%;
          }
          .nft-roadmap-box::after {
            content: '';
            position: absolute;
            top: 0;
            right: -31%;
            height: 100%;
            width: 65px;
            background-image: url(/assets/images/nft-road-arrows.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 65px;
          }
          .nft-roadmap-box:nth-last-child(1):after {
            display: none;
          }
          .nft-roadmap-box .nft-roadmap-box-image {
            width: 100%;
            float: left;
            padding-bottom: 5px;
          }
          .nft-roadmap-box .nft-roadmap-box-image img {
            width: 100%;
            max-width: 165px;
          }
          .nft-roadmap-box-content h4 {
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            color: #dbff01;
          }
          .em .nft-roadmap-box-content p {
            line-height: 36px;
            padding-bottom: 2px;
          }
          .section-11 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
            margin: 60px 0 0 0;
          }
          .section-11 .affiliation-programme-top h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-11 .affiliation-programme-top h3 span {
            color: #dbff01;
          }
          .affiliation-programme-bottom-inner {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
          }
          .affiliation-programme-box {
            width: 44%;
          }
          .affiliation-programme-right-content p {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            text-align: left;
          }
          .affiliation-programme-right-btn {
            margin: 60px 0 0 0;
          }
          .chart-box-main {
            width: 46%;
          }
          .chart-box-left img {
            max-width: 322px;
          }
          .chart-box-left-content {
            margin: 60px 0 0 0;
            padding: 0 18%;
          }
          .roadmap-box-inner {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
          }
          .section-7 .roadmap-box span.roadmap-box-right img {
            width: 100%;
            max-width: 26px;
          }
          .section-7 .roadmap-box h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #ffffff;
            display: flex;
            justify-content: left;
            align-items: center;
          }
          .section-7 .roadmap-box span {
            color: #dbff01;
            display: inherit;
            padding: 0 7px 0 4px;
          }
          .roadmap-box-main {
            width: 22%;
            position: relative;
          }
          .roadmap-box-main::after {
            content: '';
            position: absolute;
            bottom: 51%;
            right: -127px;
            height: 18px;
            width: 204px;
            background-image: url(/assets/images/line-bg.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 215px;
            z-index: 1;
          }
          .roadmap-box-main-image {
            margin: 0 0 20px 0;
          }
          .roadmap-box-main:nth-last-child(1):after {
            display: none;
          }
          .section-7 .roadmap-box {
            max-width: 1300px;
          }
          .roadmap-box-main-content ul {
            padding: 0 0 0 25px;
            margin: 10px 0 0;
          }
          .roadmap-box-main-content ul li {
            text-align: left;
            list-style: disc;
            margin: 0 0 10px 0;
            display: list-item;
          }
          /* .roadmap-box-main-image {
            height: 405px;
        } */
          .roadmap-box-main-content {
            height: 240px;
            padding: 0 0 0 25%;
          }
          .awarded-section-detail-inner {
            width: 100%;
            max-width: 730px;
            margin: 0px auto 0;
          }
          .section-12 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
            margin: 0px 0 0 0;
          }
          .section-12 .awarded-content-title h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-12 .awarded-content-title h3 span {
            color: #dbff01;
          }
          .section-12 .awarded-image img {
            width: 100%;
            max-width: 300px;
          }
          .awarded-image {
            margin: 0 0 35px 0;
          }
          .section-8 h4 span {
            color: #dbff01;
          }
          .section-13 {
            float: left;
            width: 100%;
            text-align: center;
            padding: 16px 0;
            margin: 10px 0 0 0;
          }
          .section-13 .our-team-detail-top h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 32px;
          }
          .section-13 .our-team-detail-top h3 span {
            color: #dbff01;
          }
          .our-team-box {
            background: #1e1e1e;
            border-radius: 15px;
            width: 32%;
            padding: 0 45px 45px;
            margin: 0 0 150px 0;
          }
          .our-team-box:nth-last-child(1),
          .our-team-box:nth-last-child(2),
          .our-team-box:nth-last-child(3) {
            margin: 0 0 50px 0;
          }
          .our-team-detail-bottom-inner {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding: 180px 0 0 0;
          }
          .our-team-box-image-inner img {
            width: 100%;
            max-width: 200px;
          }
          .our-team-box-image {
            margin: -110px 0 0 0;
          }
          .our-team-box-title {
            text-align: left;
            margin: 18px 0 0 0;
          }
          .our-team-box-title-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            align-items: baseline;
          }
          .our-team-box-title-right img {
            width: 100%;
            max-width: 43px;
          }
          .our-team-box-content {
            text-align: left;
            margin: 25px 0 0 0;
          }
          .our-team-box-content p {
            font-weight: 400;
            font-size: 24px;
            line-height: 26px;
            color: #ffffff;
          }
          .our-team-box-title-left h3 {
            font-weight: 700;
            font-size: 32px;
            line-height: 160%;
            color: #dfff01;
          }
          .our-team-box-title-left h5 {
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            color: #a9a9a9;
          }
          .nft-roadmap-section-top {
            margin: 0 0 50px 0;
          }
          .section-5 {
            margin: 65px 0 0 0;
          }
          #pie-chart1 {
            background: #000000;
            height: 550px;
            margin-top: -80px;
          }
          #pie-chart1 rect {
            fill: #000000 !important;
          }
          .chart-map svg g:nth-child(1) g g text {
            fill: #ffffff !important;
          }

          /*****footer styles goes here *****/
          footer {
            float: left;
            width: 100%;
            background: #dbff01;
            padding: 40px 0;
          }
          footer .footer-inner {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
          }
          footer .footer-inner .cpy {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            padding-top: 15px;
          }
          footer .designed {
            text-align: right;
          }
          footer .designed p {
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #000;
          }
          footer .footer-inner .right-side {
            width: 40%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
          }
          footer .footer-inner .right-side .links {
            width: 58%;
          }
          footer .footer-inner .right-side .social {
            width: 36%;
          }

          footer .footer-inner .right-side ul {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
          }
          footer .footer-inner .right-side .social ul li:last-child {
            width: 50px;
          }
          footer .footer-inner .right-side ul a {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #000;
          }
          footer .footer-inner .right-side ul a:hover {
            opacity: 0.5;
          }
          footer .mob {
            display: none;
          }
          .tele {
            position: fixed;
            right: 20px;
            bottom: 20px;
            background-color: #dbff01;
            padding: 6px 6px;
            border-radius: 15px;
            z-index: 9;
            height: 50px;
            width: 50px;
            -webkit-box-shadow: 0px 0px 40px 0px rgba(219, 255, 1, 1);
            -moz-box-shadow: 0px 0px 40px 0px rgba(219, 255, 1, 1);
            box-shadow: 0px 0px 40px 0px rgba(219, 255, 1, 1);
          }
          .tele a:hover {
            opacity: 0.7;
          }
        `}
      </style>
      <style jsx>
        {`
          /* #Media Queries
================================================== */
          @media all and (max-width: 1770px) {
            .em .main {
              padding: 0 20px;
              max-width: 100%;
              width: 100%;
            }
            .menu-box .menu-right {
              width: 69%;
            }
            .em h1 {
              font-size: 48px;
              line-height: 4rem;
              font-weight: 700;
              padding-bottom: 40px;
            }
            .section-1 .inner-box .left-text {
              padding-top: 100px;
            }
            .section-2 .inner-box h3 {
              font-size: 32px;
              line-height: 44px;
            }
            .nft-boxes .nft-box .bottom-text p {
              font-size: 12px;
              line-height: 16px;
            }
            .nft-boxes .nft-box .bottom-text p.name {
              font-size: 13px;
              line-height: 17px;
            }
            .em h2.title {
              font-size: 40px;
              line-height: 47px;
            }
            .em h4 {
              font-size: 29px;
              line-height: 34px;
              font-weight: 700;
            }
            .em p {
              font-size: 20px;
              line-height: 27px;
            }
            .section-4 .pack-boxs {
              width: 100%;
              max-width: 1170px;
              margin: 0 auto;
              padding-top: 100px;
            }
            .section-4 .pack-boxs .pack-box .text-box {
              padding-top: 15px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p.name {
              font-size: 18px;
              line-height: 24px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p.price {
              font-size: 22px;
              line-height: 27px;
            }
            .section-4 .pack-boxs .pack-box .text-box .info-text {
              font-size: 13px;
              line-height: 19px;
              padding-top: 6px;
              padding-bottom: 21px;
            }
            .em .btns {
              font-size: 17px;
              line-height: 22px;
              padding: 8px 30px;
            }
            .section-4 .pack-boxs .pack-box {
              padding: 20px 15px;
              margin-bottom: 30px;
            }
            .section-5 .input-box {
              padding-top: 50px;
            }
            .section-6 .chart-box {
              max-width: 1170px;
              margin: 0 auto;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p {
              font-size: 20px;
              line-height: 26px;
            }
          }
          @media all and (max-width: 1610px) {
            .enumenu_ul li a.connect-wallet-btn {
              padding: 12px 20px 12px 55px;
            }
            // .enumenu_ul li a.connect-wallet-btn::before {
            //   top: 12px;
            //   left: 16px;
            // }
          }
          @media all and (max-width: 1575px) {
            .enumenu_ul li {
              padding: 0 15px;
            }
          }
          @media all and (max-width: 1534px) {
            .header {
              padding: 15px 0;
            }
            .menu-box .menu-left img {
              max-width: 160px;
            }
            .enumenu_ul li a {
              font-size: 14px;
            }
            .menu-box .menu-right {
              width: 77%;
            }
            .section-1 .inner-box {
              align-items: center;
            }
            .section-1 .inner-box .left-text {
              padding-top: 0%;
            }
            .em h1 br {
              display: none;
            }
            .em h1 {
              font-size: 45px;
              line-height: 3.5rem;
              font-weight: 700;
              padding-bottom: 32px;
            }
            .em p {
              font-size: 14px;
              line-height: 24px;
            }
            .section-2 .inner-box h3 {
              font-size: 27px;
              line-height: 39px;
            }
            .nft-boxes {
              max-width: 1300px;
              margin: 0 auto;
            }
            .section-9 .nft-boxes .play-your-title h3 {
              font-size: 18px;
              line-height: 20px;
            }
            .section-9 .nft-boxes .nft-box {
              padding: 30px 15px;
            }
            .nft-boxes .nft-box {
              width: 19%;
              margin-bottom: 18px;
            }
            .section-3 {
              padding: 60px 0;
            }
            .em h2.title {
              font-size: 32px;
              line-height: 2.5rem;
            }
            .section-3 .card-box {
              padding-top: 60px;
            }
            .section-3 .card-box ul li:after {
              height: 47px;
              width: 47px;
              font-size: 19px;
              line-height: 44px;
            }
            .section-3 .card-box ul li p {
              padding-top: 20px;
              font-size: 16px;
              line-height: 20px;
            }
            .section-4 .pack-boxs {
              max-width: 1300px;
              padding-top: 50px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p.name {
              font-size: 14px;
              line-height: 20px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p {
              font-size: 13px;
              line-height: 20px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p.price {
              font-size: 17px;
              line-height: 20px;
            }
            .section-4 .pack-boxs .pack-box .text-box .info-text {
              font-size: 12px;
              line-height: 17px;
              padding-top: 4px;
              padding-bottom: 18px;
            }
            .em .input-box button {
              font-size: 18px;
              line-height: 20px;
              letter-spacing: 0.03em;
              padding: 15px 28px;
            }
            .em .input-style {
              height: 53px;
              padding: 19px 30px;
            }
            .section-6 .chart-box {
              max-width: 1300px;
              padding-top: 70px;
            }
            /* .section-6 .chart-box img:nth-child(1){
        max-width: 200px;
        width: 100%;
    }
    .section-6 .chart-box img:nth-child(2){
        max-width: 440px;
        width: 100%;
    } */
            .section-7 {
              padding: 60px 0 40px;
            }
            .section-7 .roadmap-box {
              padding-top: 50px;
            }
            .section-7 .roadmap-box {
              padding-top: 50px;
            }
            .em h4 {
              font-size: 20px;
              line-height: 29px;
            }
            .section-8 .input-box {
              padding-top: 40px;
            }
            footer .footer-inner .left-side img {
              width: 100%;
              max-width: 200px;
            }
            footer .footer-inner .cpy {
              font-size: 12px;
              line-height: 20px;
              padding-top: 10px;
            }
            footer .footer-inner .right-side {
              width: 50%;
            }
            footer .footer-inner .right-side ul a {
              font-size: 14px;
              line-height: 20px;
            }
            footer .footer-inner .right-side .social img {
              max-width: 30px;
            }
            footer .footer-inner .right-side .social {
              width: 30%;
            }
            .enumenu_ul li a.connect-wallet-btn {
              padding: 12px 15px 12px 15px;
            }
            // .enumenu_ul li a.connect-wallet-btn::before {
            //   top: 10px;
            //   left: 12px;
            // }
            .nft-roadmap-box {
              padding: 30px 15px;
            }
            .nft-roadmap-box .nft-roadmap-box-image img {
              max-width: 135px;
            }
          }
          @media all and (max-width: 1500px) {
            #pie-chart1 {
              margin-top: -55px;
            }
          }
          @media all and (max-width: 1366px) {
            .nft-roadmap-box::after {
              right: -34%;
            }
            .section-12 .awarded-image img {
              max-width: 240px;
            }
          }
          @media all and (max-width: 1280px) {
            .our-team-box {
              padding: 0 25px 30px;
            }
            .our-team-box-image-inner img {
              max-width: 160px;
            }
            .our-team-box-image {
              margin: -90px 0 0 0;
            }
            .our-team-detail-bottom-inner {
              padding: 130px 0 0 0;
            }
            .our-team-box-title-left h3 {
              font-size: 26px;
            }
            .our-team-box-title-right img {
              max-width: 35px;
            }
            .roadmap-box-main::after {
              bottom: 51%;
              right: -117px;
              height: 18px;
              width: 185px;
            }
          }
          @media all and (max-width: 1200px) {
            .roadmap-box-main::after {
              bottom: 51%;
              right: -105px;
              height: 18px;
              width: 160px;
            }
            .section-8 {
              padding: 60px 0;
            }
            .section-5 {
              margin: 40px 0 0 0;
            }
          }
          @media all and (max-width: 1110px) {
            .em h1 {
              font-size: 35px;
              line-height: 3rem;
            }
            .enumenu_ul.desk {
              display: none;
            }
            .menu-icon {
              display: inline-block;
            }
            .logo img {
              max-width: 166px;
            }

            body.menu-open {
              overflow: hidden;
              position: fixed;
            }
            body.menu-open .header_sect {
              position: fixed;
              z-index: 99999;
            }
            .menu-block {
              height: auto;
            }
            .menu-icon {
              display: block;
            }
            .services-detail a.pba-detail {
              border-bottom: 2px solid #d3d9da;
            }
            .enumenu_ul > li {
              display: block;
              margin: 0px;
              padding: 0px;
            }
            .enumenu_ul > li > a {
              display: inline-block;
              text-align: left;
              padding: 8px 21px;
              width: 100%;
              margin-bottom: 6px;
            }
            .enumenu_ul {
              width: 100%;
            }
            .enumenu_ul > li ul {
              background: #dde3ed;
              margin-bottom: 20px;
            }
            .enumenu_ul ul li {
              margin-bottom: 15px;
            }
            .enumenu_ul ul li,
            .enumenu_ul .sb-menu li:last-child {
              padding: 0;
            }
            .enumenu_ul.mob ul li:hover {
              background-color: transparent;
            }
            .enumenu_ul.mob ul li:hover > a,
            .enumenu_ul li.mbtn:hover,
            .enumenu_ul li a.mbtn:focus,
            .enumenu_ul li.mbtn:hover a,
            .enumenu_ul li a.mbtn:focus a {
              background: transparent;
              color: #11283d;
            }
            .enumenu_ul > li ul a {
              text-align: left;
              display: block;
              padding: 5px 0;
              border-bottom: 2px solid #fff;
            }
            .enumenu_ul.mob li ul a {
              border-bottom: 2px solid #fff;
              font-weight: 500;
              font-size: 30px;
            }
            .em h1 {
              font-size: 26px;
              line-height: 2rem;
              padding-bottom: 20px;
            }
            .section-2 .inner-box h3 {
              font-size: 24px;
              line-height: 35px;
            }
            .section-3 {
              padding: 40px 0;
            }
            .em h2.title {
              font-size: 28px;
              line-height: 2rem;
              padding-bottom: 18px;
            }
            .section-3 .card-box {
              padding-top: 40px;
            }
            .nft-boxes .nft-box::after {
              height: 14px;
              width: 14px;
            }
            .section-3 .card-box ul li p {
              padding-top: 15px;
              font-size: 14px;
              line-height: 18px;
            }
            .section-4 {
              padding: 40px 0;
            }
            .section-4 {
              padding: 40px 0 20px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text .left-text {
              width: 70%;
            }
            .em .btns {
              font-size: 14px;
              line-height: 18px;
              padding: 8px 22px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p {
              font-size: 12px;
              line-height: 20px;
            }
            .section-5 .input-box {
              padding-top: 30px;
            }
            .em .input-box button {
              font-size: 16px;
              line-height: 18px;
            }
            .section-8 {
              padding: 40px 0;
            }
            footer .footer-inner {
              align-items: end;
            }
            footer .footer-inner .right-side {
              width: 60%;
            }
            .header .menu-box {
              flex-wrap: wrap;
            }
            .enumenu_ul.mob {
              position: absolute;
              left: 0;
              background-color: #000;
              height: 100vh;
              z-index: 1;
            }
            .tele {
              right: 10px;
              bottom: 10px;
            }
            .section-9 .inner-box .play-your-title h3 {
              padding-bottom: 10px;
            }
            .section-9 .nft-boxes .nft-box {
              padding: 20px 15px;
            }
            .em .nft-roadmap-box-content p {
              line-height: 20px;
              padding-bottom: 2px;
            }
            .nft-roadmap-box {
              padding: 20px 15px;
            }
            .nft-roadmap-box .nft-roadmap-box-image img {
              max-width: 85px;
            }
            .nft-roadmap-box::after {
              width: 50px;
              background-size: 50px;
            }
            .chart-box-left img {
              max-width: 230px;
            }
            .chart-box-left-content {
              margin: 30px 0 0 0;
              padding: 0 6%;
            }
            .roadmap-box-main::after {
              bottom: 52%;
              right: -83px;
              height: 18px;
              width: 128px;
            }
            .roadmap-box-main-content {
              height: 240px;
              padding: 0 0 0 18%;
            }
            .section-12 .awarded-content-title h3 {
              font-size: 32px;
              line-height: 42px;
            }
            .our-team-box {
              padding: 0 15px 20px;
            }
            .our-team-box-title-left h3 {
              font-size: 22px;
            }
            .our-team-box-title-left h5 {
              font-size: 20px;
              line-height: 24px;
            }
            .our-team-box-title-right img {
              max-width: 30px;
            }
            .em p {
              font-size: 14px;
              line-height: 20px;
            }
            .our-team-box-image-inner img {
              max-width: 135px;
            }
            .our-team-box {
              margin: 0 0 125px 0;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text .left-text {
              width: 100%;
            }
            .enumenu_ul li a.connect-wallet-btn {
              padding: 12px 15px 12px 15px;
              display: inline-block;
              width: auto;
              margin: 0 0 0 8px;
            }
            #pie-chart1 {
              height: 360px;
            }
          }
          @media all and (max-width: 1023px) {
            .section-9 .inner-box h3 {
              font-size: 24px;
              line-height: 35px;
            }
            .section-9 .nft-boxes .nft-box {
              padding: 10px 10px 10px;
            }
            .section-9 .nft-boxes .play-your-title h3 {
              font-size: 14px;
              line-height: 20px;
            }
            .play-your-btn a {
              padding: 6px 12px;
            }
            .play-your-title {
              min-height: 50px;
              display: inline-block;
            }
            .section-9 .nft-boxes .nft-box .img-box img {
              max-width: 45px;
            }
            .section-9 {
              padding: 16px 0;
              margin: 10px 0 0 0;
            }
            .section-3 {
              padding: 20px 0;
            }
            .em .info-price-text-btn .btns {
              padding: 5px 18px;
            }
            .section-10 .nft-roadmap-section-top h3 {
              font-size: 28px;
              line-height: 35px;
              padding-bottom: 8px;
            }
            .nft-roadmap-box {
              padding: 15px 10px;
            }
            .nft-roadmap-box::after {
              width: 35px;
              background-size: 35px;
            }
            .em h4 {
              font-size: 16px;
              line-height: 24px;
            }
            .section-11 .affiliation-programme-top h3 {
              font-size: 28px;
              line-height: 35px;
              padding-bottom: 32px;
            }
            .section-7 {
              padding: 20px 0 40px;
            }
            .section-7 .roadmap-box {
              padding-top: 15px;
            }
            .roadmap-box-main::after {
              bottom: 62%;
              right: -60px;
              height: 18px;
              width: 90px;
            }
            .roadmap-box-main-content {
              height: 300px;
              padding: 0 0 0 9%;
            }
            .roadmap-box-main-content ul li {
              margin: 0 0 5px 0;
            }
            .section-12 .awarded-image img {
              max-width: 200px;
            }
            .section-12 .awarded-content-title h3 {
              font-size: 28px;
              line-height: 35px;
            }
            .section-8 {
              padding: 15px 0;
            }
            .section-13 .our-team-detail-top h3 {
              font-size: 28px;
              line-height: 35px;
              padding-bottom: 20px;
            }
            .our-team-box {
              width: 48%;
            }
            .our-team-box:nth-last-child(3) {
              margin: 0 0 125px 0;
            }
            .section-1 {
              padding: 35px 0;
            }
          }
          @media all and (max-width: 767px) {
            footer .footer-inner .right-side .social ul li:last-child {
              width: 0px;
            }
            .menu-box .menu-right {
              width: 50px;
            }
            .menu-box .menu-left {
              width: 116px;
            }
            .section-1 .inner-box .left-text {
              padding-top: 0;
            }
            .section-1 {
              padding: 40px 0;
            }
            .section-1 .inner-box .rigt-img {
              display: none;
            }
            .section-1 .inner-box .left-text {
              text-align: center;
              width: 100%;
            }
            .em h1 br {
              display: none;
            }
            .nft-boxes {
              max-width: 320px;
              margin: 0 auto;
            }
            .nft-boxes .nft-box {
              width: 48%;
            }
            .section-3 .card-box ul li {
              width: 48%;
              padding-top: 20px;
              margin-bottom: 40px;
            }
            .section-3 {
              padding: 40px 0 10px;
            }
            .em h2.title br {
              display: none;
            }
            .section-4 .pack-boxs {
              max-width: 840px;
              padding-top: 32px;
            }
            .section-4 .pack-boxs .pack-box {
              border-radius: 18px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text .left-text {
              width: 100%;
              margin-bottom: 10px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text p.price {
              font-size: 15px;
              line-height: 18px;
            }
            .section-4 .pack-boxs .pack-box .text-box .innter-text .right-text {
              width: 100%;
              margin-bottom: 10px;
            }
            .em .input-style {
              width: 100%;
            }
            .em .input-box button {
              font-size: 14px;
              line-height: 16px;
              width: 100%;
              margin-top: 15px;
            }
            .em .input-box button {
              max-width: 258px;
              margin: 25px auto 0;
            }
            .section-6 .chart-box {
              max-width: 320px;
              padding-top: 50px;
              text-align: center;
            }
            .section-6 .chart-box img:nth-child(1) {
              max-width: 100%;
              width: 100%;
              margin-bottom: 40px;
            }
            .section-6 .chart-box img:nth-child(2) {
              max-width: 100%;
              width: 100%;
            }
            .section-7 {
              padding: 5px 0 40px;
            }
            .section-7 .roadmap-box.mob {
              display: inline-block;
              text-align: center;
            }
            /* .section-7 .roadmap-box.desk{
        display: none;
    } */
            .section-7 .roadmap-box img {
              width: 100%;
              max-width: 592px;
            }
            footer .footer-inner {
              align-items: end;
              text-align: center;
              flex-direction: column;
            }
            footer .footer-inner .left-side {
              width: 100%;
              text-align: center;
              padding-bottom: 26px;
            }
            footer .footer-inner .cpy {
              display: none;
            }
            footer .footer-inner .right-side {
              width: 100%;
              flex-direction: column;
            }
            footer .footer-inner .right-side .links {
              width: 300px;
              padding-bottom: 26px;
            }
            footer .footer-inner .right-side .social {
              width: 200px;
            }
            footer .designed {
              text-align: right;
              display: none;
            }
            footer .mob {
              display: block;
              text-align: center;
            }
            .mob .cpy {
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #323232;
              padding-top: 26px;
            }
            footer {
              padding: 33px 0;
            }
            .em h4 {
              font-size: 17px;
              line-height: 26px;
            }
            .play-your-title {
              min-height: auto;
              display: inline-block;
            }
            .section-9 .nft-boxes .nft-box {
              width: 48%;
            }
            .nft-roadmap-box {
              width: 42%;
              margin: 0 0 25px 0;
            }
            .nft-roadmap-box::after {
              right: -32%;
            }
            .nft-roadmap-box:nth-child(2n)::after {
              display: none;
            }
            .affiliation-programme-box {
              width: 100%;
            }
            .affiliation-programme-left.affiliation-programme-box {
              margin: 0 0 30px 0;
            }
            .chart-box-main {
              width: 100%;
            }
            .section-6 .chart-box img:nth-child(1) {
              max-width: 100%;
              width: 100%;
              margin-bottom: 3px;
            }
            .chart-box-left.chart-box-main {
              margin: 0 0 30px 0;
            }
            .our-team-box {
              width: 100%;
            }
            .our-team-box:nth-last-child(2) {
              margin: 0 0 125px 0;
            }
            .roadmap-box-main {
              width: 100%;
              position: relative;
            }
            .roadmap-box-main::after {
              display: none;
            }
            .roadmap-box-main-content {
              height: auto;
              padding: 0 0 0 0%;
            }
            .section-7 .roadmap-box img {
              width: 100%;
              max-width: 220px;
            }
            .section-3 {
              padding: 15px 0 10px;
            }
            .section-4 {
              padding: 0px 0 20px;
            }
            .section-10 {
              padding: 10px 0;
            }
            .section-11 {
              padding: 16px 0;
              margin: 5px 0 0 0;
            }
            .affiliation-programme-right-btn {
              margin: 25px 0 0 0;
            }
            .section-5 {
              margin: 0px 0 0 0;
              padding: 25px 0;
            }
            .section-6 {
              padding: 20px 0;
            }
            .nft-roadmap-box .nft-roadmap-box-image img {
              max-width: 50px;
            }
            .nft-roadmap-box {
              padding: 10px 10px;
            }
            #pie-chart1 {
              margin-top: -10px;
            }
          }
          @media all and (max-width: 640px) {
            .section-3 .card-box {
              max-width: 330px;
              margin: 0 auto;
            }
          }
          @media all and (max-width: 600px) {
          }
          @media all and (max-width: 480px) {
            .section-3 .card-box ul li {
              width: 100%;
            }
            .section-4 .pack-boxs .pack-box {
              width: 100%;
            }
          }
          @media all and (max-width: 374px) {
            .nft-roadmap-box::after {
              width: 30px;
              background-size: 30px;
            }
            .em h4 {
              font-size: 16px;
              line-height: 22px;
            }
            .em .nft-roadmap-box-content p {
              line-height: 16px;
              padding-bottom: 2px;
            }
            .nft-roadmap-box .nft-roadmap-box-image img {
              max-width: 50px;
            }
            .nft-roadmap-box {
              padding: 10px 10px;
            }
            .section-11 .affiliation-programme-top h3 {
              font-size: 26px;
              line-height: 32px;
              padding-bottom: 25px;
            }
            .em h2.title {
              font-size: 26px;
              line-height: 2rem;
              padding-bottom: 18px;
            }
            .roadmap-box-main-image {
              margin: 0 0 10px 0;
            }
            .section-12 .awarded-content-title h3 {
              font-size: 26px;
              line-height: 32px;
            }
            .our-team-box-title-left h5 {
              font-size: 18px;
              line-height: 24px;
            }
          }

          .marketplace-div {
            background: linear-gradient(180deg, #cefe01 0%, #52ff00 100%);
            border-radius: 15px;
            padding: 12px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 12px;
          }
          .marketplace-div .image-div {
            display: flex;
          }
          .marketplace-div .image-div img {
            height: 100px;
          }
          .marketplace-div .one {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }
          .marketplace-div h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #000000;
          }
          .marketplace-div p {
            font-size: 18px;
            color: #000000;
          }
          .marketplace-div .marketplace-div-text {
            margin-left: 18px;
          }
          .marketplace-div .marketplace-div-link {
            padding: 16px 32px;
            background: #000000 !important;
            border-radius: 10px;
            color: #52ff00 !important;
          }

          .section-1 {
            margin-top: 124px;
          }

          .private-sale-btn {
            background: rgb(219, 255, 1);
            color: #000;
            padding: 8px 18px !important;
            border-radius: 60px;
          }

          .section-whitepaper {
            text-align: center;
            margin-top: 18px;
            margin-bottom: 18px;
          }

          .section-whitepaper p {
            font-size: 32px;
            line-height: normal;
          }

          .section-whitepaper p .primary {
            color: rgb(219, 255, 1);
          }

          @media all and (max-width: 768px) {
            .marketplace-div .marketplace-div-text {
              margin-left: 0px;
            }
            .marketplace-div {
              height: 350px;
            }
            .section-1 {
              margin-top: 50px;
            }
            .private-sale-btn {
              margin-top: 12px !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HomePageView;
